import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface CancelRefundModel {
  title: string;
  message: string;
}

@Component({
  selector: 'ap-cancel-refund-modal',
  templateUrl: './cancel-refund-modal.component.html',
  styleUrls: ['./cancel-refund-modal.component.css']
})
export class CancelRefundModalComponent extends SimpleModalComponent<CancelRefundModel, string> implements CancelRefundModel, OnInit{
  cancellationReasonForm: FormGroup;
  title: string;
  message: string;
  textLength: number;
  refundCacellationReason: string;
  
  constructor(private fb:FormBuilder) { 
  super();
  this.cancellationReasonForm = this.fb.group({
  cancellationReason: ['', Validators.required]
    });
  }

  confirm() {
    this.result = this.cancellationReasonForm.value.cancellationReason;
    this.close();
  }

  closeForm() {
    this.close();
  }

  ngOnInit() {
    this.cancellationReasonForm = new FormGroup({
      cancellationReason: new FormControl('', Validators.required)
    });
    this.textLength=0;
  }

  checkLength() {
    this.textLength = this.refundCacellationReason.length;
  }
}
