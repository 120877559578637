<div class="input-group autocomplete-container">
  <div class="input-group-btn">
    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="dropdown">
      <span class="caret"></span>
    </button>
    <div class="dropdown-menu autocomplete-form">
      <div class="row">
        <div class="col-xs-12 form-group has-feedback">
          <input type="text" class="form-control" [ngModel]="searching" (ngModelChange)="onSearch($event)" attr.aria-label="{{ 'AUTOCOMPLETION.SEARCH' | translate }}" placeholder="{{ 'AUTOCOMPLETION.SEARCH' | translate }}">
          <span class="fa fa-search form-control-feedback right" aria-hidden="true"></span>
        </div>
        <div class="col-xs-12 autocomplete-result">
          <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="'INIT'" [ngTemplateOutlet]="noSearch"></ng-container>
            <ng-container *ngSwitchCase="'LOADING'" [ngTemplateOutlet]="loading"></ng-container>
            <ng-container *ngSwitchCase="'EMPTY'" [ngTemplateOutlet]="empty"></ng-container>
            <ng-container *ngSwitchCase="'DONE'" [ngTemplateOutlet]="data"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <input type="text" class="form-control search-input" maxlength="{{ maxlength }}" [ngModel]="value?.key" (change)="onManualEntry($event)" [disabled]="readonly" placeholder="{{placeholder | translate }}" attr.aria-label="{{ description | translate }}">
  <div *ngIf="value" class="input-group-btn">
    <button type="button" (click)="onClear()" class="btn btn-default" aria-label="remove"><i class="fa fa-remove" alt="remove"></i></button>
  </div>
</div>
<!-- Display list of options -->
<ng-template #data>
  <ul class="list-unstyled autocomplete">
    <li *ngFor="let option of options" (click)="onSelect(option)"><a href="javascript:void(0)">{{ option.key }}</a></li>
  </ul>
</ng-template>
<!-- Message to explain to the user to fill the search field to launch the search request -->
<ng-template #noSearch>
  <div class="text-center">
    <i class="fa fa-pencil-square-o" alt="edit"></i>
    <div>{{ 'AUTOCOMPLETION.SEARCHING' | translate : { minSearch: minSearch } }}</div>
  </div>
</ng-template>
<!-- Message when to notifiy loading -->
<ng-template #loading>
  <div class="text-center">
    <i class="fa fa-spinner fa-spin" alt="spin"></i>
  </div>
</ng-template>
<!-- Message when no data -->
<ng-template #empty>
  <div class="text-center">
    <i class="fa fa-inbox" alt="empty"></i>
    <div>{{ 'AUTOCOMPLETION.EMPTY' | translate }}</div>
  </div>
</ng-template>
