import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractFilter } from './abstract-filter.model';

@Component({
  selector: 'ap-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.css']
})
export class FilterItemComponent implements OnInit {

  @Input() filter: AbstractFilter;

  @Output() remove = new EventEmitter<AbstractFilter>();

  @Output() edit = new EventEmitter<AbstractFilter>();

  @Output() disable = new EventEmitter<AbstractFilter>();

  @ViewChild('content') contentDiv: ElementRef;

  @ViewChild('actions') actionsDiv: ElementRef;

  @ViewChild('filterDiv') filterDiv: ElementRef;

  constructor() {
  }

  @HostListener('mouseover')
  onMouseOver() {
    const w = this.contentDiv.nativeElement.offsetWidth;
    this.filterDiv.nativeElement.classList.add('focus');

    this.actionsDiv.nativeElement.style.display = 'block';
    this.actionsDiv.nativeElement.style.width = (w) + 'px';

    this.contentDiv.nativeElement.style.display = 'none';

  }

  @HostListener('mouseout')
  onMouseOut() {
    this.filterDiv.nativeElement.classList.remove('focus');

    this.actionsDiv.nativeElement.style.display = 'none';
    this.contentDiv.nativeElement.style.display = 'block';
  }

  onRemove() {
    this.remove.emit(this.filter);
  }

  onEdit() {
    this.edit.emit(this.filter);
  }

  onDisable() {
    this.filter.enabled = !this.filter.enabled;
    this.disable.emit(this.filter);
  }

  ngOnInit() {
  }

}
