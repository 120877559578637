import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import cloneDeep from 'lodash.clonedeep';
import { AbstractFilter } from '../filter-item/abstract-filter.model';
import { AbstractFilterTemplate } from '../filter-template/abstract-filter-template.model';

@Component({
  selector: 'ap-filter-add',
  templateUrl: './filter-add.component.html',
  styleUrls: ['./filter-add.component.css']
})
export class FilterAddComponent implements OnInit {

  @Input() templates: AbstractFilterTemplate<AbstractFilter>[] = [];

  @Output() addFilter = new EventEmitter<AbstractFilter>();

  @Output() remove = new EventEmitter<AbstractFilter>();

  createdFilter: AbstractFilter;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Retrieve template to build a new filter.
   * @param templateName string
   */
  onSelect(templateName: string) {
    const template = this.templates.find((t: AbstractFilterTemplate<AbstractFilter>) => t.name === templateName);
    if (template) {
      this.createdFilter = cloneDeep(template).buildDefaultFilter();
    } else {
      this.createdFilter = null;
    }
  }

  getGroups(): string[] {
    return this.templates.map(t => t.group).filter(t => t !== 'DEFAULT').reduce((acc, d) => acc.includes(d) ? acc : acc.concat(d), []);
  }

  onAddFilter(filter: AbstractFilter) {
    this.addFilter.emit(filter);
  }
}
