import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTimeFilter } from '../../filter-item/datetime-filter-item/datetime-filter.model';
import cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';

@Component({
  selector: 'ap-datetime-filter-template',
  templateUrl: './datetime-filter-template.component.html',
  styleUrls: ['./datetime-filter-template.component.css']
})
export class DateTimeFilterTemplateComponent implements OnInit {

  @Input()
  get filter(): DateTimeFilter { return this._filter; }
  set filter(filter: DateTimeFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: moment(filter.value).format('YYYY-MM-DDTHH:mm:ss')});
  }
  _filter: DateTimeFilter;

  @Output() addFilter = new EventEmitter<DateTimeFilter>();

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.formBuilder.group({
      value: ['', Validators.required]
    });
  }

  ngOnInit() { }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = new Date(this.filterForm.value.value);
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }
  
}
