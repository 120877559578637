import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ap-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.css']
})
export class StatusLabelComponent implements OnInit {

  @Input()
  status: string;

  constructor() { }

  ngOnInit() {
  }

}
