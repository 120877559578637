<div class="row">
  <div class="col-md-12">
    <div class="form-inline">
      <!-- List the template name -->
      <div class="form-group">
        <select class="form-control" (change)="onSelect($event.target.value)" attr.aria-label="{{ 'FILTERS.ADD.LABEL' | translate }}">
            <!--
            <option value="">-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
            <option *ngFor="let template of templates" [value]="template.name">{{ template.label | translate }}</option>
            -->
            <option value="">-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
            <option *ngFor="let template of templates | filter:'group':['DEFAULT']" [value]="template.name">{{ template.label | translate }}</option>

            <optgroup *ngFor="let group of getGroups()" label="{{ group | translate }}">
              <option *ngFor="let template of templates | filter:'group':[group]" [value]="template.name">{{ template.label | translate }}</option>
            </optgroup>
    

        </select>
      </div>
      <!-- Display the filter template editor -->
      <div class="form-group" *ngIf="createdFilter">
        <ap-filter-template (addFilter)="onAddFilter($event)" [filter]="createdFilter"></ap-filter-template>
      </div>
    </div>
  </div>
</div>