import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'furtherInformation', pure: false})

export class FurtherInformationLabelPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(furtherInfoKey: string): string {
    return environment.admin.devices?.device?.furtherInfo ? this.translateService.instant('DEVICE.LIST.LABELS.FURTHER_INFO.' + furtherInfoKey) : furtherInfoKey;
  }
}
