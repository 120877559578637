<div class="modal-backdrop">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title | translate }}</h4>
        </div>
        <div class="modal-body">
          <p class="pre-wrap">{{ message | translate }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="cancel()" attr.aria-label="{{ 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}">{{ 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}</button>
        </div>
      </div>
    </div>
</div>