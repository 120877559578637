import { FilterOperatorEnum } from "../../commons/enum/filter-operator.enum";

export class Filter {
    id?: number;
    page: string;
    name: string;
    companyReference?: string;
    creationDate?: Date;
    items: FilterItem[];
    shared?: boolean;

    static parse(page, name, str: string, shared = false): Filter {
        const filter = new Filter();
        filter.page = page;
        filter.name = name;
        filter.shared = shared;

        filter.items = str.split('|').map(s => FilterItem.parse(s));
        return filter;
    }

    encode(): string {
        return this.items.map(item => item.encode()).join('|');
    }
}

export class FilterItem {
    type: string;
    name: string;
    value: string;
    active: boolean;
    extraData?: string;
    operator?: FilterOperatorEnum;

    static parse(str: string) {
        const values = str.split('~');
        const item = new FilterItem();
        item.name = values[0];
        item.active = JSON.parse(values[1]);
        item.value = values[2];
        if (values.length > 3) {
            item.extraData = values[3];
        }
        // Retrieve filter operator
        Object.values(FilterOperatorEnum).forEach(filterOperator => {
            if (values.indexOf(filterOperator) > -1) {
                item.operator = filterOperator;
            }
         });
        return item;
    }

    encode(): string {
        return this.name + '~' + (this.active ? '1' : '0') + '~' +  this.value + (this.extraData ? '~' + this.extraData : '');
    }
}
