import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ap-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})

export class ProgressBarComponent implements OnInit {
  // value by default
  @Input() threshold = false;
  @Input() rate = 0;
  @Input() successfulThreshold = 0;
  @Input() criticalThreshold = 0;

  color = 'progress-bar-info';

  constructor() {}

  ngOnInit() {
    if (this.rate > 1) {
      this.rate = 1;
    }
    if (this.threshold) {
      if (this.rate <= this.successfulThreshold) {
        this.color = 'progress-bar-success';
      } else if (this.rate <= this.criticalThreshold) {
        this.color = 'progress-bar-warning';
      } else {
        this.color = 'progress-bar-danger';
      }
    }
    this.rate = this.rate * 100 ;
  }

}
