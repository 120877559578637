import { GenericParameter } from './models/list/genericParameter';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Page } from '../commons/page';
import { Configuration } from '../configuration';
import { BlockUnblockTerminalInfo } from './models/blocking/blockUnblockTerminalInfo';
import { DeviceConfigBulk } from './models/deviceConfigBulk';
import { DeviceConfigBulkRequest } from './models/deviceConfigBulkRequest';
import { DeviceConfigFlatV2 } from './models/deviceConfigFlatV2';
import { DeviceConfigurationV2 } from './models/deviceConfigurationV2';
import { DeviceConfigV2Template } from './models/deviceConfigV2Template';
import { DeviceInfoV3 } from './models/deviceInfoV3';
import { Device } from './models/list/device';
import { DeviceBatchResults } from './models/list/deviceBatchResults';
import { DeviceFilter } from './models/list/deviceFilter';
import { XenturionInfoV3 } from './models/xenturionInfoV3';
import { DeviceGenericParameterBatchPatch } from './models/deviceGenericParameterBatchPatch';
import { DeviceInfoV2 } from './models/deviceInfoV2';
import { AdministratorService } from '../administrator/administrator.service';
import { DeviceBatchStatus } from './models/deviceBatchStatus';
import { FilterOperatorEnum } from '../commons/enum/filter-operator.enum';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private configuration = new Configuration();
  private defaultHeaders = new HttpHeaders();
  private basePath = environment.admin.api.deviceEngine;

  constructor(private httpClient: HttpClient, private administratorService: AdministratorService) {}

  public getDeviceInfoV3(poi: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceInfoV3>;
  public getDeviceInfoV3(poi: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceInfoV3>>;
  public getDeviceInfoV3(poi: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    return this.httpClient.get<DeviceInfoV3>(`${this.basePath}/api/device-configuration-manager/v3/devicesInfo/${poi}`, {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders,
      observe,
      reportProgress
    }).pipe(catchError(() => of(null)));
  }

  public getDeviceInfoV2(poi: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceInfoV2>;
  public getDeviceInfoV2(poi: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceInfoV2>>;
  public getDeviceInfoV2(poi: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    return this.httpClient.get<DeviceInfoV2>(`${this.basePath}/api/device-configuration-manager/v2/devicesInfo/${poi}`, {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders,
      observe,
      reportProgress
    }).pipe(catchError(() => of(null)));
  }

  public getDeviceConfigV2(poi: string, includeHistory: boolean = false): Observable<DeviceConfigurationV2> {
    return this.httpClient.get<DeviceConfigurationV2>(`${this.basePath}/api/device-configuration-manager/v2/devicesConfig/${poi}?includeHistory=${includeHistory}`, {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders,
    }).pipe(catchError(() => of(null)));
  }

  public getDeviceConfigFlatV2(poi: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceConfigFlatV2>;
  public getDeviceConfigFlatV2(poi: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceConfigFlatV2>>;
  public getDeviceConfigFlatV2(poi: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    return this.httpClient.get<DeviceConfigurationV2>(`${this.basePath}/api/device-configuration-manager/v2/devicesConfig/flat/${poi}`, {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders,
      observe,
      reportProgress
    }).pipe(catchError(() => of(null)));
  }

  public patchDeviceConfigBulkV2(deviceConfigBulkRequest: DeviceConfigBulkRequest, observe?: 'body', reportProgress?: boolean): Observable<DeviceConfigBulk>;
  public patchDeviceConfigBulkV2(deviceConfigBulkRequest: DeviceConfigBulkRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceConfigBulk>>;
  public patchDeviceConfigBulkV2(deviceConfigBulkRequest: DeviceConfigBulkRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (!deviceConfigBulkRequest) {
      throw new Error('Required parameter deviceConfigBulkRequest was null or undefined when calling patchDeviceConfigBulkV2.');
    }

    let headers = this.defaultHeaders;

    const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const httpContentTypeSelected = this.configuration.selectHeaderContentType(['application/json']);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<DeviceConfigBulk>(`${this.basePath}/api/device-configuration-manager/v2/devicesConfig/_bulk`,
      deviceConfigBulkRequest,
      {
        withCredentials: this.configuration.withCredentials,
        headers: this.defaultHeaders,
        observe,
        reportProgress
      }
    ).pipe(catchError(() => of(null)));
  }

  public patchDevicesGenericParameter(deviceGenericParameterBatchPatch: DeviceGenericParameterBatchPatch): Observable<DeviceBatchResults> {

    if (!deviceGenericParameterBatchPatch) {
      throw new Error('Required parameter deviceConfigBulkRequest was null or undefined when calling patchDevicesGenericParameter.');
    }

    return this.httpClient.patch<DeviceBatchResults>(`${this.basePath}/api/device-configuration-manager/v3/devices/genericparameters/_bulk`, deviceGenericParameterBatchPatch );
  }

  public getXenturionInfoV3(poi: string, observe?: 'body', reportProgress?: boolean): Observable<XenturionInfoV3>;
  public getXenturionInfoV3(poi: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<XenturionInfoV3>>;
  public getXenturionInfoV3(poi: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    return this.httpClient.get<XenturionInfoV3>(`${this.basePath}/api/device-configuration-manager/v3/devicesInfo/${poi}/xenturion`, {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders,
      observe,
      reportProgress
    }).pipe(catchError(() => of(null)));
  }

  /**
   * Get All Devices by filters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevices(filter: DeviceFilter, sortOrder: string, sortElement: string, pageNumber: number, pageSize: number, returnType: string): Observable<Page<Device>> {
    let params = new HttpParams();

    if (Number.isInteger(pageNumber) && Number.isInteger(pageSize)) {
      params = params.set('page', pageNumber.toString()).set('size', pageSize.toString());
    }

    if (!!returnType){
      params = params.set('returnType', returnType);
    }

    if (filter) {
      params = this.initHttpParamsWithDeviceFilter(filter, params);
    }

    params = this.setParamsWithCompanyRef(params);

    let headers = this.defaultHeaders;
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);

    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    params = params.set('sort', sortElement + ',' + sortOrder);

    return this.httpClient.get<Page<Device>>(`${this.basePath}/api/device-configuration-manager/v3/devices`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      params
    });
  }

  /**
   * Get a device by its POI
   * @param poi string
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevice(poi: string): Observable<Device> {

    return this.httpClient.get<Device>(`${this.basePath}/api/device-configuration-manager/v3/devices/${poi}`);
  }

  public createOrUpdateGenericParameters(poi: string, genericsParameters: Array<GenericParameter>, observe?: 'body', reportProgress?: boolean): Observable<Device> {
    if (!poi || !genericsParameters) {
      throw new Error('Parameters poi and genericsParameters are required for createOrUpdateGenericParameters method');
    }

    return this.httpClient.post<Device>(`${this.basePath}/api/device-configuration-manager/v3/devices/${poi}/genericparameters`,
      genericsParameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: this.defaultHeaders,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Delete the device corresponding to the poi parameter
   * @param poi device's poi parameter
   */
  deleteDevice(poi: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.basePath}/api/device-configuration-manager/v3/devices/${poi}`,
    {
      withCredentials: this.configuration.withCredentials,
      headers: this.defaultHeaders
    });
  }

  /**
   * Initialize a given DeviceFilter in a given HttpParams
   *
   * @param filter: DeviceFilter
   * @param params: HttpParams
   */
  private initHttpParamsWithDeviceFilter(filter: DeviceFilter, params: HttpParams): HttpParams {
    for (const [key, value] of Object.entries(filter)) {
      if (typeof value === 'string' && value.trim() !== '') {
        params = params.set(key, value.trim());
      } else if (value && Array.isArray(value)) {
        (<string[]>value).forEach(element => {
          params = params.append(key, element);
        });
      }
    }
    return params;
  }

  public getAllDeviceConfigV2Templates(): Observable<DeviceConfigV2Template> {
    return this.httpClient.get<DeviceConfigV2Template>(`${this.basePath}/api/device-configuration-manager/v2/device-config-templates`).pipe(catchError(() => of(null)));
  }

  public updateDeviceConfigV2Template(deviceConfigV2Template: DeviceConfigV2Template, observe?: 'body', reportProgress?: boolean): Observable<DeviceConfigV2Template>;
  public updateDeviceConfigV2Template(deviceConfigV2Template: DeviceConfigV2Template, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceConfigV2Template>>;
  public updateDeviceConfigV2Template(deviceConfigV2Template: DeviceConfigV2Template, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceConfigV2Template>>;
  public updateDeviceConfigV2Template(deviceConfigV2Template: DeviceConfigV2Template, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (!deviceConfigV2Template || !deviceConfigV2Template.id) {
      throw new Error('Required parameter deviceConfigV2Template was null or undefined when calling updateDeviceConfigV2Template.');
    }

    let headers = this.defaultHeaders;

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(['application/json']);
    if (httpContentTypeSelected !== undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DeviceConfigV2Template>(`${this.basePath}/api/device-configuration-manager/v2/device-config-templates/${deviceConfigV2Template.id}`,
      deviceConfigV2Template,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );

  }

  public createDeviceConfigV2(deviceConfig: DeviceConfigurationV2): Observable<DeviceConfigurationV2> {
    if (!deviceConfig) {
      throw new Error('Required parameter deviceConfig was null or undefined when calling createDeviceConfigV2.');
    }

    let headers = this.defaultHeaders;
    const httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(['*/*']);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(['application/json']);
    if (httpContentTypeSelected !== undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DeviceConfigurationV2>(`${this.basePath}/api/device-configuration-manager/v2/devicesConfig`,
      deviceConfig,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  public updateDeviceConfigV2(deviceConfig: DeviceConfigurationV2): Observable<void> {
    if (!deviceConfig) {
      throw new Error('Required parameter deviceConfig was null or undefined when calling updateDeviceConfigV2.');
    }
    if (!deviceConfig.id) {
      throw new Error('Device id required for updating.');
    }
    return this.httpClient.put<void>(`${this.basePath}/api/device-configuration-manager/v2/devicesConfig/${deviceConfig.id}`, deviceConfig);
  }

  public blockUnblockDevices(blockUnblockTerminalInfo: BlockUnblockTerminalInfo): Observable<DeviceBatchResults> {
    if (blockUnblockTerminalInfo === null || blockUnblockTerminalInfo === undefined) {
       throw new Error('Required parameter blockUnblockTerminalInfo was null or undefined when calling blockUnblockDevices.');
    }
    return this.httpClient.post<DeviceBatchResults>(`${this.basePath}/api/device-configuration-manager/v3/devices/_block`,
                                                            blockUnblockTerminalInfo);
  }

  public rebootDevice(poi: string): Observable<DeviceBatchStatus> {
    if (!poi) {
       throw new Error('Required parameter poi was null or undefined when calling rebootDevice.');
    }
    return this.httpClient.post<DeviceBatchStatus>(`${this.basePath}/api/device-configuration-manager/v3/devices/${poi}/_reboot`, {});
  }

  public rebootDevices(pois: string[]): Observable<DeviceBatchResults> {
    if (!pois || pois.length === 0) {
       throw new Error('Required parameter pois was null or undefined or empty when calling rebootDevices.');
    }
    return this.httpClient.post<DeviceBatchResults>(`${this.basePath}/api/device-configuration-manager/v3/devices/_reboot`, pois);
  }

  private setParamsWithCompanyRef(params: HttpParams): HttpParams {
    const companyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    if (companyReference) {
      return params.set('companyReference', `${FilterOperatorEnum.CONTAINS}:${companyReference}`);
    }

    return params;
  }
}
