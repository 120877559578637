// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from './environment.common';

export const environment = {
  version: commonEnvironment.version,
  production: commonEnvironment.production,

  admin: {
    /****************************************
     * Mutitenant activation
     ****************************************/
    multitenant: {
      enabled: false,
      tenant: '',
      useLocaly: false
    },

    /****************************************
     * API
     ****************************************/
    api: {
      gateway: '',
      apikey: '',
      deviceEngine: '',
      financialEngine: '',
      kibanaUrl: '',
      mobilityEngine: '',
      sipsNormalReturnAuthorization: '',
      sipsNormalReturnSearch: '',
      configServerUri: '/api/tenant/configuration'
    },

    /****************************************
     * KEYCLOAK
     ****************************************/
    keycloak: {
      url: '',
      realm: '',
      clientId: '',
      realmClient: ''
    },

    /****************************************
     * KIBANA
     ****************************************/
    kibana: {
      dashboard: '[V2]-Statistics',
      supervision: {
        dashboardId: 'bbc3a6d0-7d1c-11eb-8762-9bd8cb7fbdff'
      },
      statistic: {
        dashboardId: '861c31c0-9ec6-11eb-80fd-196f08f8fa34'
      }
    },

    /****************************************
     * APPLICATION
     *   Define main application parameters (favicon, title...)
     ****************************************/
    application: {
      title: 'Admin Portal',
      favicon: './assets/images/brand/favicon.png',
      languages: [],
      pageSize: 10, // Default table size

      // Footer configuration (legal aspects)
      footer: {
        cgu: {
          enabled: true,
          targetAsset: '/assets/config/CGUPage/'
        },
        faq: {
          enabled: true,
          targetAsset: '/assets/config/FAQPage/'
        },
        legalNotice: {
          enabled: true,
          targetAsset: '/assets/config/MentionLegalPage/'
        }
      },
    },

    /****************************************
     * USERS
     ****************************************/
    users: {
      // User details config
      details: {
        // Journey part
        journeys: {
          startTime: '',  // start time journey for the new module journey manager
          endTime: '', // start time journey for the new module journey manager
          hiddenColumns: [], // To not display some columns (SERVICE_OPERATOR, LINE, RAME, ORIGIN, TRANSPORT_MODE)
          displayPR: true, // Choice to display P+R taps in journey
          duplicatePRDelay: 'PT20S', // do not display TAP-PR if performed in less than duplicatePRDelay after the previous one
          displayRejectedTaps: true // Choice to display Rejected taps in journey
        },
        // Settlement part
        settlements: {
          hiddenColumns: [], // To not display some columns (SERVICE_OPERATOR, LINE, RAME, ORIGIN, TRANSPORT_MODE),
          receipt: { // Config to generate a receipt
            currency: 'EUR',
            language: 'fr'
          }
        }
      },
      refund: {
        enabled: true,
        maxNumberRefundableTap: 5,
        refundMode: {
          deferred: true
        }
      },
      paymentCases: {
        enabled: true
      }
    },

    /****************************************
     * TOPOLOGY
     *   Topology display config (define color/shape/border of the line)
     ****************************************/
    topology: {
      default: '#686c71|#fff|#686c71',
      BUS: {
        default: '#3d3c3f|#ffdd00|#3d3c3f',
        lines: [
          { refs: ['9', '62', '68', '72'], colors: '#fff|#004f9e|#004f9e' },
          { refs: ['61', '53', '67', '78', '82'], colors: '#fff|#e4003a|#e4003a' },
          { refs: ['C4'], colors: '#fff|#6f2282|#6f2282|0' }
        ]
      },
      SUBWAY: {
        default: '#686c71|#fff|#686c71',
        lines: [
          { refs: ['A'], colors: '#e93657|#fff|#e93657' }
        ]
      }
    },

    /****************************************
     * CUSTOMER REQUEST (SAV)               *
     ****************************************/
    customerRequest: {
      enable: true, // Enable SAV module
      genericEmail: '' // Define an email for origin when request is closed
    },

    /****************************************
     * TARIFF                               *
     ****************************************/
    tariffication: {
      productColors: {
        activate: false,
        values: []
      }
    },

    /****************************************
     * COMPANIES
     ****************************************/
    companies: {
    },

    /****************************************
     * DEVICES                              *
     ****************************************/
    devices: {
      alarms: {
        hiddenColumns: [],
        checkReload: 1,
        history: 'P6M', // default backlog time for displaying alarms
        statistics: {
          enable: true
        },
        export: {
          specifiedFileName: "alarms"
        },
        maxRequestHeaderSizeElements: 475 // Max size elements alarm manager accept in request header
      },
      list: {
        hiddenColumns: [],
        export: {
          specifiedFileName: "devices"
        }
      },

      device: {
        blocking: {
          activate: true,
          transportModeAllowed: ['']
        },
        reboot: {
          activate: true,
          transportModeAllowed: ['']
        },
        activateSimpleConfiguration: true,
        activateXenturionInfos: false,
        transportMode: [''],
        installationType: [''],
        function: [''],
        deviceModel: [''],
        properties: {
          global: ['connectivity.mode', 'connectivity.mode3g', 'connectivity.bridge', 'connectivity.language',
            'validator.placeId', 'validator.corridorId', 'validator.lineId', 'validator.zoneId',
            'validator.stationId', 'validator.stageId', 'validator.directionType', 'validator.ticketId',
            'validator.companyId', 'validator.coordinateLatitude', 'validator.coordinateLongitude', 'validator.amount',
            'validator.operatorId', 'terminal.serviceOperator', 'terminal.deviceReference', 'log.level'],
          applications: {
            names: [],
            parameters: {}
          },
          parameters: {
            validation: {},
            control: {}
          }
        },
        simpleConfRules: [],
        maxLengthLocationReferenceField: 64,
        furtherInfo: {}
      }
    },

    transactions: {
      specifiedFileName: 'TransactionsBancaires'
    },

    accessMediaReader: {
      enable: true,
      connexion: {
        wsAddress: "127.0.0.1:8082",
        isWss: true
      },
      token: "HMAC"
    },

    /****************************************
     * OGONE
     ****************************************/
    ogone: {
      templateName: 'HTPTemplate11_Full.html'
    },

    /****************************************
     * SIPS
     ****************************************/
    sips: {
      templates: {
        token: 'tap2use-non-payment', // to convert PAN to SIPS token
        payment: 'tap2use-payment' // to do a clearing
      }
    }
  },
  device: {
    threshold: {
      activityFixedDevice: {
        warn: "P1D",
        down: "PT15M",
        silent: "PT48H"
      },
      activityOnBoardDevice: {
        warn: "PT12H",
        down: "P1D",
        silent: "PT48H"
      },
      cpuUsage: {
        warn: 80,
        down: 99
      },
      diskUsage: {
        warn: 80,
        down: 95
      },
      memoryUsage: {
        warn: 80,
        down: 95
      },
      rebootCount: {
        warn: 5,
        down: 10
      }
    }
  },
  merchantMode: "ogone",
  ogone: {
    url: "https://payment.preprod.direct.worldline-solutions.com"
  }
};