<div class="modal-backdrop">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ title | translate }}</h4>
      </div>
      <div class="modal-body">
        <form class="form-horizonal" [formGroup]="cancellationReasonForm" autocomplete="off" (ngSubmit)="confirm()">
          <p class="pre-wrap msg-justify">{{ 'PERSONAL_DATA_ALERT.MESSAGE' | translate }}</p>
      
          <label class="control-label">{{ 'REFUND.DETAILS.MOTIF_ANNULATION' | translate }}</label>
          <textarea [ngClass]="{'error': cancellationReasonForm.controls['cancellationReason'].invalid }"
          class="form-control" 
                    formControlName="cancellationReason" 
                    maxlength="256"></textarea> 
          <div class="msg-justify" *ngIf="cancellationReasonForm.controls['cancellationReason'].invalid">
           {{'REFUND.ERROR.COMMENT_IS_REQUIRED' |translate}}
          </div>
          <div class="remaining">
            <span>{{ cancellationReasonForm.controls['cancellationReason'].value.length }} /256</span>
          </div>
      <div class="modal-footer">
        <button type="button" 
                class="btn btn-secondary" 
                (click)="closeForm()" 
                attr.aria-label="{{'REFUND.CANCEL_REASON.CANCEL'| translate }}">{{ 'REFUND.CANCEL_REASON.CANCEL'| translate }}</button> 
        <button type="submit" 
                [disabled]="cancellationReasonForm.invalid" 
                class="btn btn-success" 
                (click)="confirm()" 
                attr.aria-label="{{ 'REFUND.CANCEL_REASON.CONFIRM'| translate }}">{{ 'REFUND.CANCEL_REASON.CONFIRM'| translate }}</button>
      </div>
    </form>
  </div>
    </div>
  </div>
</div>
