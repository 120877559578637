import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AdministratorService } from '../services/administrator/administrator.service';
import { UserAdmin } from '../services/administrator/models/userAdmin';
import { CustomerRequestService } from '../services/customer-request/customer-request.service';
import { FinancialEngineService } from '@services/financial-engine/financial-engine.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isRefundModeDeffered: boolean;
  customerRequestEnabled = false;
  alarmStatsEnabled = false;
  isAuthorizedRefundRole: boolean; // To know if a role has the right to access refunds menu
  isPaymentCasesEnabled: boolean; // To know if the menu Payment Cases is enabled for the project
  hasPermissionForRefund: boolean;
  hasPermissionForPaymentCases: boolean;
  @Input()
  isToggle: boolean;

  userId: number;
  loggedAgent$: Observable<UserAdmin>;

  constructor(
    private adminService: AdministratorService,
    public customerRequestService: CustomerRequestService,
    private financialEngineService: FinancialEngineService
  ) { }

  ngOnInit() {
    this.loggedAgent$ = this.adminService.loggedAgent$;
    if (this.adminService.loggedAgent$.getValue()) {
      this.userId = this.adminService.loggedAgent$.getValue().userId;
      this.isAuthorizedRefundRole = ['ROOT_ADMIN', 'SUPER_ADMIN', 'MYCOMPANY_ADMIN_WITH_TARIFF_MANAGEMENT',
        'MYCOMPANY_ADMIN_WITHOUT_TARIFF_MANAGEMENT', 'MYCOMPANY_FINANCIAL_AGENT'].includes(this.adminService.loggedAgent$.getValue().role.name);
      this.isPaymentCasesEnabled = environment.admin.users.paymentCases.enabled;
      this.hasPermissionForRefund = this.adminService.loggedAgent$.getValue().role.permissions.filter(p => ['PROCESS_REFUND', 'PROCESS_MYCOMPANY_REFUND'].includes(p)).length > 0;
      this.hasPermissionForPaymentCases = this.adminService.loggedAgent$.getValue().role.permissions.filter(p => ['ACCESS_ALL_PAYMENT_CASES', 'ACCESS_MYCOMPANY_PAYMENT_CASES'].includes(p)).length > 0;
    }

    // refund enable
    this.isRefundModeDeffered = environment.admin.users.refund.enabled && environment.admin.users.refund.refundMode.deferred;
    if (this.isRefundModeDeffered) {
      this.financialEngineService.refreshRefundInitiatedCounter();
    }

    // customerRequest enable
    this.customerRequestEnabled = environment.admin.customerRequest.enable;
    if (this.customerRequestEnabled) {
      this.customerRequestService.refreshRequestCustomerCounter();
    }

    // devices alarms stats enable
    this.alarmStatsEnabled = environment.admin.devices.alarms.statistics.enable;
  }

}
