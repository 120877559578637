import { Component, OnInit, Input, HostListener, ViewEncapsulation, HostBinding, Optional, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ColumnDirective,  } from '../../table-ng/table-ng.component';
import { Sort } from '../sort.model';
import { SortsDirective } from '../sorts.directive';

@Component({
  selector: '[ap-sort-header]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./sort-header.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class SortHeaderComponent implements OnInit, OnDestroy {

  @Input('ap-sort-header') name: string;

  public sort: Sort;

  @HostBinding('class.sorting_asc') isSortingAsc = false;
  @HostBinding('class.sorting_desc') isSortingDesc = false;
  @HostBinding('class.sorting') isSorting = true;

  private $sortChange: Subscription;

  constructor(@Optional() public _sorts: SortsDirective, @Inject('TABLE_COLUMN') @Optional() public _columnDef: ColumnDirective) { }


  ngOnInit() {
    if (this._columnDef) {
      this.sort = {
        field: this._sorts.active,
        order: this._sorts.direction
      };
      if (!this.name) {
        this.name = this._columnDef.name;
      }

      if (this._sorts.active === this.name) {
        this.updateDisplay();
      }

      this.$sortChange = this._sorts.sortChange.subscribe((_sort: Sort) => {
        this.updateSort();
      });
    }
   }

  private updateSort() {
    if (this._sorts.active !== this.name) {
      this.reset();
    }
  }

  @HostListener('click', ['$event.target'])
  change() {
    this.sort = {
      field: this.name,
      order: this.sort && this.sort.order === 'ASC' ? 'DESC' : 'ASC'
    };
    this.updateDisplay();
    this._sorts.sort(this);
  }

  reset() {
    this.sort = null;
    this.updateDisplay();
  }

  private updateDisplay() {
    if (this.sort) {
      if (this.sort.order === 'ASC') {
        this.isSortingAsc = true;
        this.isSortingDesc = false;
      } else {
        this.isSortingAsc = false;
        this.isSortingDesc = true;
      }
    } else {
      this.isSortingAsc = false;
      this.isSortingDesc = false;
    }
  }

  ngOnDestroy(): void {
    this.$sortChange.unsubscribe();
  }

}
