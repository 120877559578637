import { Pipe, PipeTransform } from '@angular/core';
import { RefundCase } from '@app/core/services/financial-engine/models/refund/refund-case.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'supportToCredit'})
export class SupportToCreditPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(refund: RefundCase): string {
      return refund.accessMediaUsed.accessMediaReference === refund.accessMediaToCredit.accessMediaReference ? this.translate.instant('REFUND.DETAILS.VALIDATION_SUPPORT') : this.translate.instant('REFUND.DETAILS.OTHER_BC');
  }
}
