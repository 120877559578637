import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DateFilter } from '@app/shared/components/filter/filter-item/date-filter-item/date-filter.model';
import * as moment from 'moment';

@Component({
  selector: 'ap-table-date-filter',
  templateUrl: './table-date-filter.component.html',
  styleUrls: ['./table-date-filter.component.css']
})
export class TableDateFilterComponent implements OnChanges {

  @Input() filter: DateFilter;
  dateValue = '';

  @Output() save = new EventEmitter<DateFilter>();

  constructor() {
  }

  ngOnChanges(): void {
    if (this.filter && this.filter.value && this.filter.filterTemplate) {
      if (this.filter.filterTemplate.getDefaultValue() !== this.filter.value) {
        this.dateValue = moment(this.filter.value).format('YYYY-MM-DD');
      } else {
        this.dateValue = '';
      }
    }
  }

  onEnter() {
    this.filter.value = this.dateValue ? new Date(moment(this.dateValue).format('YYYY-MM-DD')) : null;
    this.save.emit(this.filter);
  }
}
