<ng-container *ngIf="filter">
  <div #filterDiv class="filter" [ngClass]="{'disabled': !filter.enabled}">
    <!-- Display filter in function of the type -->
    <span #content class="content" [ngSwitch]="filter.getType()">
      <ap-text-filter-item [filter]="filter" *ngSwitchCase="'TextFilter'"></ap-text-filter-item>
      <ap-sips-filter-item [filter]="filter" *ngSwitchCase="'SipsFilter'"></ap-sips-filter-item>
      <ap-selection-filter-item [filter]="filter" *ngSwitchCase="'SelectionFilter'"></ap-selection-filter-item>
      <ap-date-filter-item [filter]="filter" *ngSwitchCase="'DateFilter'"></ap-date-filter-item>
      <ap-autocomplete-filter-item [filter]="filter" *ngSwitchCase="'AutocompleteFilter'"></ap-autocomplete-filter-item>
      <ap-datetime-filter-item [filter]="filter" *ngSwitchCase="'DateTimeFilter'"></ap-datetime-filter-item>
      <ap-multiple-selection-filter-item [filter]="filter" *ngSwitchCase="'MultipleSelectionFilter'"></ap-multiple-selection-filter-item>
      <ap-month-year-filter-item [filter]="filter" *ngSwitchCase="'MonthYearFilter'"></ap-month-year-filter-item>
      <ap-access-media-filter-item [filter]="filter" *ngSwitchCase="'AccessMediaFilter'"></ap-access-media-filter-item>
    </span>
    <span #actions class="actions">
      <a (click)="onRemove()" type="button" title="{{ 'FILTERS.FILTER.ACTIONS.REMOVE' | translate }}" class="label label-danger">
        <i class="fa fa-trash" alt="Remove filter"></i>
      </a>
      <a (click)="onDisable()" type="button" title="{{ (filter.enabled ? 'FILTERS.FILTER.ACTIONS.DISABLE' : 'FILTERS.FILTER.ACTIONS.ENABLE') | translate }}" class="label label-default">
        <i class="fa" [ngClass]="{'fa-check-square-o': filter.enabled, 'fa-square-o': !filter.enabled}" alt="enabled / disabled"></i>
      </a>
      <a (click)="onEdit()" type="button" title="{{ 'FILTERS.FILTER.ACTIONS.EDIT' | translate }}" class="label label-info">
        <i class="fa fa-pencil" alt="Edit"></i>
      </a>
    </span>
  </div>
</ng-container>
