import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  /**
   * Filter a list
   *
   * @param items - The list to filter
   * @param names - The names of the properties to include or exclude
   * @param include - true if only this parameter should be included, false to exclude them
   */
  transform(items: any[], key: string, names: any[], include: boolean = true): any[] {
    return items.filter(item => {
      return include ? names.includes(item[key]) : !names.includes(item[key]);
    });
  }
}
