import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(durationParam: any, locale: string = 'fr', unit: any = null, ago = false, threshold = {}): string {
    if (durationParam === undefined || durationParam === null) {
      return null;
    }

    const duration = unit ? moment.duration(+durationParam, unit) : moment.duration(durationParam);
    return duration.locale(locale).humanize(ago, threshold);
  }
}

