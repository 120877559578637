import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ap-checklist-empty',
  templateUrl: './checklist-empty.component.html',
  styleUrls: ['./checklist-empty.component.css']
})
export class ChecklistEmptyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
