import { ActionReducer, createReducer, INIT, on, UPDATE } from "@ngrx/store";
import { FilterState } from "./filter-state";
import { clearBlockingInfoRequest, clearBlockingStateDateRequest, clearLastBlockingRequestDateRequest, clearPoiRequest, setBlockingInfoRequest, setBlockingStateDateRequest, setLastBlockingRequestDateRequest, setPoiRequest, setFilterInfo, clearFromPage, setFromPage } from "./filter-state.action";
import cloneDeep from 'lodash.clonedeep';

export const initFilterState: FilterState = null;

export const editFilterStateReducer = createReducer(
    initFilterState,
    /* poi */
    on(clearPoiRequest, (state, _) => {
        return Object.assign({}, state, {poi: ""});
    }),
    on(setPoiRequest, (state, action) => {
        return Object.assign({}, state, { poi: action.poi });
    }),
    /* lastBlockingRequest */
    on(clearLastBlockingRequestDateRequest, (state, _) => {
        const stateClone = cloneDeep(state);
        if (state.blockingInfo) {
            stateClone.blockingInfo.lastBlockingRequestDate = null;
          }
          return stateClone;
    }),
    on(setLastBlockingRequestDateRequest, (state, action) => {
        if (!state.blockingInfo) {
          return Object.assign({}, state, { blockingInfo: { lastBlockingRequestDate: action.lastBlockingRequestDate, blockingStateDate: null } });
        }
        const stateClone = cloneDeep(state);
        stateClone.blockingInfo.lastBlockingRequestDate = action.lastBlockingRequestDate;
        return stateClone;
    }),
    /* blockingStateDateRequest */
    on(clearBlockingStateDateRequest, (state, _) => {
        const stateClone = cloneDeep(state);
        if (state.blockingInfo) {
        stateClone.blockingInfo.blockingStateDate = null;
        }
        return stateClone;
    }),
    on(setBlockingStateDateRequest, (state, action) => {
        if (!state.blockingInfo) {
        return Object.assign({}, state, { blockingInfo: { lastBlockingRequestDate: null, blockingStateDate: action.blockingStateDate } });
        }
        const stateClone = cloneDeep(state);
        stateClone.blockingInfo.blockingStateDate = action.blockingStateDate;
        return stateClone;
    }),
    /* blockingInfoRequest */
    on(clearBlockingInfoRequest, (state, _) => {
        return Object.assign({}, state, { blockingInfo: null });
    }),
    on(setBlockingInfoRequest, (state, action) => {
        return Object.assign({}, state, { blockingInfo: action.blockingInfo });
    }),
    /* filterInfo */
    on(setFilterInfo, (state, action) => {
        return Object.assign({}, state, { filterInfo: action.filterInfo });
    }),
    /* fromPage */
    on(clearFromPage, (state, _) => {
        return Object.assign({}, state, { fromPage: ""});
    }),
    on(setFromPage, (state, action) => {
        return Object.assign({}, state, { fromPage: action.fromPage });
    })
);

// TODO add metaReduceur 

export const metaReducerLocalStorageFilter = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
          const storageValue = localStorage.getItem("FilterState");
          if (storageValue) {
            try {
              return JSON.parse(storageValue);
            } catch {
              localStorage.removeItem("FilterState");
            }
          }
        }
        const nextState = reducer(state, action);
        localStorage.setItem("FilterState", JSON.stringify(nextState));
        return nextState;
      };
}