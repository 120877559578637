import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SipsFilter } from '../../filter-item/sips-filter-item/sips-filter.model';
import cloneDeep from 'lodash.clonedeep';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { SimpleModalService } from 'ngx-simple-modal';
import { SipsSearchComponent } from '@app/shared/components/sips-search/sips-search.component';

@Component({
  selector: 'ap-sips-filter-template',
  templateUrl: './sips-filter-template.component.html',
  styleUrls: ['./sips-filter-template.component.css']
})
export class SipsFilterTemplateComponent extends AbstractOnDestroyComponent implements OnInit {

  @Input()
  get filter(): SipsFilter { return this._filter; }
  set filter(filter: SipsFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: filter.value});
  }
  _filter: SipsFilter;

  @Output() addFilter = new EventEmitter<SipsFilter>();

  filterForm: UntypedFormGroup;
  sips: any;
  error = false;

  constructor(private formBuilder: UntypedFormBuilder, private simpleModalService: SimpleModalService) {
    super();
    this.filterForm = this.formBuilder.group({
      value: ['', Validators.required]
    });
  }

  ngOnInit() {}

  confirmSearch() {
    const modal = this.simpleModalService.addModal(SipsSearchComponent).subscribe(token => {
      if (token) {
        this._filter.value = token;
        this.addFilter.emit(cloneDeep(this._filter));
      }
    });

    // Automatically close modal after 5 minutes
    setTimeout( () => modal.unsubscribe(), 5 * 60 * 1000);
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = this.filterForm.value.value;
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

  searchByCB() {
    if (!this.error) {
      const form = document.getElementById('cards-form') as HTMLFormElement;
      form.submit();
    }
  }
}
