import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ap-checkbox-ng',
  host: {'(click)': 'selectItem()', '(blur)': 'onTouched()'},
  template:   `
    <div class="icheckbox_flat-green" [ngClass]="{'checked': currentValue.getValue() }">
      <input aria-label="none" type="checkbox" class="flat" style="position: absolute; opacity: 0;">
      <ins class="iCheck-helper" style="display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 0px none; opacity: 0;"></ins>
    </div>`,
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxNgComponent),
        multi: true
      }
    ]
})

export class CheckboxNgComponent implements ControlValueAccessor {

  currentValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  onTouched: () => void;
  constructor() {}

  registerOnChange(fn: any): void {
    this.currentValue.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: boolean): void {
    this.currentValue.next(obj);
  }

  selectItem() {
    this.currentValue.next(!this.currentValue.getValue());
  }
}
