import { Input, Directive } from '@angular/core';
import { FilterData } from '../../filter/filterData';

@Directive({
    selector: '[ap-t-filters]'
})
export class TFiltersDirective {

    @Input()
    filterData: FilterData;

    constructor() {

    }

}
