import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[ap-focus]' })
export class FocusDirective implements AfterViewInit {

  constructor(private ref: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ref.nativeElement.focus();
    }, 0);
  }

}
