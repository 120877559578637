export class Pagination {
  totalElements: number;
  totalPages: number;
  elements: number;
  page: number;

  constructor(totalElements: number, totalPages: number, elements: number, page: number) {
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.elements = elements;
    this.page = page;
  }

  public static default(elements: number = 10): Pagination {
    return new Pagination(0, 0, elements, 0);
  }
}
