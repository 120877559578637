import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Observable, Subscription } from 'rxjs';

export interface ConfirmProcessingModel<T> {
  title?: string;
  message?: string;
  confirmButton?: string;
  closeButton?: string;
  process: Observable<T>;
}

@Component({
  selector: 'ap-confirm',
  templateUrl: './confirm-processing.component.html',
  styleUrls: ['./confirm-processing.component.css']
})
export class ConfirmProcessingModal<T> extends SimpleModalComponent<ConfirmProcessingModel<T>, T> implements ConfirmProcessingModel<T>, OnDestroy {
  title: string;
  message: string;
  confirmButton: string;
  closeButton: string;
  process: Observable<T>;
  loading = false;

  process$: Subscription;

  constructor(private router: Router) {
    super();
  }

  confirm() {
    this.loading = true;
    this.process$ = this.process.subscribe((data: T) => {
      this.result = data;
      this.close();
    }, () => {
      this.close();
      this.router.navigate(['/error/500']);
    });
  }

  cancel() {
    this.close();
  }

  ngOnDestroy(): void {
    if (this.process$) {
      this.process$.unsubscribe();
    }
  }
}
