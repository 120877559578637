<div class="modal-backdrop">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- Template to display paypage sips -->
      <div class="text-center">
        <ap-sips *ngIf="sipsRequest" [sipsRequest]="sipsRequest" (receive)="receiveFromSips($event)">
          <ap-sips-message>
            <h4 class="info">{{title || 'ACCESS_MEDIA.SEARCH_LABEL' | translate }}</h4>
          </ap-sips-message>
        </ap-sips>
      </div>

    </div>
  </div>
</div>
