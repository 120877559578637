import { Directive, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'input[ap-toogle-password][type=password]',
})
export class TooglePasswordComponent implements OnInit {
  constructor(public elementRef: ElementRef, public translateService: TranslateService) { }

  ngOnInit() {
    const element = this.elementRef.nativeElement;

    const eye = document.createElement('i');
    eye.setAttribute('class', 'fa fa-eye form-control-feedback');
    eye.style.pointerEvents = 'all';
    eye.style.cursor = 'pointer';
    element.setAttribute('placeholder', '********')

    eye.addEventListener('click', () => {
        if (element.getAttribute('type') === 'password') {
            element.setAttribute('type', 'text');
            eye.setAttribute('class', 'fa fa-eye-slash form-control-feedback')
            element.setAttribute('placeholder', this.translateService.instant('SHARED.COMPONENT.TOOGLE_PASSWORD.PLACEHOLDER'))
        } else {
            element.setAttribute('type', 'password');
            eye.setAttribute('class', 'fa fa-eye form-control-feedback')
            element.setAttribute('placeholder', '********')
        }
    });

    element.parentNode.insertBefore(eye, element.nextSibling)
  }
}
