/* eslint-disable */
import { SipsSearchComponent } from './components/sips-search/sips-search.component';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StatusLabelComponent } from './labels/status-label/status-label.component';
import { LoadingViewComponent } from './loading-view/loading-view.component';
import { ConfirmComponent } from './components/modals/confirm/confirm.component';
import { RouterModule } from '@angular/router';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { ChecklistItemComponent } from './components/checklist/checklist-item/checklist-item.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SortComponent } from './components/table/sort/sort.component';
import { TableComponent } from './components/table/table.component';
import { LabelComponent } from './components/label/label.component';
import { PanelComponent } from './components/panel/panel.component';
import { PanelTitleComponent } from './components/panel/panel-title/panel-title.component';
import { CardComponent } from './components/card/card.component';
import { CardBodyComponent } from './components/card/card-body/card-body.component';
import { CardFooterComponent } from './components/card/card-footer/card-footer.component';
import { PanelContentComponent } from './components/panel/panel-content/panel-content.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { CheckListItemDirective } from './components/checklist/checklist-item/checklist-item.directive';
import { ChecklistEmptyComponent } from './components/checklist/checklist-empty/checklist-empty.component';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { LocalFilterPipe } from './pipes/local-filter.pipe';
import { MapComponent } from './components/map/map.component';
import { MediaEventComponent } from './components/media-event/media-event.component';
import { MediaEventIconComponent } from './components/media-event/media-event-icon/media-event-icon.component';
import { MediaEventTitleComponent } from './components/media-event/media-event-title/media-event-title.component';
import { MediaEventContentComponent } from './components/media-event/media-event-content/media-event-content.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NavBarTabComponent } from './components/nav-bar/nav-bar-tab/nav-bar-tab.component';
import { DefaultIfEmptyPipe } from './pipes/defaultIfEmpty.pipe';
import { ConcatPipe } from './pipes/concat.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { StepsComponent } from './components/steps/steps.component';
import { StepComponent } from './components/steps/step/step.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FindPipe } from './pipes/find.pipe';
import { PageTitleComponent } from './components/page/page-title/page-title.component';
import { PageComponent } from './components/page/page.component';
import { PageToolbarComponent } from './components/page/page-toolbar/page-toolbar.component';
import { PageContentComponent } from './components/page/page-content/page-content.component';
import { FilterComponent } from './components/filter/filter.component';
import { FilterListComponent } from './components/filter/filter-list/filter-list.component';
import { FilterTemplateComponent } from './components/filter/filter-template/filter-template.component';
import { TextFilterTemplateComponent } from './components/filter/filter-template/text-filter-template/text-filter-template.component';
import { SipsFilterTemplateComponent } from './components/filter/filter-template/sips-filter-template/sips-filter-template.component';
import { SipsFilterItemComponent } from './components/filter/filter-item/sips-filter-item/sips-filter-item.component';
import { FilterItemComponent } from './components/filter/filter-item/filter-item.component';
import { TextFilterItemComponent } from './components/filter/filter-item/text-filter-item/text-filter-item.component';
import { FilterAddComponent } from './components/filter/filter-add/filter-add.component';
import { FilterEditComponent } from './components/filter/filter-edit/filter-edit.component';
import { SelectionFilterTemplateComponent } from './components/filter/filter-template/selection-filter-template/selection-filter-template.component';
import { SelectionFilterItemComponent } from './components/filter/filter-item/selection-filter-item/selection-filter-item.component';
import { TableFilterComponent } from './components/table/table-filter/table-filter.component';
import { TableTextFilterComponent } from './components/table/table-filter/table-text-filter/table-text-filter.component';
import { TableSelectFilterComponent } from './components/table/table-filter/table-select-filter/table-select-filter.component';
import { TableDateFilterComponent } from './components/table/table-filter/table-date-filter/table-date-filter.component';
import { FilterSaveComponent } from './components/filter/filter-save/filter-save.component';
import { FilterLoadComponent } from './components/filter/filter-load/filter-load.component';
import { DateFilterItemComponent } from './components/filter/filter-item/date-filter-item/date-filter-item.component';
import { DateFilterTemplateComponent } from './components/filter/filter-template/date-filter-template/date-filter-template.component';
import { ToolTipDirective } from './directives/tooltip/tooltip.directive';
import { BankSchemaPipe } from './pipes/bankSchemaPipe';
import { TableSipsFilterComponent } from './components/table/table-filter/table-sips-filter/table-sips-filter.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckboxNgComponent } from './components/checkbox/checkboxNg.component';
import { RadioComponent } from './components/radio/radio.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteFilterTemplateComponent } from './components/filter/filter-template/autocomplete-filter-template/autocomplete-filter-template.component';
import { AutocompleteFilterItemComponent } from './components/filter/filter-item/autocomplete-filter-item/autocomplete-filter-item.component';
/* eslint-enable */
import { OrderByPipe } from './pipes/orderBy';
import { SipsComponent } from './components/sips/sips.component';
import { SafePipe } from './pipes/safe.pipe';
import { BtnLoadingComponent } from './components/button/btn-loading.component';
import { TableNgComponent, ColumnDirective, HeaderDirective, CellDirective, SubHeaderDirective, BulkHeaderDirective } from './components/table-ng/table-ng.component';
import { SortsDirective } from './components/sort/sorts.directive';
import { SortHeaderComponent } from './components/sort/sort/sort-header.component';
import { TFiltersDirective } from './components/table-ng/table-filter/t-filters.directive';
import { TableFilterHeaderComponent } from './components/table-ng/table-filter/t-filter-header.component';
import { CompanyPipe } from './pipes/company.pipe';
import { ApSecureDirective } from './directives/secure/secure.directive';
import { WeekDaysPipe } from './pipes/week-days.pipe';
import { DateTimeFilterTemplateComponent } from './components/filter/filter-template/datetime-filter-template/datetime-filter-template.component';
import { DateTimeFilterItemComponent } from './components/filter/filter-item/datetime-filter-item/datetime-filter-item.component';
import { TruncateMaskedPanPipe } from './pipes/truncateMaskedPan.pipe';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SideMenuItemComponent } from './components/side-menu/side-menu-item/side-menu-item.component';
import { SideSubmenuComponent } from './components/side-menu/side-submenu/side-submenu.component';
import { SideSubMenuItemsComponent } from './components/side-menu/side-submenu/side-submenu-items.component';
import { userStringPipe } from './pipes/userString.pipe';
import { SupportToCreditPipe } from './pipes/supportToCredit.pipe';
import { AdminNameComponent } from './components/admin-name/admin-name.component';
import { ObsWithStatusPipe } from './pipes/obs-with-status.pipe';
import { OverlayComponent } from './components/overlay/overlay.component';
import { FilterByTermPipe } from './pipes/filter-by-term.pipe';
import { FocusDirective } from './directives/focus/focus.directive';
import { FilterLoadOverlayComponent } from './components/filter/filter-load/filter-load-overlay/filter-load-overlay.component';
import { OverlayService } from './components/overlay/overlay.service';
import { AutoresizeDirective } from './directives/autoresize/autoresize.directive';
import { ConfirmProcessingModal } from './components/modals/confirm-processing/confirm-processing.component';
import { SelectComponent } from './components/select/select.component';
import { OptionComponent, OptionInfoComponent, OptOptionComponent } from './components/select/option.component';
import { CalloutComponent } from './components/callout/callout.component';
import { InternationalisationPluralPipe } from './pipes/i18nPlural';
import { PageHeightDirective } from './directives/page-height/pageHeight.directive';
import { SipsMessageComponent } from './components/sips/sips-message/sips-message.component';
import { BankCardComponent } from './components/bank-card/bank-card.component';
import { JoinPipe } from './pipes/join.pipe';
import { TooglePasswordComponent } from './components/toogle-password/toogle-password.component';
import { MultipleSelectionFilterTemplateComponent } from './components/filter/filter-template/multiple-selection-filter-template/multiple-selection-filter-template.component';
import { MultipleSelectionFilterItemComponent } from './components/filter/filter-item/multiple-selection-filter-item/multiple-selection-filter-item.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { TableMultipleSelectFilterComponent } from './components/table/table-filter/table-multiple-select-filter/table-multiple-select-filter.component';
import { TableAutocompleteFilterTemplateComponent } from './components/table/table-filter/table-autocomplete-filter-template/table-autocomplete-filter-template.component';
import { ReloadCalloutComponent } from './components/reload-callout/reload-callout.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { ExecutionModePipe } from './pipes/execution-mode.pipe';
import { ExportSettlementDataComponent } from './components/export-data/export-component/export-settlement-data.component';
import { SaccGatewayModalComponent } from './components/modals/sacc-gateway-modal/sacc-gateway-modal.component';
import { PeriodBarComponent } from './components/period-bar/period-bar.component';
import { FilterApplicableProductPipe } from './pipes/filter-applicable-product.pipe';
import { MonthYearFilterItemComponent } from './components/filter/filter-item/month-year-filter-item/month-year-filter-item.component';
import { MonthYearFilterTemplateComponent } from './components/filter/filter-template/month-year-filter-template/month-year-filter-template.component';
import { AccessMediaReaderComponent } from './components/access-media-reader/access-media-reader.component';
import { PaireLinkModalComponent } from './components/modals/paire-link-modal/paire-link-modal.component';
import { AccessMediaFilterItemComponent } from './components/filter/filter-item/access-media-filter-item/access-media-filter-item.component';
import { AccessMediaFilterTemplateComponent } from './components/filter/filter-template/access-media-filter-template/access-media-filter-template.component';
import { WaitingModalComponent } from './components/modals/waiting-modal/waiting-modal.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { BackToListButtonComponent } from './components/back-to-list-button/back-to-list-button.component';
import { ExportDevicesDataComponent } from './components/export-data/export-component/export-devices-data.component';
import { ExportDataComponent } from './components/export-data/export-data.component';
import { ExportDeviceAlarmsDataComponent } from './components/export-data/export-component/export-device-alarms-data.component';
import { OgoneComponent } from './components/ogone/ogone.component';
import { OgoneMotoComponent } from './components/ogone-moto/ogone-moto.component';
import { OgoneMessageComponent } from './components/ogone/ogone-message/ogone-message.component';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { CancelRefundModalComponent } from './components/modals/cancel-refund-modal/cancel-refund-modal.component';
import { ShowMessageModalComponent } from './components/modals/show-message-modal/show-message-modal.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        StatusLabelComponent,
        AutoresizeDirective,
        LoadingViewComponent,
        ConfirmComponent,
        SipsSearchComponent,
        ChecklistComponent,
        ChecklistItemComponent,
        PaginationComponent,
        SortComponent,
        TableComponent,
        LabelComponent,
        PanelComponent,
        PanelTitleComponent,
        CardComponent,
        CardBodyComponent,
        CardFooterComponent,
        PanelContentComponent,
        JumbotronComponent,
        CheckListItemDirective,
        ChecklistEmptyComponent,
        LocalizedDatePipe,
        LocalFilterPipe,
        MapComponent,
        MediaEventComponent,
        MediaEventIconComponent,
        MediaEventTitleComponent,
        MediaEventContentComponent,
        ProgressBarComponent,
        NavBarComponent,
        NavBarTabComponent,
        DefaultIfEmptyPipe,
        ConcatPipe,
        PluralPipe,
        DurationPipe,
        StepsComponent,
        StepComponent,
        FilterPipe,
        FindPipe,
        BankSchemaPipe,
        PageComponent,
        PageTitleComponent,
        PageToolbarComponent,
        PageContentComponent,
        FilterComponent,
        FilterListComponent,
        FilterTemplateComponent,
        TextFilterTemplateComponent,
        SipsFilterTemplateComponent,
        TableDateFilterComponent,
        FilterItemComponent,
        TextFilterItemComponent,
        SipsFilterItemComponent,
        FilterAddComponent,
        FilterEditComponent,
        SelectionFilterTemplateComponent,
        SelectionFilterItemComponent,
        TableAutocompleteFilterTemplateComponent,
        TableFilterComponent,
        TableTextFilterComponent,
        TableSipsFilterComponent,
        TableSelectFilterComponent,
        FilterSaveComponent,
        FilterLoadComponent,
        DateFilterItemComponent,
        DateFilterTemplateComponent,
        ToolTipDirective,
        CheckboxComponent,
        CheckboxNgComponent,
        RadioComponent,
        AutocompleteComponent,
        AutocompleteFilterItemComponent,
        AutocompleteFilterTemplateComponent,
        OrderByPipe,
        SipsComponent,
        SipsMessageComponent,
        SafePipe,
        BtnLoadingComponent,
        TableNgComponent,
        CellDirective,
        ColumnDirective,
        HeaderDirective,
        SubHeaderDirective,
        SortsDirective,
        SortHeaderComponent,
        TFiltersDirective,
        TableFilterHeaderComponent,
        BulkHeaderDirective,
        CompanyPipe,
        WeekDaysPipe,
        ApSecureDirective,
        CheckboxNgComponent,
        DateTimeFilterTemplateComponent,
        DateTimeFilterItemComponent,
        TruncateMaskedPanPipe,
        SideMenuComponent,
        SideMenuItemComponent,
        SideSubmenuComponent,
        SideSubMenuItemsComponent,
        userStringPipe,
        SupportToCreditPipe,
        AdminNameComponent,
        ObsWithStatusPipe,
        OverlayComponent,
        FilterByTermPipe,
        FocusDirective,
        FilterLoadOverlayComponent,
        ConfirmProcessingModal,
        OptionComponent,
        OptOptionComponent,
        SelectComponent,
        OptionInfoComponent,
        CalloutComponent,
        InternationalisationPluralPipe,
        PageHeightDirective,
        BankCardComponent,
        PageHeightDirective,
        JoinPipe,
        TooglePasswordComponent,
        MultipleSelectionFilterTemplateComponent,
        MultipleSelectionFilterItemComponent,
        MultipleSelectComponent,
        TableMultipleSelectFilterComponent,
        ReloadCalloutComponent,
        TimelineComponent,
        ExecutionModePipe,
        ExportSettlementDataComponent,
        SaccGatewayModalComponent,
        PeriodBarComponent,
        FilterApplicableProductPipe,
        MonthYearFilterItemComponent,
        MonthYearFilterTemplateComponent,
        AccessMediaReaderComponent,
        AccessMediaFilterItemComponent,
        AccessMediaFilterTemplateComponent,
        PaireLinkModalComponent,
        BackToListButtonComponent,
        WaitingModalComponent,
        ErrorModalComponent,
        ExportDevicesDataComponent,
        ExportDataComponent,
        ExportDeviceAlarmsDataComponent,
        OgoneComponent,
        OgoneMotoComponent,
        OgoneMessageComponent,
        MaintenanceBannerComponent,
        CancelRefundModalComponent,
        ShowMessageModalComponent
    ],
    exports: [
        StatusLabelComponent,
        AutoresizeDirective,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LocalizedDatePipe,
        LoadingViewComponent,
        ConfirmComponent,
        SipsSearchComponent,
        ChecklistComponent,
        ChecklistItemComponent,
        PaginationComponent,
        TableComponent,
        SortComponent,
        LabelComponent,
        PanelComponent,
        PanelTitleComponent,
        CardComponent,
        CardBodyComponent,
        CardFooterComponent,
        PanelContentComponent,
        JumbotronComponent,
        CheckListItemDirective,
        ChecklistEmptyComponent,
        LocalFilterPipe,
        MapComponent,
        MediaEventComponent,
        MediaEventIconComponent,
        MediaEventTitleComponent,
        MediaEventContentComponent,
        ProgressBarComponent,
        NavBarComponent,
        NavBarTabComponent,
        DefaultIfEmptyPipe,
        ConcatPipe,
        PluralPipe,
        DurationPipe,
        StepsComponent,
        StepComponent,
        FilterPipe,
        FindPipe,
        BankSchemaPipe,
        PageComponent,
        PageTitleComponent,
        PageToolbarComponent,
        PageContentComponent,
        FilterListComponent,
        FilterComponent,
        TableFilterComponent,
        ToolTipDirective,
        CheckboxComponent,
        CheckboxNgComponent,
        RadioComponent,
        AutocompleteComponent,
        AutocompleteFilterItemComponent,
        AutocompleteFilterTemplateComponent,
        OrderByPipe,
        SipsComponent,
        SipsMessageComponent,
        SafePipe,
        BtnLoadingComponent,
        TableNgComponent,
        CellDirective,
        ColumnDirective,
        HeaderDirective,
        SubHeaderDirective,
        SortsDirective,
        SortHeaderComponent,
        TFiltersDirective,
        TableFilterHeaderComponent,
        BulkHeaderDirective,
        CompanyPipe,
        ApSecureDirective,
        CheckboxNgComponent,
        WeekDaysPipe,
        DateTimeFilterTemplateComponent,
        DateTimeFilterItemComponent,
        TruncateMaskedPanPipe,
        SideMenuComponent,
        SideMenuItemComponent,
        SideSubmenuComponent,
        SideSubMenuItemsComponent,
        userStringPipe,
        SupportToCreditPipe,
        AdminNameComponent,
        ObsWithStatusPipe,
        OverlayComponent,
        ConfirmProcessingModal,
        OptionComponent,
        OptOptionComponent,
        SelectComponent,
        OptionInfoComponent,
        CalloutComponent,
        InternationalisationPluralPipe,
        PageHeightDirective,
        BankCardComponent,
        JoinPipe,
        TooglePasswordComponent,
        MultipleSelectionFilterTemplateComponent,
        ReloadCalloutComponent,
        TimelineComponent,
        ExecutionModePipe,
        SaccGatewayModalComponent,
        PeriodBarComponent,
        FilterApplicableProductPipe,
        AccessMediaReaderComponent,
        BackToListButtonComponent,
        OgoneComponent,
        OgoneMotoComponent,
        OgoneMessageComponent,
        MaintenanceBannerComponent,
        CancelRefundModalComponent,
        ShowMessageModalComponent
    ],
    providers: [
        OverlayService,
        FindPipe
    ], schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
