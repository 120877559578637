<a
  [attr.rel]="rel"
  [attr.isdone]="isdone"
  [className]="activeStep === rel ? 'selected' : 'disabled'"
>
  <span class="step_no">{{ stepValue }}</span>
  <span class="step_descr">
    {{ title }}<br />
    <small>{{ subtitle || '' }}</small>
  </span>
</a>
