import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find',
  pure: true
})
export class FindPipe implements PipeTransform {

  /**
   * Find an element in a list
   *
   * @param items - The list to filter
   * @param predicate - An object the key / value to find
   */
  transform(items: any[], predicate: any): any {

    return items?.find(item => {
      return Object.keys(predicate).every(key => item[key] === predicate[key]);
    });
  }
}
