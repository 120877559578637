import { Component, Input, OnInit } from '@angular/core';
import { MonthYearFilter } from './month-year-filter.model';

@Component({
  selector: 'ap-month-year-filter-item',
  templateUrl: './month-year-filter-item.component.html'
})
export class MonthYearFilterItemComponent implements OnInit {

  @Input() filter: MonthYearFilter;

  constructor() { }

  ngOnInit(): void {
  }

}
