import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ap-panel-content',
  templateUrl: './panel-content.component.html',
  styleUrls: ['./panel-content.component.css']
})
export class PanelContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
