/*
 *ngFor="let c of oneDimArray | orderBy:'asc'"
 *ngFor="let c of arrayOfObjects | orderBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import orderBy from 'lodash.orderby';

@Pipe({ name: 'orderBy', pure: true })
export class OrderByPipe implements PipeTransform {

  transform(value: any[], order: 'asc' | 'desc' = 'asc', column: string = ''): any[] {
    if (!value) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
      if (order === 'asc') { return value.sort(); } else { return value.sort().reverse(); }
    } // sort 1d array

    return orderBy(value, [v => v[column].toString().toLocaleLowerCase('fr')], [order]);
  }
}
