<div class="modal-backdrop">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{title || 'USER.MODALS.USER.DELETE.TITLE' | translate }}</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="email; else elseContainer">
          <p class="pre-wrap">{{'USER.MODALS.USER.DELETE.MESSAGE_WITH_EMAIL' | translate:{ email: email } }}</p>
        </ng-container>
        <ng-template #elseContainer>
          <p class="pre-wrap">{{message || 'USER.MODALS.USER.DELETE.MESSAGE' | translate }}</p>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()" attr.aria-label="{{ 'COMMENTS.MODALS.CREATE.YES'| translate }}">{{confirmButton || 'COMMENTS.MODALS.CREATE.YES'| translate }}</button>
        <button type="button" class="btn btn-default" (click)="cancel()" attr.aria-label="{{ 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}">{{closeButton || 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}</button>
      </div>
    </div>
  </div>
</div>
