import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextFilter } from '@app/shared/components/filter/filter-item/text-filter-item/text-filter.model';

@Component({
  selector: 'ap-table-text-filter',
  templateUrl: './table-text-filter.component.html',
  styleUrls: ['./table-text-filter.component.css']
})
export class TableTextFilterComponent implements OnInit {

  @Input() filter: TextFilter;

  @Output() save = new EventEmitter<TextFilter>();

  constructor() { }

  ngOnInit() {
  }

  onEnter() {
    this.save.emit(this.filter);
  }
}
