export enum PermissionEnum {
    ACCESS_ALL_ACCESS_MEDIA = 'ACCESS_ALL_ACCESS_MEDIA',
    ACCESS_ALL_ACCOUNTS = 'ACCESS_ALL_ACCOUNTS',
    ACCESS_ALL_ACTIVITIES = 'ACCESS_ALL_ACTIVITIES',
    ACCESS_ALL_ADDRESSES = 'ACCESS_ALL_ADDRESSES',
    ACCESS_ALL_CARTS = 'ACCESS_ALL_CARTS',
    ACCESS_ALL_CONSENTS = 'ACCESS_ALL_CONSENTS',
    ACCESS_ALL_CONTRACTS = 'ACCESS_ALL_CONTRACTS',
    ACCESS_ALL_DEBTS = 'ACCESS_ALL_DEBTS',
    ACCESS_ALL_DOC_PROOF = 'ACCESS_ALL_DOC_PROOF',
    ACCESS_ALL_INVOICES = 'ACCESS_ALL_INVOICES',
    ACCESS_ALL_PAYMENT_MEANS = 'ACCESS_ALL_PAYMENT_MEANS',
    ACCESS_ALL_PAYMENTS = 'ACCESS_ALL_PAYMENTS',
    ACCESS_ALL_PURCHASES = 'ACCESS_ALL_PURCHASES',
    ACCESS_ALL_RECEIPTS = 'ACCESS_ALL_RECEIPTS',
    ACCESS_ALL_SUBSCRIPTIONS = 'ACCESS_ALL_SUBSCRIPTIONS',
    ACCESS_ALL_USERS = 'ACCESS_ALL_USERS',
    ACCESS_CONTROLS = 'ACCESS_CONTROLS',
    ACCESS_MY_ACCESS_MEDIA = 'ACCESS_MY_ACCESS_MEDIA',
    ACCESS_MY_ACCOUNT = 'ACCESS_MY_ACCOUNT',
    ACCESS_MY_ACTIVITIES = 'ACCESS_MY_ACTIVITIES',
    ACCESS_MY_ADDRESS = 'ACCESS_MY_ADDRESS',
    ACCESS_MY_CART = 'ACCESS_MY_CART',
    ACCESS_MY_CONSENT = 'ACCESS_MY_CONSENT',
    ACCESS_MY_CONTRACTS = 'ACCESS_MY_CONTRACTS',
    ACCESS_MY_DEBTS = 'ACCESS_MY_DEBTS',
    ACCESS_MY_DOC_PROOF = 'ACCESS_MY_DOC_PROOF',
    ACCESS_MY_INVOICE = 'ACCESS_MY_INVOICE',
    ACCESS_MY_PAYMENT_MEAN = 'ACCESS_MY_PAYMENT_MEAN',
    ACCESS_MY_PAYMENTS = 'ACCESS_MY_PAYMENTS',
    ACCESS_MY_PURCHASE = 'ACCESS_MY_PURCHASE',
    ACCESS_MY_RECEIPT = 'ACCESS_MY_RECEIPT',
    ACCESS_MY_SAV_TICKETS = 'ACCESS_MY_SAV_TICKETS',
    ACCESS_MY_SUBSCRIPTIONS = 'ACCESS_MY_SUBSCRIPTIONS',
    ACCESS_MY_USER = 'ACCESS_MY_USER',
    ACCESS_PRODUCT = 'ACCESS_PRODUCT',
    ACCESS_TERMINAL_INFO = 'ACCESS_TERMINAL_INFO',
    ACCESS_TERMINAL_LOG = 'ACCESS_TERMINAL_LOG',
    ACCESS_TICKETS = 'ACCESS_TICKETS',
    BLOCK_TERMINALS = 'BLOCK_TERMINALS',
    BLOCK_MYCOMPANY_TERMINALS = 'BLOCK_MYCOMPANY_TERMINALS',
    CONFIGURE_MAIN_TERMINAL = 'CONFIGURE_MAIN_TERMINAL',
    CONFIGURE_TERMINAL = 'CONFIGURE_TERMINAL',
    CONFIGURE_MYCOMPANY_TERMINAL = 'CONFIGURE_MYCOMPANY_TERMINAL',
    CREATE_TAP = 'CREATE_TAP',
    DISTRIBUTE_PRODUCT = 'DISTRIBUTE_PRODUCT',
    LOGIN_CONTROLER = 'LOGIN_CONTROLER',
    MANAGE_APPORTIONMENT = 'MANAGE_APPORTIONMENT',
    MANAGE_INVOICE_TEMPLATE = 'MANAGE_INVOICE_TEMPLATE',
    MANAGE_RECEIPT_TEMPLATE = 'MANAGE_RECEIPT_TEMPLATE',
    MANAGE_SUBSCRIPTIONS = 'MANAGE_SUBSCRIPTIONS',
    MANAGE_SAV_TICKETS = 'MANAGE_SAV_TICKETS',
    VALIDATE_ADDRESSES = 'VALIDATE_ADDRESSES',
    VALIDATE_IDENTITY = 'VALIDATE_IDENTITY',
    VALIDATE_PAYMENT_MEAN = 'VALIDATE_PAYMENT_MEAN',
    ACCESS_STATISTICS = 'ACCESS_STATISTICS',
    ACCESS_TOPOLOGY = 'ACCESS_TOPOLOGY',
    MANAGE_VALIDATION_REQUEST = 'MANAGE_VALIDATION_REQUEST',
    ACCESS_ALL_EVENTS = 'ACCESS_ALL_EVENTS',
    ACCESS_MY_EVENTS = 'ACCESS_MY_EVENTS',
    ACCESS_MY_BILLS = 'ACCESS_MY_BILLS',
    ACCESS_ALL_BILLS = 'ACCESS_ALL_BILLS',
    ACCESS_TERMINAL_SUPERVISION = 'ACCESS_TERMINAL_SUPERVISION',
    DISTRIBUTE_CONTRACT_DEFAULT = 'DISTRIBUTE_CONTRACT_DEFAULT',
    ACCESS_ALL_TRANSPORT_OFFERS = 'ACCESS_ALL_TRANSPORT_OFFERS',
    ACCESS_MY_INVOICES = 'ACCESS_MY_INVOICES',
    ACCESS_CONTRACTS = 'ACCESS_CONTRACTS',
    ACCESS_ALL_TARIFFS = 'ACCESS_ALL_TARIFFS',
    ACCESS_MONITORING_TERMINAL = 'ACCESS_MONITORING_TERMINAL',
    ACCESS_STATISTICS_TERMINAL = 'ACCESS_STATISTICS_TERMINAL',
    ACCESS_TARIFF = 'ACCESS_TARIFF',
    ACCESS_TERMINAL_HISTORY_DETAILS = 'ACCESS_TERMINAL_HISTORY_DETAILS',
    CONFIGURE_MY_USER = 'CONFIGURE_MY_USER',
    CONFIGURE_TARIFF = 'CONFIGURE_TARIFF',
    FULL_CONFIGURE_TERMINAL = 'FULL_CONFIGURE_TERMINAL',
    MANAGE_ALARM = 'MANAGE_ALARM',
    MANAGE_REFUND = 'MANAGE_REFUND',
    MANAGE_STATISTICS = 'MANAGE_STATISTICS',
    REMOVE_ACCOUNT = 'REMOVE_ACCOUNT',
    ACCESS_MONITORING_MYCOMPANY_TERMINAL = 'ACCESS_MONITORING_MYCOMPANY_TERMINAL',
    ACCESS_MYCOMPANY_STATISTICS = 'ACCESS_MYCOMPANY_STATISTICS',
    ACCESS_MYCOMPANY_USERS = 'ACCESS_MYCOMPANY_USERS',
    ACCESS_STATISTICS_MYCOMPANY_TERMINAL = 'ACCESS_STATISTICS_MYCOMPANY_TERMINAL',
    MANAGE_ROLES = 'MANAGE_ROLES',
    MANAGE_SHARED_FILTERS = 'MANAGE_SHARED_FILTERS',
    MANAGE_MYCOMPANY_SHARED_FILTERS = 'MANAGE_MYCOMPANY_SHARED_FILTERS', 
    ACCESS_ALL_PAYMENT_CASES = 'ACCESS_ALL_PAYMENT_CASES',
    ACCESS_MYCOMPANY_PAYMENT_CASES = 'ACCESS_MYCOMPANY_PAYMENT_CASES',
    ACCESS_INTERNAL_TOOL = 'ACCESS_INTERNAL_TOOL'
}
