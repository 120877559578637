import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Entry } from '@app/shared/components/autocomplete/autocomplete.component';
import cloneDeep from 'lodash.clonedeep';
import { AutocompleteFilter } from '@app/shared/components/filter/filter-item/autocomplete-filter-item/autocomplete-filter.model';

@Component({
  selector: 'ap-table-autocomplete-filter-template',
  templateUrl: './table-autocomplete-filter-template.component.html',
  styleUrls: ['./table-autocomplete-filter-template.component.css']
})
export class TableAutocompleteFilterTemplateComponent implements OnInit {
  value: Entry<string, string>;

  @Input()
  get filter(): AutocompleteFilter { return this._filter; }
  set filter(filter: AutocompleteFilter) {
    this._filter = filter;
    this.value = this._filter.value;
  }
  _filter: AutocompleteFilter;

  @Output() save = new EventEmitter<AutocompleteFilter>();

  constructor() {
  }

  ngOnInit() {  }

  ngOnChanges(changes: SimpleChanges){

    if (!changes.filter.currentValue.value) {
      this.onClear();
    }
  }
  onSelect(entry: Entry<string, string>) {
    this.value = entry;
    this._filter.value = this.value;
    this.save.emit(cloneDeep(this._filter));
  }

  onClear() {
    this.onSelect(null);
  }
}
