import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '@app/core/services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
import { CustomerRequest } from './model/customerRequest';
import { Comment } from './model/comment';
import { Admin } from './model/admin';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../commons/page';
import { AdministratorService } from '../administrator/administrator.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerRequestService extends BaseService {
  private requestCustomerCounterSource = new BehaviorSubject<number>(0);

  // Observable to give the number of open ticket
  requestCustomerCounter$: Observable<number> = this.requestCustomerCounterSource.asObservable();
  isAuthorizedCustomerRequestRole = ['ROOT_ADMIN', 'SUPER_ADMIN', 'MYCOMPANY_ADMIN_WITH_TARIFF_MANAGEMENT',
    'MYCOMPANY_ADMIN_WITHOUT_TARIFF_MANAGEMENT', 'SAV'].includes(this.administratorService.loggedAgent$.getValue().role.name);

  constructor(
    private httpClient: HttpClient,
    protected toastr: ToastrService,
    protected translate: TranslateService,
    protected administratorService: AdministratorService
  ) {
    super(toastr, translate);
  }


  findCustomerRequests(
    filter,
    sortOrder = 'ASC',
    sortElement = 'id',
    pageNumber = 0,
    pageSize = 10
  ): Observable<Page<CustomerRequest>> {

    if (sortElement == 'date') {
      sortElement = 'creationDate';
    }

    let params = new HttpParams()
      .set('sort', sortElement + ',' + sortOrder)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString())
      .set('since', '')
      .set('until', '');

    if (filter.customerRequestId != null && filter.customerRequestId !== '') {
      params = params.set('customerRequestId', filter.customerRequestId);
    }

    if (filter.adminReference != null && filter.adminReference !== '') {
      params = params.set('adminReference', filter.adminReference);
    }
    if (filter.customerLastName != null && filter.customerLastName !== '') {
      params = params.set('customerLastName', filter.customerLastName);
    }

    if (filter.customerFirstName != null && filter.customerFirstName !== '') {
      params = params.set('customerFirstName', filter.customerFirstName);
    }
    if (filter.fuzzyCustomerFirstName != null && filter.fuzzyCustomerFirstName !== '') {
      params = params.set('fuzzyCustomerFirstName', filter.fuzzyCustomerFirstName);
    }

    if (filter.fuzzyCustomerLastName != null && filter.fuzzyCustomerLastName !== '') {
      params = params.set('fuzzyCustomerLastName', filter.fuzzyCustomerLastName);
    }

    if (filter.fuzzyAdminFirstName != null && filter.fuzzyAdminFirstName !== '') {
      params = params.set('fuzzyAdminFirstName', filter.fuzzyAdminFirstName);
    }

    if (filter.fuzzyAdminLastName != null && filter.fuzzyAdminLastName !== '') {
      params = params.set('fuzzyAdminLastName', filter.fuzzyAdminLastName);
    }

    if (filter.status != null && filter.status !== '') {
      params = params.set('status', filter.status);
    }

    return this.httpClient.get<Page<CustomerRequest>>(environment.admin.api.mobilityEngine + '/v1/customerrequests/_internal', { params });
  }

  findCustomerRequest(id: number) {
    return this.httpClient
      .get<CustomerRequest>(environment.admin.api.mobilityEngine + '/v1/customerrequests/' + id + '/_internal', {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });

  }

  findCustomerRequestsByCustomerReference(
    customerReference,
    sortOrder = 'ASC',
    sortElement = 'id',
    pageNumber = 0,
    pageSize = 10
  ): Observable<Page<CustomerRequest>> {
    const params = new HttpParams()
      .set('customerReference', customerReference)
      .set('sort', sortElement + ',' + sortOrder)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString())
      .set('since', '')
      .set('until', '');

    return this.httpClient
      .get<Page<CustomerRequest>>(environment.admin.api.mobilityEngine + '/v1/customerrequests/_internal', {
        params,
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });

  }

  createComment(id: number, comment: Comment): Observable<Comment> {
    return this.httpClient
      .post<CustomerRequest>(
        environment.admin.api.mobilityEngine + '/v1/customerrequests/' + id + '/comments',
        comment, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      }
      );
  }

  deleteAdmin(id: number) {
    return this.httpClient
      .delete<CustomerRequest>(environment.admin.api.mobilityEngine + '/v1/customerrequests/' + id + '/admin', {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      }).pipe(
        tap(() => this.refreshRequestCustomerCounter())
      );
  }

  assignAdmin(id: number, admin: Admin) {
    return this.httpClient
      .put<CustomerRequest>(
        environment.admin.api.mobilityEngine + '/v1/customerrequests/' + id + '/admin',
        admin, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      }
      ).pipe(
        tap(() => this.refreshRequestCustomerCounter())
      );
  }

  close(id: number, comment: Comment) {
    return this.httpClient
      .put<CustomerRequest>(
        environment.admin.api.mobilityEngine + '/v1/customerrequests/' + id + '/close',
        comment, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      }
      ).pipe(
        tap(() => this.refreshRequestCustomerCounter())
      );
  }

  /**
   * To update the observable requestCustomerCounter$
   */
  refreshRequestCustomerCounter() {
    // Avoid calling the counter function for unauthorized profiles
    if (this.isAuthorizedCustomerRequestRole) {
      this.httpClient
        .get<Number>(environment.admin.api.mobilityEngine + '/v1/customerrequests/_count?status=OPEN', {
          headers: new HttpHeaders({
            'x-apikey': environment.admin.api.apikey
          })
        })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(nbCustomerRequest => this.requestCustomerCounterSource.next(nbCustomerRequest.valueOf()),
          err => this.requestCustomerCounterSource.next(0)
        );
    }
  }

}
