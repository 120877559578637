import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * Form step component.
 *
 * @see StepsComponent
 */
@Component({
  selector: 'li[ap-step]',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent implements OnInit {
  @Input() rel: Number;
  @Input() value: String;
  @Input() title: String;
  @Input() subtitle: String;
  @Input() activeStep: Number;

  get isdone(): Number { return this.activeStep >= this.rel ? 1 : 0 ; }

  get stepValue(): String { return !!this.value ? this.value : this.rel.toString(); }

  constructor() {}

  ngOnInit() {}

}
