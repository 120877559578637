import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ap-media-event-icon',
  templateUrl: './media-event-icon.component.html',
  styleUrls: ['./media-event-icon.component.css']
})
export class MediaEventIconComponent implements OnInit {
  @Input() iconCssClass: string;

  constructor() { }

  ngOnInit() {
  }

}
