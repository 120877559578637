import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AbstractOnDestroyComponent } from '../abstract-on-destroy-component/abstract-on-destroy-component';
import { takeUntil } from 'rxjs/operators';

export abstract class BaseService extends AbstractOnDestroyComponent {
  constructor(
    protected toastr: ToastrService,
    protected translate: TranslateService
  ) {
    super();
  }

  protected handleError(error: Response | any, errMsg?: string) {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        this.translate.get('ERROR.NO_CONNEXION')
        .pipe( takeUntil(this.unsubscribe) )
        .subscribe((res: string) => {
          errMsg = res;
        });
      } else {
         // Handle 500, 501, 502... errors
        if (error.status.toString().startsWith('5')) {
          this.translate.get('ERROR.INTERNAL_SERVER')
          .pipe( takeUntil(this.unsubscribe) )
          .subscribe((res: string) => {
            errMsg = res;
          });
        } else {
          // Handle Http Error (error.status === 403, 404...)
          errMsg = errMsg ? `Erreur ${error.status} : ` + errMsg : `Erreur ${error.status} : ${error.error.message}.`;
        }
      }
    } else {
      errMsg = errMsg ? 'Une erreur inconnue s\'est produite : ' + errMsg : error.message ? error.message : error.toString();
    }

    this.toastr.error(errMsg);


    return throwError(error);
  }
}
