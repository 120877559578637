import { Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash.clonedeep';
import { FilterData } from '../../filter/filterData';

@Component({
  selector: '[ap-table-filter]',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.css']
})
export class TableFilterComponent implements OnInit {

  @Input('ap-table-filter') name: string;

  @Input()
  get filterData(): FilterData { return this._filterData; }
  set filterData(filterData: FilterData) {
    this._filterData = filterData;
    this.createFilter();
  }
  _filterData: FilterData;

  filter;

  constructor() { }

  private createFilter() {
    const filter = this._filterData.filters
      .find(f => f.filterTemplate.name === this.name && f.enabled);

    if (filter) {
      this.filter = cloneDeep(filter);
    } else {
      const template = this._filterData.templates.find(t => t.name === this.name);
      this.filter = template.buildDefaultFilter();
    }
  }

  ngOnInit() {
    this._filterData.onChange$.subscribe(e => {
      this.createFilter();
    });
  }

  onEnter() {
    if (this.filter.value) {
      this._filterData.addOrUpdateFilter(this.filter);
    } else {
      const index = this._filterData.filters
        .findIndex(f => f.filterTemplate.name === this.name && f.enabled);
      if (index > -1) {
        this._filterData.removeFilter(index);
      }
    }
  }

}
