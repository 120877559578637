import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ap-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.css']
})
export class ChecklistItemComponent implements OnInit {

  @Input() checked: boolean;
  @Input() disabled: boolean;

  @Output() checkedChange = new EventEmitter();


  constructor() { }

  ngOnInit() {}

  selectItem() {
      this.checked = !this.checked;
      this.checkedChange.emit(this.checked);
  }

}
