import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExtraRouterService {

  private previousUrl: string;

  constructor(private router: Router) {

  }

  init() {
    this.router.events
    .pipe(filter((e: any) => e instanceof RoutesRecognized),
      pairwise(),
    ).subscribe((e: any) => {
      if ( !e[0]?.urlAfterRedirects.includes('/bulk-change') ) {
        this.previousUrl = e[0]?.urlAfterRedirects;
      }
    });
  }

  /**
   * Go to the previous page and keep the same state.
   */
  back() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl, { state: history?.state });
    }
  }

}
