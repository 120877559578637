import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterService } from '@app/core/services/filter/filter.service';
import { Filter } from '@app/core/services/filter/model/filter.model';
import { OverlayWrapper } from '@app/shared/components/overlay/overlay-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmProcessingModal, ConfirmProcessingModel } from '@app/shared/components/modals/confirm-processing/confirm-processing.component';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { CompanyPipe } from '@app/shared/pipes/company.pipe';

export class FilterLoadOverlayData {
  public name: string;
  public selectFilter = new EventEmitter<Filter>();

  constructor(name: string) {
    this.name = name;
  }
}

@Component({
  selector: 'ap-filter-load-overlay',
  templateUrl: './filter-load-overlay.component.html',
  styleUrls: ['./filter-load-overlay.component.css'],
  providers: [CompanyPipe]
})
export class FilterLoadOverlayComponent extends OverlayWrapper<FilterLoadOverlayData> implements OnInit {

  filters$: Observable<Filter[]>;

  companyControl = new UntypedFormControl('');
  company = '';

  companies = Object.keys(environment.admin.companies).map(v => ({ key: v, label: this.companyPipe.transform(v) }));
  requiredCompany: string;

  termControl = new UntypedFormControl();
  term = '';

  sharedControl = new UntypedFormControl(true);
  personalControl = new UntypedFormControl(true);
  shared: boolean[] = [true, false];

  constructor (
      private filterService: FilterService,
      private simpleModalService: SimpleModalService,
      private translateService: TranslateService,
      private toastr: ToastrService,
      private administratorService: AdministratorService,
      private companyPipe: CompanyPipe) {
    super();
  }

  ngOnInit() {
    this.requiredCompany = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;

    this.filters$ = this.filterService.getCachedFilters(this.data.name);

    this.termControl.valueChanges.pipe(
      // wait until the user stops typing for a second
      debounceTime(200)
    ).subscribe(term => { this.term = term; });

    this.companyControl.valueChanges.subscribe(company => {
      this.company = company;
    });

    this.sharedControl.valueChanges.subscribe(enabled => {
      if (enabled) {
        this.shared = this.shared.concat(true);
      } else {
        this.shared = this.shared.filter(s => !s);
        this.companyControl.setValue('');
      }
    });

    this.personalControl.valueChanges.subscribe(enabled => {
      if (enabled) {
        this.shared = this.shared.concat(false);
        this.companyControl.setValue('');
      } else {
        this.shared = this.shared.filter(s => s);
      }
    });

  }

  onSelect(filter: Filter) {
    this.data.selectFilter.emit(filter);
  }

  /**
   * To delete personal or shared filter with confirmation modal.
   *
   * @param filter Fitler
   */
  onDelete(filter: Filter) {
    const params = <ConfirmProcessingModel<boolean>>{
      process: this.filterService.deleteFilter(filter).pipe(map(() => true)),
      message: this.translateService.instant('FILTERS.DELETE.' + (filter.shared ? 'SHARED' : 'PERSONAL'))
    };

    this.simpleModalService.addModal(ConfirmProcessingModal, params).subscribe(result => {
      if (result) {
        const notifyMsg = this.translateService.instant('FILTERS.LOAD.DELETE_RECORD');
        this.toastr.success(notifyMsg);
        this.filters$ = this.filterService.getCachedFilters(this.data.name);
      }
    });
  }

}
