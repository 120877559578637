import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'bankSchema'})
export class BankSchemaPipe implements PipeTransform {

  // Partial matching of AID
  aidToSchema = new Map([
    ['A0000000031010', 'Visa'],
    ['A0000000032010', 'Visa Electron'],
    ['A0000000032020', 'Visa V Pay'],
    ['A0000000038010', 'Visa Plus'],
    ['A0000000041010', 'MasterCard'],
    ['A0000000049999', 'MasterCard'],
    ['A0000000043060', 'MasterCard Maestro'],
    ['A0000000046000', 'MasterCard Cirrus'],
    ['A0000000050001', 'MasterCard Maestro'],
    ['A0000000421010', 'CB'],
    ['A0000000422010', 'CB'],
    ['A0000000423010', 'CB'],
    ['A0000000424010', 'CB'],
    ['A0000000425010', 'CB'],
    ['A00000002501', 'Amex'],
    ['A00000002504', 'Amex'],
  ]);

  /**
   * map the bank schema information from aid
   * @param aid Application Identifier
   * @return the bank schema information corresponding to the aid, otherwise return null
   */
  transform(aid: string): string {
    return aid && this.aidToSchema.has(aid) ? this.aidToSchema.get(aid.substring(0, 14)) : null;
  }
}

