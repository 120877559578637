import { createAction, props } from "@ngrx/store";
import { BlockingInfo, FilterInfo } from "./filter-state";

/* POI */
export const setPoiRequest = createAction("[Filter - Device] Set device poi selected", props<{poi: string}>());
export const clearPoiRequest = createAction("[Filter - Device] Clear device poi");

/* LastBlockingRequestDate */
export const setLastBlockingRequestDateRequest = createAction("[Filter - Device] Set lastBlockingRequestDate using date", props<{ lastBlockingRequestDate: Date }>());
export const clearLastBlockingRequestDateRequest = createAction("[Filter - Device] Clear lastBlockingRequestDate");

/* BlockingStateDate*/
export const setBlockingStateDateRequest = createAction("[Filter - Device] Set blockingStateDate", props<{ blockingStateDate: Date }>());
export const clearBlockingStateDateRequest = createAction("[Filter - Device] Clear blockingStateDate");

/* BlockingInfo */
export const setBlockingInfoRequest = createAction("[Filter - Device] Set blockingInfo", props<{ blockingInfo: BlockingInfo }>());
export const clearBlockingInfoRequest = createAction("[Filter - Device] Clear blockingInfo");

/* Filter */
export const setFilterInfo = createAction("[Filter - Global] Set filterInfo", props<{filterInfo: FilterInfo}>());

/* FromPage */
export const setFromPage = createAction("[Filter - Global] Set fromPage", props<{ fromPage: string}>());
export const clearFromPage = createAction("[Filter - Global] Clear fromPage");
