import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { KibanaService } from '@app/core/services/kibana/kibana.service';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';


@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent implements OnInit {

  kibanaUrl: string = environment.admin.api.kibanaUrl;
  kibanaSrc: string;

  myCompanyReferences: string[] = Object.keys(environment.admin.companies);
  myCurrentCompanyReference: string;
  companyRoles: string[] = [];
  isUserWithPartitionedRole = false;

  ranges = [];
  currentRange;

  since = 'Since';

  durations = [ 'PT15M', 'PT30M', 'PT1H', 'PT6H', 'PT24H', 'P2D', 'P4D', 'P1W', 'P2W', 'P1M', 'P2M', 'P3M', 'P1Y', 'P2Y' ];

  constructor(private translate: TranslateService,
    private administratorService: AdministratorService,
    private kibanaService: KibanaService) {
  }

  ngOnInit() {
    // verify that the attribute 'companyRef' is defined in keycloak
    this.myCurrentCompanyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    if (this.myCurrentCompanyReference) {
      // the user has partitioned role. So his statistics are managed by kibana gatekeeper.
      this.isUserWithPartitionedRole = true;
    }

    this.translate.get('STATISTIC.SINCE').subscribe(trad => {
      this.since = trad;
      this.generate();
      this.currentRange = this.ranges[0];
      this.applyFilters();
    });

    this.translate.onLangChange.subscribe((e) => {
      this.translate.get('STATISTIC.SINCE').subscribe(trad => {
        this.since = trad;
        this.generate();
        this.currentRange = this.ranges[0];
        this.applyFilters();
      });
    });

  }

  applyFilters() {
    this.kibanaSrc = null;
    setTimeout(() => {
      this.urlBuilder();
    }, 100);
  }

  displayDay(day) {
    return day.locale(this.translate.currentLang).calendar(null).split(' ')[0];
  }

  onChange(e) {
    this.currentRange = e;
  }

  onChangeFrom(e) {
    const customRange = {
        label: 'PERSONALIZED',
        from: moment(e),
        to: this.currentRange.to
    };
    this.generate();
    this.ranges.push(customRange);
    this.currentRange = customRange;
  }

  onResetDate() {
    this.generate();
    this.currentRange = this.ranges[0];
  }

  onChangeTo(e) {
    const customRange = {
      label: 'PERSONALIZED',
      from: this.currentRange.from,
      to: moment(e)
    };
    this.generate();
    this.ranges.push(customRange);
    this.currentRange = customRange;
  }

  private generate() {
    this.ranges = [];
    this.ranges.push({
      'label': this.displayDay(moment()),
      'from': moment().startOf('day'),
      'to': moment()
    });
    this.ranges.push({
      'label': this.displayDay(moment().subtract(1, 'days').endOf('day')),
      'from': moment().subtract(1, 'days').startOf('day'),
      'to': moment().subtract(1, 'days').endOf('day')
    });
    this.durations.forEach(duration => {
      this.ranges.push({
        'label': this.displayDuration(duration),
        'from': (moment().subtract(duration).isSame(moment(), 'day') ? (moment().subtract(duration)) : (moment().subtract(duration).startOf('day'))),
        'to': moment()
      });
    });
  }

  private urlBuilder () {
    this.kibanaSrc = this.kibanaService.createStatisticUrl(this.currentRange, this.myCurrentCompanyReference);
  }

  private displayDuration(duration) {
    // @ts-ignore
    const data = moment.localeData(this.translate.currentLang)._relativeTime;
    data.future = this.since + ' %s';

    moment.defineLocale('fr-foo', {
      parentLocale: this.translate.currentLang,
      relativeTime : data
    });
    return moment.duration(duration).locale('fr-foo').humanize(true);
  }

}
