import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ap-export-data',
  templateUrl: './export-data.component.html'
})
export class ExportDataComponent implements OnInit {

  @Input() exportType: 'SETTLEMENT' | 'DEVICES' | 'ALARMS';

  constructor() { }

  ngOnInit(): void {
  }

}
