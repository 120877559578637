import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash.clonedeep';
import { MultipleSelectionFilter } from '../../filter-item/multiple-selection-filter-item/multiple-selection-filter.model';
import { FilterOperatorEnum } from '@app/core/services/commons/enum/filter-operator.enum';

@Component({
  selector: 'ap-multiple-selection-filter-template',
  templateUrl: './multiple-selection-filter-template.component.html',
  styleUrls: ['./multiple-selection-filter-template.component.css']
})
export class MultipleSelectionFilterTemplateComponent implements OnInit {

  @Input()
  get filter(): MultipleSelectionFilter { return this._filter; }
  set filter(filter: MultipleSelectionFilter) {
    this._filter = filter;
    this.filterForm.patchValue({operator: filter.operator});
  }
  _filter: MultipleSelectionFilter;

  @Output() addFilter = new EventEmitter<MultipleSelectionFilter>();

  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.formBuilder.group({
      value: new UntypedFormControl('', Validators.required),
      operator: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = this.filterForm.value.value;
      this._filter.operator = this.filterForm.value.operator;
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

  shouldShowSelectField(): boolean {
    if (this.filterForm.value.operator === FilterOperatorEnum.EMPTY) {
      this.filterForm.patchValue({value: ' '});
      return false;
    }
    return true;
  }

}
