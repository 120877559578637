<div class="row" *ngIf="pagination">
  <div class="col-sm-6">
    <div class="dataTables_info pagination-information">
      {{ 'PAGINATION.DISPLAY' | translate }}
      <select name="datatable-checkbox_length" aria-controls="datatable-checkbox" id="datatable-checkbox" [ngModel]="_pagination.elements" (ngModelChange)="onSizePage($event)" attr.aria-label="{{ 'PAGINATION.NB_ELEMENTS' | translate}}">
        <option id="datatable-checkbox-10" [value]="10">10</option>
        <option id="datatable-checkbox-25" [value]="25">25</option>
        <option id="datatable-checkbox-50" [value]="50">50</option>
        <option id="datatable-checkbox-100" [value]="100">100</option>
      </select>
      {{ 'PAGINATION.ELEMENTS' | translate }} {{ 'PAGINATION.FROM' | translate }}

      {{_pagination.page * _pagination.elements}} {{ 'PAGINATION.TO' | translate }} {{ _pagination.totalElements > ((_pagination.page + 1) * _pagination.elements) ? ((_pagination.page + 1) * _pagination.elements) : _pagination.totalElements }}

      {{ 'PAGINATION.OF' | translate }} {{_pagination.totalElements}}
    </div>
  </div>
  <div class="col-sm-6">
    <div class="dataTables_paginate paging_simple_numbers">
      <ul class="pagination">
        <li [ngClass]="{'disabled': _pagination.page == 0}" class="paginate_button previous" id="datatable-checkbox_previous">
          <a href="javascript:;" (click)="onPage(0)" >{{ 'PAGINATION.FIRST' | translate }}</a>
        </li>
        <li *ngFor="let i of pages" [ngClass]="{'active': _pagination.page == i}" class="paginate_button ">
          <a href="javascript:;" (click)="onPage(i)">{{i + 1}}</a>
        </li>
        <li [ngClass]="{'disabled': _pagination.page == _pagination.totalPages - 1}" class="paginate_button next" id="datatable-checkbox_next">
          <a href="javascript:;" (click)="onPage(_pagination.totalPages - 1)">{{ 'PAGINATION.LAST' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
