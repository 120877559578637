import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs)

/**
 * Return the date into the local format
 */
@Pipe({
  name: 'localFilter', pure: false
})
export class LocalFilterPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(items: any[], element?: string): any[] {
    if (items && element) {
      items.forEach(item => {
        if (item.label === element) {
          this.translate.get('STATISTIC.' + element).subscribe(msg => {
            item.label = msg;
          });
        }
      });
      return items;
    } else {
      return items;
    }
  }

}
