import { Component } from '@angular/core';

@Component({
  selector: 'ul[ap-side-submenu-items]',
  template: '<ng-content select="li"></ng-content>',
  styles: [
    '.active :host { display: block !important; }'
  ],
  host: { 'class' : 'nav child_menu_left' }
})
export class SideSubMenuItemsComponent {
}
