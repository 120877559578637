import { Pipe, PipeTransform } from '@angular/core';
import { ApplicableProductUI } from '@app/core/services/tariffication/models/applicable-product.model';

@Pipe({
  name: 'filterApplicableProduct',
  pure: true
})
export class FilterApplicableProductPipe implements PipeTransform {

  /**
   * Filter a list
   *
   * @param items - The list to filter
   * @param include - true if only this parameter value should be true, false otherwise
   */
  transform(items: ApplicableProductUI[], include: boolean = true): ApplicableProductUI[] {
    return items.filter(item => include ? item?.exception : !item?.exception);
  }
}
