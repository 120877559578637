import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as rison from 'rison-node';

@Injectable({
  providedIn: 'root'
})
export class KibanaService {

  kibanaUrl: string = environment.admin.api.kibanaUrl;

  kibanaSupervisionDashboardId: string = environment.admin.kibana.supervision.dashboardId;
  kibanaStatisticDashboardId: string = environment.admin.kibana.statistic.dashboardId;

  /**
   * Create kibana statistics query
   *
   * @param currentRange search statistics with the period provided by the user
   * @param companyReference search statistics with partitioned role's user. Otherwise the user is allowed to access to all statistics with filters.
   * 
   * @return url href
   */
  createStatisticUrl (currentRange: any, companyReference: string): string {
    // Load the kibana source
    const url = new URL(this.kibanaStatisticDashboardId, this.kibanaUrl.replace('#', 'ANCHOR_TAG'));

    // Add the embed mode
    url.searchParams.append('embed', 'true');

    // Build the date time query
    const dateTimeQuery = {
      'filters': [],
      'refreshInterval': {
        'pause': true,
        'value': 0
      },
      'time': {
        'from': new Date(currentRange.from).toISOString(),
        'mode': 'absolute',
        'to': new Date(currentRange.to).toISOString()
      }
    };

    const sourceQuery = {
      'columns': ['_source'],
      'filters': [],
      'interval': 'auto',
      'query': {
        'language': 'kuery',
        'query': ''
      },
     'sort': []
    };

    if (companyReference) {
        // Build the select userAgent Company query
      const userAgentQuery = this.createQueryItem(companyReference);
      // Fill non generic fields
      userAgentQuery.meta.params = {
        'query': companyReference
      };
      userAgentQuery.query = {
        'match_phrase': {
          'companyReference': companyReference
        }
      };
      sourceQuery.filters.push(userAgentQuery);
    }

     // Apply the datetime query to the url
     url.searchParams.append('_g', rison.encode(dateTimeQuery));
     url.searchParams.append('_a', rison.encode(sourceQuery));
     url.href = url.href.replace('ANCHOR_TAG', '#');

     return url.href;
  }

  /**
   * Create kibana devices statistics (supervision) query
   *
   * @param from search statistics with the beginning of the period provided by the user
   * @param to search statistics with the end of the period provided by the user
   * @param companyReference search statistics with partitioned role's user. Otherwise the user is allowed to access to all statistics with filters.
   * @param selectDevices search statistics for selected devices
   *
   * @return url href
   */
  createSupervisionUrl (from: Date, to: Date, functions: string[], companyReference: string, selectDevices: Array<string>): string {
    // Load the kibana source
    const url = new URL(this.kibanaSupervisionDashboardId, this.kibanaUrl.replace('#', 'ANCHOR_TAG'));

    // Add the url param
    url.searchParams.append('embed', 'true');

    const dateTimeQuery = {
      'filters': [],
      'refreshInterval': {
        'pause': true,
        'value': 0
      },
      'time': {
        'from': from,
        'mode': 'absolute',
        'to': to
      }
    };

    url.searchParams.append('_g', rison.encode(dateTimeQuery));
    // Process to the filter creation

    // CReate the global query
    const query = {
      'description': '',
      'filters': [],
    };

  if (companyReference) {
        // Build the select userAgent Company query
      const userAgentQuery = this.createQueryItem(companyReference);
      // Fill non generic fields
      userAgentQuery.meta.params = {
        'query': companyReference
      };
      userAgentQuery.query = {
        'match_phrase': {
          'companyReference': companyReference
        }
      };
      query.filters.push(userAgentQuery);
    }

    if (selectDevices.length > 0) {
      query.filters.push(this.buildContainsFilter('poi.keyword', selectDevices));
    }

    query.filters.push(this.buildContainsFilter('function', functions));

    url.searchParams.append('_a', rison.encode(query));
    url.href = url.href.replace('ANCHOR_TAG', '#');

    return url.href;
  }

  /** Method to create a query item  */
  private createQueryItem(fieldName: string): any {
    return {
        '$state': {
          'store': 'appState'
        },
        'meta': {
          'alias': null,
          'disabled': false,
          'key': fieldName,
          'negate': false,
          'params': [],
          'type': 'phrases',
          'value': ''
        },
        'query': {}
      };
  }

  private buildContainsFilter(fieldName: string, values: string[]) {
    return {
      '$state': {
        'store': 'appState'
      },
      'meta': {
        'alias': null,
        'disabled': false,
        'key': fieldName,
        'negate': false,
        'params': values,
        'type': 'phrases',
        'value': values.join(', ')
      },
      'query': {
        'bool': {
          'should' : values.map(value => ({ 'match_phrase' : { [fieldName] : value } })),
          'minimum_should_match': 1
        }
      }
    };
  }

}
