import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount, tap } from 'rxjs/operators';
import { Page } from '../commons/page';
import { Filter, FilterItem } from './model/filter.model';
import { AdministratorService } from '../administrator/administrator.service';
import { environment } from '@env/environment';
import { PermissionEnum } from '../administrator/models/enum/permission';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private permissions = this.administratorService.loggedAgent$.getValue().role.permissions;
  private baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private administratorService: AdministratorService
  ) {
    this.baseUrl = environment.admin.api.mobilityEngine + '/api/user-manager';
  }

  private filtersCache: { [key: string]: Observable<Filter[]> } = {};

  public getCachedFilters(filterPage: string): Observable<Filter[]> {
    this.filtersCache[filterPage] = this.filtersCache[filterPage] || this.fetchFilters(filterPage);
    return this.filtersCache[filterPage];
  }

  public resetFilterCache() {
    this.filtersCache = {};
  }

  private fetchFilters(filterPage: string) {
    return this.getFilters(filterPage).pipe(
      catchError(_e => of(null)),
      publishReplay(1),
      refCount()
    );
  }

  public getFilters(page: string): Observable<Filter[]> {
    const adminId = this.administratorService.loggedAgent$.getValue().userCredentials.userKeycloakId;
    const records = JSON.parse(localStorage.getItem(adminId + '@' + page + '-records') || '[]');

    const filters = records.map(record => {
      const recordItem = localStorage.getItem(adminId + '@' + page + '-record@' + record.name);
      return Filter.parse(page, record.name, recordItem, false);
    });

    if (this.permissions.includes(PermissionEnum.MANAGE_SHARED_FILTERS) || this.permissions.includes(PermissionEnum.MANAGE_MYCOMPANY_SHARED_FILTERS)) {
      return of(filters).pipe(mergeMap((filters: Filter[]) => {
        return this.getRemoteFilters(page).pipe(map((remoteFilters: Filter[]) => {
          return filters.concat(remoteFilters);
        }));
      }));
    } else {
      return of(filters)
    }
  }

  public getLocalFilters(page: string): Filter[] {
    const adminId = this.administratorService.loggedAgent$.getValue().userCredentials.userKeycloakId;
    const companyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;

    let records: Filter[] = [];
    if (companyReference) {
      records = JSON.parse(localStorage.getItem(adminId + '@' + page + '-records') || '[]');
      return records.map(record => {
        const recordItem = localStorage.getItem(adminId + '@' + page + '-record@' + record.name);
        return Filter.parse(page, record.name, recordItem, false);
      });

    } else {
      const keys = Object.keys(localStorage).map(k => {
          if ( k.includes('@' + page + '-record@') ) {
            return k;
          }
        });
      keys.forEach(k => {
        if (k) {
          records.push(Filter.parse(page, k.substring(k.lastIndexOf('@') + 1), localStorage.getItem(k), false));
        }
      });
      return records;
    }

  }

  public getRemoteFilters(filterPage: string): Observable<Filter[]> {
    const companyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    let params = new HttpParams()
        .set('page', '0')
        .set('size', '5000')
        .set('visibleAll', 'true')
        .set('sort', 'normalizedName')
        .set('filterPage', filterPage);

    if (companyReference) {
      params = params.set('companyReference', companyReference);
    } else {
      Object.keys(environment.admin.companies).forEach(company => {
        params = params.append('companyReference', company);
      });
    }

    return this.httpClient.get<Page<Filter>>(`${this.baseUrl}/v1/filters`, { params }).pipe(
      catchError(_e => {
        return of(<Page<Filter>>{
          content: []
        });
      }),
      map(filters => {
        return filters.content.map(filter => {
          filter = Object.assign(new Filter(), filter);

          const items = filter.items.map(i => {
            return Object.assign(new FilterItem, i);
          });
          filter.items = items;
          filter.shared = true;
          return filter;
        });
      }));
  }

  public deleteLocalFilter(page: string, name: string) {
    const adminId = this.administratorService.loggedAgent$.getValue().userCredentials.userKeycloakId;
    let records = JSON.parse(localStorage.getItem(adminId + '@' + page + '-records') || '[]');

    localStorage.removeItem(adminId + '@' + page + '-record@' + name);

    records = records.filter(r => r.name !== name);
    localStorage.setItem(adminId + '@' + page + '-records', JSON.stringify(records));

    this.resetFilterCache();
  }

  public deleteRemoteFilter(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/v1/filters/${id}`).pipe(tap(() => this.resetFilterCache()));
  }

  public deleteFilter(filter: Filter): Observable<void> {
    if (filter.shared) {
      return this.deleteRemoteFilter(filter.id);
    } else {
      return of(this.deleteLocalFilter(filter.page, filter.name));
    }
  }

  /**
   * Save the filter into the right place depending on shared variable
   * @param filter the filter to save
   * @returns void
   */
   public saveFilter(filter: Filter): Observable<void> {
    if (!filter) {
      return throwError(new HttpErrorResponse({ error: 'Required parameter filter was null or undefined when calling saveLocalFilter.', status: 400 }));
    }
    if ( filter.shared && !this.permissions.includes(PermissionEnum.MANAGE_SHARED_FILTERS) && !this.permissions.includes(PermissionEnum.MANAGE_MYCOMPANY_SHARED_FILTERS) ) {
      return throwError(new HttpErrorResponse({ error: "Creation is not possible because you don't have the permission", status: 403 }));
    }
    if (filter.shared) {
      return this.saveRemoteFilter(filter);
    } else {
      return this.saveLocalFilter(filter);
    }
   }

  /**
   * Save the filter into back-end
   *
   * @param filter the shared filter to save
   * @returns void
   */
  public saveRemoteFilter(filter: Filter): Observable<void> {
    // toDO: dette technique => type de filtre à revoir
    filter.items.forEach(item => item.type = 'DEFAULT');
    this.resetFilterCache();
    return this.httpClient.post<void>(`${this.baseUrl}/v1/filters`, filter);
  }

  /**
   * Save the filter into LocalStorage
   *
   * @param filter the filter to save
   * @returns void
   */
  public saveLocalFilter(filter: Filter): Observable<void> {
    const adminId = this.administratorService.loggedAgent$.getValue().userCredentials.userKeycloakId;
    const records = JSON.parse(localStorage.getItem(adminId + '@' + filter.page + '-records') || '[]');

    if ( records.some(record => record.name.trim().localeCompare(filter.name.trim(), 'fr', { sensitivity: 'base', ignorePunctuation: true }) === 0) ) {
      return throwError(new HttpErrorResponse({ error: 'Creation is not possible because a personal filter with this name already exists.', status: 409 }));
    }

    records.push({name: filter.name, favorite: false});
    localStorage.setItem(adminId + '@' + filter.page + '-records', JSON.stringify(records));

    const recordItem = adminId + '@' + filter.page + '-record@' + filter.name;
    localStorage.setItem(recordItem, filter.encode());

    return of(this.resetFilterCache());
  }

}
