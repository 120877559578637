<ng-progress aria-label="progress bar"></ng-progress>
<div>
  <div class="container body">
    <div class="main_container">
      <div class="col-md-3 left_col menu_fixed" *ngIf="!maintenanceInProgress">
        <app-menu [isToggle]='isToggle'></app-menu>
      </div>
      <!-- top navigation -->
      <div class="top_nav top_nav_fixed" *ngIf="!maintenanceInProgress">
        <ap-maintenance-banner *ngIf="maintenancePlanned"></ap-maintenance-banner>
        <div class="nav_menu">
          <nav aria-label="nav menu">
            <div class="nav toggle">
              <a (click)="toggleNavSize()" id="menu_toggle"><i class="fa fa-bars"></i></a>
            </div>

            <ul class="nav navbar-nav navbar-right">
              <li class="">
                <a href="javascript:void(0)" (click)="logout()" class="user-profile" id="logoutButton">
                  <i class="fa fa-sign-out"></i> {{'MENU.LOGOUT' | translate}}
                </a>
              </li>
              <li class="">
                <a *ngIf="lang" href="javascript:;" class="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                  <img src="assets/images/flags/flag-{{lang}}.png" alt="">{{'MENU.LANG.' + lang | translate}}
                  <span class=" fa fa-angle-down"></span>
                </a>
                <ul class="dropdown-menu dropdown-usermenu pull-right">
                  <li *ngFor="let language of availableLanguages"><a href="javascript:;" (click)="changeLanguage(language)" class="user-profile">
                    <img src="assets/images/flags/flag-{{language}}.png" alt="">{{'MENU.LANG.' + language | translate}}</a></li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /top navigation -->

      <!-- page content -->
      <div class="right_col" #content role="main" [ngClass]="{ 'maintenance-banner-position': maintenancePlanned }">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
      <!-- /page content -->

      <!-- footer content -->
      <footer aria-label="footer">
        <div class="pull-right">
          <div id="link_footer" *ngIf="!maintenanceInProgress">
            <a *ngIf="displayCgu" [routerLink]="'/extra/cgu'">{{ 'FOOTER.CGU' | translate }} </a>
            <a *ngIf="displayFaq" [routerLink]="'/extra/faq'">{{ 'FOOTER.FAQ' | translate }} </a>
            <a *ngIf="displayLegalNotice" [routerLink]="'/extra/legal-notice'">{{ 'FOOTER.LEGAL_NOTICE' | translate }} </a>
          </div>
          {{ "VERSION" | translate }} {{appVersion}}
        </div>
        <div class="clearfix" role="separator" aria-label="footer separator"></div>
      </footer>
      <!-- /footer content -->
    </div>
  </div>
</div>
<div toastContainer></div>
<div class="modal-container"></div>
