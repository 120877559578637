import { Component, OnInit, Input } from '@angular/core';

enum Status {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  REFUND = 'REFUND',
  DEFAULT = 'DEFAULT'
}

@Component({
  selector: 'ap-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  @Input() status: Status = Status.DEFAULT;

  constructor() { }

  ngOnInit() {
  }

}
