import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor() {}

  // pas utilisé ?
  downloadFileStream(blob: any, fileName: String, fileType: string) {
    const fullName = fileName + '.' + fileType;
    const url = window.URL.createObjectURL(blob);
    // For InternetExplorer
    /*if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fullName);
    } else {*/
      const link = document.createElement('a');
      link.download = fullName;
      link.style.display = 'none';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    //}
    window.URL.revokeObjectURL(url);
  }

  getVariable(variableName: string, type?: TypeStorage) {
    const typeStorage = type === 'session' ? sessionStorage : localStorage;
    return typeStorage.getItem(variableName);
  }

  persistInBrowser(key: string, value: string, type?: TypeStorage) {
    const typeStorage = type === 'session' ? sessionStorage : localStorage;
    typeStorage.setItem(key, value);
  }

}

declare type TypeStorage = 'session' | 'local';
