<div class="row">
  <!-- Autocomplete filter -->
  <div class="input-group col-md-12">
    <span class="input-group-addon"><i class="fa fa-search" aria-hidden="true"></i></span>
    <input ap-focus type="text" class="form-control" [formControl]="termControl" attr.aria-label="{{ 'SHARED.COMPONENT.OVERLAY.SEARCH' | translate }}" placeholder="{{ 'SHARED.COMPONENT.OVERLAY.SEARCH' | translate }}">
  </div>

  <!-- Filter type -->
  <div class="col-md-4">
    <h5 class="font-var">{{ 'SHARED.COMPONENT.OVERLAY.FILTER_TYPE' | translate }}</h5>

    <div class="checkbox-container">
      <div><ap-checkbox-ng [formControl]="personalControl"></ap-checkbox-ng><label>{{ 'SHARED.COMPONENT.OVERLAY.PERSONAL_FILTERS' | translate }}</label></div>
      <div><ap-checkbox-ng [formControl]="sharedControl"></ap-checkbox-ng><label>{{ 'SHARED.COMPONENT.OVERLAY.SHARED_FILTERS' | translate }}</label></div>
    </div>

    <br/>
    <!-- Filter operator -->
    <ng-container *ngIf="sharedControl.value">
      <h5 class="font-var">{{ 'SHARED.COMPONENT.OVERLAY.USABLE_BY' | translate }}</h5>
      <div class="radio">
        <label><input type="radio" [formControl]="companyControl" value=""> {{ 'FILTERS.COMPANY.ALL' | translate }}</label>
      </div>
      <div class="radio" *ngFor="let company of (companies | orderBy:'asc':'label')">
        <label ><input type="radio" [formControl]="companyControl" [value]="company.key">{{ company.label }}</label>
      </div>
    </ng-container>
  </div>

  <!-- Filter list -->
  <div class="col-md-8">
    <h5 class="font-var">{{ 'SHARED.COMPONENT.OVERLAY.FILTERS_AVAILABLE' | translate }}</h5>

    <ng-container *ngIf="filters$ | obsWithStatus | async as obsFilters">
      <!-- Loading -->
      <ap-jumbotron  *ngIf="obsFilters.loading" [icon]="'fa-spinner fa-spin'">{{ 'TABLE.LOADING' | translate }}</ap-jumbotron>

      <ng-container *ngIf="obsFilters.value && (obsFilters.value | filterByTerm:company:['companyReference']:true:false | filter:'shared':shared | filterByTerm:term:['name']) as filters">
        <!-- empty -->
        <ap-jumbotron *ngIf="!filters || filters.length == 0" [icon]="'fa-inbox'">{{ 'FILTERS.LOAD.EMPTY.TITLE' | translate }}</ap-jumbotron>

        <!-- List -->
        <ul class="to_do" ap-page-height>
          <li class="filter" *ngFor="let filter of filters">
            <div *ngIf="!requiredCompany || filter.companyReference || !filter.shared" class="btn-delete-filter" (click)="onDelete(filter)">
              <button class="btn btn-xs btn-danger"><i class="fa fa-trash"></i></button>
            </div>
            <div (click)="onSelect(filter)">
              <div class="shared-ico-container">
                <span class="fa-stack fa-lg">
                  <i class="fa fa-square fa-stack-2x"></i>
                  <i class="fa fa-stack-1x fa-inverse" [ngClass]="{'fa-users': filter?.shared, 'fa-user': !filter?.shared }"></i>
                </span>
              </div>
              <div class="text-filter-container">{{ filter.name }}<br /><span class="font-var">{{ filter.companyReference | company }}</span></div>
            </div>
          </li>
        </ul>
      </ng-container>
    </ng-container>

  </div>
</div>
