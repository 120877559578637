<ap-page>
  <ap-page-title>{{ 'STATISTIC.LIST.TITLE' | translate }}</ap-page-title>
  <ap-page-content>
    <ap-panel>
      <ap-panel-content>
        <form class="form-horizontal" style="padding-top: 10px; padding-bottom: 14px;" *ngIf="currentRange" aria-label="statistic form">
          <div class="row">
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label id="fromDateLabel">{{ 'STATISTIC.FROM' | translate }}</label>
              <input type="datetime-local" name="from" class="form-control" (change)="onChangeFrom($event.target.value)" [value]="currentRange.from.format('YYYY-MM-DDTHH:mm:ss')" step="1"/>
            </div>
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label id="toDateLabel">{{ 'STATISTIC.TO' | translate }}</label>
              <input type="datetime-local" name="to" class="form-control" (change)="onChangeTo($event.target.value)" [value]="currentRange.to.format('YYYY-MM-DDTHH:mm:ss')" step="1"/>
            </div>
            <div class="col-lg-2 col-sm-2 col-xs-2">
              <label for="selectRange" id="selectRangeLabel">{{ 'STATISTIC.SELECT' | translate }}</label>
              <select id="selectRange" name="selectRange" class="form-control" [ngModel]="currentRange" (ngModelChange)="onChange($event)">
              <option *ngFor="let range of ranges | localFilter : 'PERSONALIZED'" [ngValue]="range">{{ range.label }}</option>
              </select>
            </div>
            <!-- Company ref -->
            <div *ngIf="!isUserWithPartitionedRole" class="form-group col-lg-2 col-sm-2 col-xs-2 statistic-operator-select">
              <label for="company" id="companyLabel">{{ 'COMMON.LABELS.SERVICE_OPERATOR' | translate }}</label>
              <select id="company" class="form-control" name="selectMyCompanyReference" [(ngModel)]="myCurrentCompanyReference">
                <option [ngValue]="null"></option>
                <option *ngFor="let myCompanyReference of myCompanyReferences" [value]="myCompanyReference">{{ myCompanyReference | company }}</option>
              </select>
            </div>
            <div class="col-md-offset-0  col-lg-2 col-sm-2 col-xs-2">
              <span class="stats-label"> &nbsp; </span>
              <button type="button" id="apply" class="btn btn-success form-control" name="apply" (click)="applyFilters()">{{ 'ACTIONS.APPLY' | translate }}</button>
            </div>
            <div class="dashboardButton col-md-offset-0  col-lg-2 col-sm-2 col-xs-2 statistic-dashboardButton-pos">
              <span class="stats-label"> &nbsp; </span>
              <a id="dashboard" *apSecure="'MANAGE_STATISTICS'" class="btn btn-info form-control" target="_blank" title="Go to Kibana Administration Interface" href="{{kibanaSrc}}"><i class="fa fa-area-chart" aria-hidden="true"></i> Dashboard Kibana</a>
            </div>
          </div>
        </form>
        <div class="kibanaFrame" *ngIf="kibanaSrc">
          <iframe id="iframe" [src]="kibanaSrc | safe" class="kibanaIframe" title="Kibana Dashboard View"></iframe>
        </div>
      </ap-panel-content>
    </ap-panel>
  </ap-page-content>
</ap-page>
