import { Component, ViewChild, OnInit, Inject, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from './core/services/configuration/configuration.service';
import { MaintenanceService } from './core/services/maintenance/maintenance.service';
import { MaintenanceStateEnum } from './core/services/maintenance/maintenance-config.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title: string = environment.admin.application.title || 'Admin Portal';
  favicon: string = environment.admin.application.favicon || './assets/images/brand/favicon.png';

  isToggle: boolean;

  appVersion = environment.version;

  lang: string = ''; // Current language selected
  availableLanguages: string[];   // available languages in config (i18n)

  displayFaq: boolean = environment.admin.application.footer.faq.enabled;
  displayCgu: boolean = environment.admin.application.footer.cgu.enabled;
  displayLegalNotice: boolean = environment.admin.application.footer.legalNotice.enabled;

  // to hide header and footer in maintenance
  maintenanceInProgress: boolean = false;
  // to display banner
  maintenancePlanned: boolean = false;

  constructor(
    private keycloakService: KeycloakService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private configuration: ConfigurationService,
    private maintenanceService: MaintenanceService
  ) { }

  @ViewChild('content', { static: true }) contentDiv: ElementRef;

  ngOnInit() {
    this.initMaintenance();
    // Init languages (default + available)
    this.availableLanguages = this.translate.getLangs();
    this.lang = this.translate.currentLang;
    this.changeLanguage(this.lang);

    // Define title
    this.titleService.setTitle(this.title);
    // Define favicon
    this.document.getElementById('appFavicon').setAttribute('href', this.favicon);
    this.isToggle = this.document.getElementsByTagName('body').item(0).classList.contains('nav-md');
  }

  ngAfterViewInit(): void {
    this.adjustMainContainer();
    this.autoSizeMenu();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.adjustMainContainer();
    this.autoSizeMenu();
  }

  private adjustMainContainer() {
    const windowSize = window.innerHeight - 50;
    this.contentDiv.nativeElement.style.minHeight = windowSize + 'px';
  }

  private autoSizeMenu() {
    this.document.getElementsByTagName('body').item(0).classList.remove('nav-md', 'nav-sm');
    if (window.innerWidth < 1360) {
      this.document.getElementsByTagName('body').item(0).classList.add('nav-sm');
      this.isToggle = false;
    } else {
      this.document.getElementsByTagName('body').item(0).classList.add('nav-md');
      this.isToggle = true;
    }
  }

  toggleNavSize() {
    const body = this.document.getElementsByTagName('body').item(0);
    if (body.classList.contains('nav-md')) {
      body.classList.remove('nav-md');
      body.classList.add('nav-sm');
      this.isToggle = false;
    } else {
      body.classList.remove('nav-sm');
      body.classList.add('nav-md');
      this.isToggle = true;
    }
  }

  onActivate($event) {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - pos); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  logout() {
    const baseUrl = location.origin;
    this.keycloakService.logout(baseUrl).then(() => {});
    window.sessionStorage.clear();
  }

  changeLanguage(language: string) {
    this.configuration.reloadAndUseTranslation(language).then(() => {
      this.lang = language;
    });
  }

  private initMaintenance() {
    this.maintenanceService.nextMaintenanceStateSubject(MaintenanceStateEnum.INITIALIZE);
    this.maintenanceInProgress = this.maintenanceService.getMaintenanceActivatedSubject();
    this.maintenancePlanned = this.maintenanceService.getShowBannerSubject();
  }
}

declare interface CustomKeycloakProfile extends Keycloak.KeycloakProfile {
  attributes?: { locale: string[], companyRef: string[] };
}
