import { Component, ViewChild, OnInit, ElementRef, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { environment } from '@env/environment';
import { FinancialEngineService } from '@app/core/services/financial-engine/financial-engine.service';

export interface SipsModel {
  title?: string;
}

@Component({
  selector: 'app-sips-search',
  templateUrl: './sips-search.component.html',
  styleUrls: ['./sips-search.component.css']
})
export class SipsSearchComponent extends SimpleModalComponent<SipsModel, string> implements SipsModel, OnInit {

  @ViewChild('sipsForm', { static: true }) sipsForm: ElementRef;
  @ViewChild('data', { static: true }) data: ElementRef;
  @ViewChild('encode', { static: true }) encode: ElementRef;
  @ViewChild('version', { static: true }) version: ElementRef;
  @ViewChild('seal', { static: true }) seal: ElementRef;

  title: string;
  sipsRequest: any;
  message: any;


  constructor(private financialEngineService: FinancialEngineService, private translate: TranslateService) {
    super();
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: any) {
    if (typeof e.data === 'string') {
      this.result = e.data;
    }
    this.close();
  }

  ngOnInit() {
    // Display loading icon
    this.message = { icons: ['fa', 'fa-spinner', 'fa-spin'] };

    this.financialEngineService.readSearchUsingGET('978', this.translate.currentLang, environment.admin.api.sipsNormalReturnSearch, undefined, environment.admin.sips.templates.token)
      .subscribe(response => {
        this.sipsRequest = response;

        this.sipsForm.nativeElement.action = this.sipsRequest.url;
        this.data.nativeElement.value = this.sipsRequest.data;
        this.encode.nativeElement.value = this.sipsRequest.encode;
        this.version.nativeElement.value = this.sipsRequest.interfaceVersion;
        this.seal.nativeElement.value = this.sipsRequest.seal;

        this.sipsForm.nativeElement.submit();
        this.message = null;
      }, _err => {
        this.message = {
          title: this.translate.instant('TOAST.ERROR.FAILED'),
          icons: ['fa', 'fa-exclamation-circle']
        };
      });
  }
}
