<div *ngIf="values && values.length > 0" class="period">
  <!-- left part -->
  <div class="period-start-end period-height-bar period-bar-default-color"></div>
  <!-- values -->
  <div *ngFor="let value of values; last as isLast" class="period-height-bar period-bar-default-color" [ngClass]="{'period-active': value.applicable}" [ngStyle]="{'width': value.size + '%'}">
    <div class="period-separator" *ngIf="value.startTime === '00:00'"></div>
    <div class="period-text">{{ value.startTime }}</div>
  </div>
  <!-- Last value and right part -->
  <div class="period-start-end period-height-bar period-bar-default-color">
    <div class="period-separator" *ngIf="getLastValue().endTime === '00:00'"></div>
    <div class="period-text">{{ getLastValue().endTime }}</div>
  </div>
</div>
