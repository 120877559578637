import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'dd/MM/yyyy HH:mm:ss'): any {

    const currentLang = this.translateService.currentLang;

    const datePipe: DatePipe = new DatePipe(currentLang);

    // Pattern localized
    if (currentLang === 'en') {
      switch (pattern) {
        case 'dd/MM/yyyy HH:mm:ss':
          pattern = 'MM/dd/yyyy HH:mm:ss';
          break;
        case 'dd MMM':
          pattern = 'MMM dd';
          break;
        case 'd/M/yy':
          pattern = 'M/d/yy';
          break;
        case 'dd MMM HH:mm:ss':
          pattern = 'MMM dd HH:mm:ss';
          break;
        case 'dd MMM HH:mm:ss':
          pattern = 'MMM dd HH:mm:ss';
          break;
        case 'dd MMM HH:mm':
          pattern = 'MMM dd HH:mm';
          break;
        case 'dd/MM/yyyy HH:mm:s':
          pattern = 'MM/dd/yyyy HH:mm:ss';
          break;
        case 'dd/MM/yyyy HH:mm':
          pattern = 'MM/dd/yyyy HH:mm'
          break;
        case "dd/MM/yyyy hh'h'mm":
          pattern = "MM/dd/yyyy hh'h'mm";
          break;
        case 'dd MMMM y':
          pattern = 'MMMM dd y';
          break;
        case 'dd MMM yyyy':
          pattern = 'MMM dd yyyy';
          break;
        case 'fullDate':
          pattern = 'MM/dd/yyyy \'at\' HH:mm:ss';
          break;
      }
    }

    // Test if we want a full date
    if (pattern === 'fullDate') {
      switch (currentLang) {
        case 'fr':
          pattern = 'dd/MM/yyyy \'à\' HH:mm:ss';
          break;
        case 'en':
          pattern = 'MM/dd/yyyy \'at\' HH:mm:ss';
          break;
        case 'de':
          pattern = 'dd/MM/yyyy \'um\' HH:mm:ss';
          break;
        case 'es':
          pattern = 'dd/MM/yyyy \'a las\' HH:mm:ss';
          break;
      }
    }
    return datePipe.transform(value, pattern);
  }
}
