import { AbstractFilterTemplate } from '../filter-template/abstract-filter-template.model';

export abstract class AbstractFilter {
  filterTemplate: AbstractFilterTemplate<AbstractFilter>;
  enabled = true;

  public abstract getType(): string;

  public abstract encode(): string;

}
