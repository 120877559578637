<div>
  <select class="form-control" (change)="onChange($event.target.value)" *ngIf="filter.getAutocompleteFilterTemplate().criterion.length > 1; else showLabel">
    <option value="" disabled>-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
    <option *ngFor="let criterion of filter.getAutocompleteFilterTemplate().criterion" [value]="criterion">{{ 'FILTERS.ADD.' + criterion | translate }}</option>
  </select>
  <ng-template #showLabel>
    <ap-label [status]="'DEFAULT'">{{ 'FILTERS.ADD.' + operator | translate }}</ap-label>
  </ng-template>
 
  <ap-autocomplete *ngIf="shouldShowAutocompleteField()" [value]="value" [minSearch]="filter.getAutocompleteFilterTemplate().minSearch" [autocomplete]="filter.getAutocompleteFilterTemplate().searchMethod" (clear)="onClear()" (choice)="onSelect($event)" [placeholder]="filter.filterTemplate.label"></ap-autocomplete>

  <button [disabled]="!value || !operator" type="button" (click)="onSubmit()" class="btn btn-success" attr.aria-label="{{ 'FILTERS.ADD.VALID' | translate }}">{{ 'FILTERS.ADD.VALID' | translate }}</button>
</div>
