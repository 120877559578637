import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/core/services/base.service';
import { Pagination } from '@app/shared/components/pagination/pagination.model';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, pluck, takeUntil, tap } from 'rxjs/operators';
import { Page } from '../commons/page';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { RefundCase } from '@app/core/services/financial-engine/models/refund/refund-case.model';
import { RefundCaseParameters, RefundCases } from '@app/core/services/financial-engine/models/refund/refund-case-parameters.model';
import { RefundRequest } from '@app/core/services/financial-engine/models/refund/refund-request.model';
import { SettlementFilter, SettlementSummary } from './models/settlementSummary';
import { SipsRequest } from './models/sipsRequest';
import { AdministratorService } from '../administrator/administrator.service';
import { UtilService } from '../util/util.service';
import { SettlementSummaryItem } from './models/settlementSummaryItem.model';
import { ExecutionModeEnum } from './models/enum/executionMode.enum';
import { CauseEnum } from './models/enum/cause.enum';
import { SettlementStatusEnum } from './models/enum/settlementStatus.enum';
import { RefundCaseStatusEnum } from './models/refund/enum/refund-case-status.enum';
import { RefundCaseComment } from './models/refund/refund-case-comment.model';
import { CommentTypeEnum } from './models/enum/commentType.enum';

@Injectable({
  providedIn: 'root'
})
export class FinancialEngineService extends BaseService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  /* For sharing informations between UserJourneyComponent & UserJourneyRefundComponent */
  refundCaseToUpdate$: BehaviorSubject<RefundCase> = new BehaviorSubject(null);
  private refundInitiatedCounterSource = new BehaviorSubject<number>(0);

  // Observable to give the number of refunds in INITIATED Status
  refundInitiatedCounter$: Observable<number> = this.refundInitiatedCounterSource.asObservable();
  isAuthorizedRefundRole = ['ROOT_ADMIN', 'SUPER_ADMIN', 'MYCOMPANY_ADMIN_WITH_TARIFF_MANAGEMENT',
    'MYCOMPANY_ADMIN_WITHOUT_TARIFF_MANAGEMENT', 'MYCOMPANY_FINANCIAL_AGENT'].includes(this.administratorService.loggedAgent$.getValue().role.name);

  private ACTOR_TYPE = 'AGENT';

  constructor(
    private httpClient: HttpClient,
    protected toastr: ToastrService,
    protected translate: TranslateService,
    private administratorService: AdministratorService,
    private utilService: UtilService
  ) {
    super(toastr, translate);
  }

  /**
   * Decode url encoded filter to SettlementFilter object
   * Rules: If no filter on status then add some filters ('EXECUTED', 'FAILED', 'SUBROGATED') on other filter
   *
   * @param filterEncoded string
   * @returns
   */
   public decodeFilter(filterEncoded: string): SettlementFilter {
    const settlementFilter: SettlementFilter = {};

    if (!filterEncoded.match(/^([^\~]+\~(0|1)\~[^\~]+(\~[\|]*)?([^\~]+)?\|)*([^\~]+\~(0|1)\~[^\~]+(\~[\|]*)?([^\~]+)?)$/)) {
      console.error('Url filter format not valid');
      return settlementFilter;
    }

    filterEncoded.split('|')
      .map(s => {
        const key = s.split('~')[0];
        const enabled = s.split('~')[1] === '1';
        const value = s.split('~')[2];

        return { key, enabled, value};
      })
      .filter(filter => filter.enabled)
      .forEach(filter => {
        switch (filter.key) {
          case 'reference':
            settlementFilter.reference = filter.value;
            break;
          case 'paymentExecutionDate':
            settlementFilter.paymentExecutionDate = new Date(filter.value);
            break;
          case 'sincePaymentExecutionDate':
            settlementFilter.since = new Date(filter.value);
            break;
          case 'untilPaymentExecutionDate':
            settlementFilter.until = new Date(filter.value);
            break;
          case 'paymentAccountReference':
            settlementFilter.paymentAccountReference = filter.value;
            break;
          case 'accessMediaDescription':
            settlementFilter.accessMediaDescription = filter.value;
            break;
          case 'serviceOperator':
            settlementFilter.serviceOperator = filter.value;
            break;
          case 'status':
            if (filter.value === SettlementStatusEnum.EXECUTED) {
              settlementFilter.cause = CauseEnum.PAYMENT;
              settlementFilter.statuses = [SettlementStatusEnum.EXECUTED, SettlementStatusEnum.SUBROGATED];
            } else if (filter.value === CauseEnum.REFUND) {
              settlementFilter.cause = CauseEnum.REFUND;
            } else {
              settlementFilter.statuses = [SettlementStatusEnum[filter.value]];
            }
            break;
          case 'since':
            settlementFilter.since = new Date(filter.value);
            break;
          case 'until':
            settlementFilter.until = new Date(filter.value);
            break;
          case 'cause':
            settlementFilter.cause = CauseEnum[filter.value];
            break;
          case 'externalTransactionReference':
            settlementFilter.externalTransactionReference = filter.value;
            break;
          case 'userAccount':
            settlementFilter.userAccount = filter.value;
            break;
          case 'executionModes':
            settlementFilter.executionModes = [ExecutionModeEnum[filter.value]];
        }
      });
    return settlementFilter;
  }

  /**
   * Initialize a given SettlementFilter in a given HttpParams
   *
   * @param filter: SettlementFilter
   * @param params: HttpParams
   */
   private initHttpParamsWithSettlementFilter(filter: SettlementFilter, params: HttpParams): HttpParams {
    for (const [key, value] of Object.entries(filter)) {
      if (typeof value === 'string' && value.trim() !== '') {
        params = params.set(key, value.trim());
      } else if (value && Array.isArray(value)) {
        (<string[]>value).forEach(element => {
          params = params.append(key, element);
        });
      }
    }
    return params;
  }

  /**
   * Get all settlements by filters
   *
   * @param since since de type Date
   * @param until until de type Date
   * @param sortOrder ordre du tri en string
   * @param sortElement element à trier en string
   * @param pageNumber numéro de la page en number
   * @param pageSize taille de la page en number
   */
  findSettlements(filter: SettlementFilter, sortOrder: string, sortElement: string, pageNumber = 0, pageSize = 100): Observable<Page<SettlementSummary>> {

    let sort = sortElement + ',' + sortOrder;
    let params = new HttpParams()
      .set('sort', sort)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString());

    if (filter) {
      params = this.initHttpParamsWithSettlementFilter(filter, params);
      if (filter.paymentExecutionDate) {
        params = params.set('paymentExecutionDate', moment(filter.paymentExecutionDate).startOf('day').format('yyyy-MM-DD'));
      }
      if (filter.since) {
        params = params.set('since', moment(filter.since).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
      }
      if (filter.until) {
        params = params.set('until', moment(filter.until).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
      }
    }

    return this.httpClient.get<Page<SettlementSummary>>(`${environment.admin.api.financialEngine}/api/invoicing-manager/v1/settlements`, { params });
  }

  /**
   * Get all access media settlements by filters
   *
   * @param accessMediaReferences tableau des références de support en string[]
   * @param since since de type Date
   * @param until until de type Date
   * @param sortOrder ordre du tri en string
   * @param sortElement element à trier en string
   * @param pageNumber numéro de la page en number
   * @param pageSize taille de la page en number
   */
  findSettlementsByAccessMedias(accessMediaReferences: string[], since: Date, until: Date,
                                sortOrder: string, sortElement: string, pageNumber = 0, pageSize = 10000): Observable<Page<SettlementSummary>> {

    if (!accessMediaReferences || accessMediaReferences.length === 0) {
      return of({
        content : [],
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
      });
    }

    let params = new HttpParams();
    if (accessMediaReferences && accessMediaReferences.length > 0) {
      accessMediaReferences.forEach((element) => {
        params = params.append('accessMediaReference', element);
      });
    }
    if (since) {
      params = params.set('since', moment(since).startOf('day').toISOString());
    }
    if (until) {
      params = params.set('until', moment(until).endOf('day').toISOString());
    }
    if (pageNumber) {
      params = params.set('page', pageNumber.toString());
    }
    if (pageSize) {
      params = params.set('size', pageSize.toString());
    }
    params = params.set('sort', sortElement + ',' + sortOrder);

    return this.httpClient.get<Page<SettlementSummary>>(`${environment.admin.api.financialEngine}/api/invoicing-manager/v1/settlements`, { params });
  }

  /**
   * Find settlements summary by transaction ids
   *
   * @param transactionId Filter by a list of transactionId
   */
  public findSettlementsSummaryByTransactionIds(transactionIds: string[]): Observable<Page<SettlementSummary>> {
    if (!transactionIds || transactionIds.length === 0) {
      return of({ content : [], number: 0, size: 0, totalElements: 0, totalPages: 0 });
    }
    let params = new HttpParams();
    transactionIds.forEach((element) => {
      params = params.append('transactionId', <any>element);
    });

    return this.httpClient
          .get<Page<SettlementSummary>>(`${environment.admin.api.financialEngine}/api/invoicing-manager/v1/settlements`, { params });
  }

  generateSipsRequest(transportOfferOrigin: string, transportOfferReference: string, templateName: string,
                                        orderChannel: string, locale: string, merchantSiteCallback: string): Observable<SipsRequest> {
    const params = new HttpParams()
      .set('transportOfferOrigin', transportOfferOrigin)
      .set('transportOfferReference', transportOfferReference)
      .set('templateName', templateName)
      .set('orderChannel', orderChannel)
      .set('locale', locale)
      .set('merchantSiteCallback', merchantSiteCallback);

    return this.httpClient
      .get<SipsRequest>(environment.admin.api.financialEngine + '/v1/payments/settle-debt/generate-sips-link', {
        params,
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });
  }

  generateSipsRequestToRefund(passengerTaps: string[], mobilityAccountId: number, templateName: string, locale: string, merchantSiteCallback: string, comment: string, accessMediaReferences: string[]): Observable<SipsRequest> {
    let params = new HttpParams()
      .set('comment', comment)
      .set('templateName', templateName)
      .set('locale', locale)
      .set('merchantSiteCallback', merchantSiteCallback);

    if (mobilityAccountId) {
      params = params.set('mobilityAccountId', mobilityAccountId + '');
    }

    if (environment.admin.users.refund.enabled) {
      if (environment.admin.users.refund.refundMode.deferred) {
        params = params.set('executionMode', 'DEFERRED');
      } else {
        params = params.set('executionMode', 'IMMEDIATE');
      }
    }

    if (accessMediaReferences?.length > 0) {
      params = params.set('accessMediaReferences', accessMediaReferences.join(','));
    }

    params = passengerTaps.reduce((acc: HttpParams, passengerTap: string) => acc.append('passengerTap', passengerTap), params);
    return this.httpClient.get<SipsRequest>(`${environment.admin.api.financialEngine}/v1/refund-cases/sips/_generate-link`, { params });
  }

  findSettlementStatus(transactionIds: string[]): Observable<CauseEnum[]> {
    let params = new HttpParams()
      .set('page', '0')
      .set('size', '500');

    params = transactionIds.reduce((acc: HttpParams, transactionId: string) => acc.append('transactionId', transactionId), params);

    return this.httpClient.get<SipsRequest>(environment.admin.api.financialEngine + '/api/invoicing-manager/v1/settlements', { params }).pipe(
      pluck('content'),
      map((settlements: any) => {
        return settlements.map(s => s.cause);
      })
    );
  }

  findSettlement(reference: string): Observable<SettlementSummary> {
    return this.httpClient.get<SettlementSummary>(environment.admin.api.financialEngine + '/api/invoicing-manager/v1/settlements/reference/' + reference);
  }

  findSettlementItems(transactionIds: string[]): Observable<SettlementSummaryItem[]> {
    let params = new HttpParams()
      .set('page', '0')
      .set('size', '500');

    transactionIds.forEach((transactionId: string) => {
      params = params.append('transactionId', transactionId);
    });

    return this.httpClient.get<SipsRequest>(environment.admin.api.financialEngine + '/api/invoicing-manager/v1/settlements', { params }).pipe(
      pluck('content'),
      map((settlements: any) => {
        return settlements.flatMap(s => s.items).filter((i: SettlementSummaryItem) => transactionIds.includes(i.transactionId));
      })
    );
  }

  /**
   * Read access media state by filters
   * Read access media state in the Access Control Manager by filters
   * @param actorReference actorReference
   * @param actorType actorType
   */
  public readSearchUsingGET(currencyCode?: string, customerLanguage?: string, normalReturnUrl?: string, orderChannel?: string, templateName?: string): Observable<Array<any>> {
    let queryParameters = new HttpParams();

    if (currencyCode) {
      queryParameters = queryParameters.set('currencyCode', currencyCode);
    }
    if (customerLanguage) {
      queryParameters = queryParameters.set('customerLanguage', customerLanguage);
    }
    if (normalReturnUrl) {
      queryParameters = queryParameters.set('normalReturnUrl', normalReturnUrl);
    }
    if (orderChannel) {
      queryParameters = queryParameters.set('orderChannel', orderChannel);
    }
    if (templateName) {
      queryParameters = queryParameters.set('templateName', templateName);
    }

    return this.httpClient.get<Array<any>>(environment.admin.api.financialEngine + '/api/payment-gateway/v1/sips/parameters/search', { params: queryParameters });
  }

  /**
   * Read paginated refund cases by filters
   *
   * @param accessMediaReference Reference of the access media used for the journeys/taps
   * @param accessMediaType Type of the access media used for the journeys/taps
   * @param accountId Mobility account identifier
   * @param holderEmailAddress Holder&#39;s email address of AccessMedia used for the journey
   * @param holderFirstName Holder&#39;s first name of AccessMedia used for the journey
   * @param holderLastName Holder&#39;s last name of AccessMedia used for the journey
   * @param settlementReference Holder&#39;s reference folder used for the journey
   * @param page Results page you want to retrieve (0..N)
   * @param serviceOperator Service operator recipient of the refund
   * @param since Since date of one journey in the refund case
   * @param size Number of records per page.
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param status RefundCase status list)
   * @param until Until date of one journey in the refund case
   */
  public readRefundCasesUsingGET(refundCaseParams: RefundCaseParameters): Observable<Page<RefundCase>> {

    let queryParameters = this.initRefundCaseParameters(refundCaseParams);
    let headers = this.defaultHeaders;

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Page<RefundCase>>(`${environment.admin.api.financialEngine}/api/invoicing-manager/v1/refund-cases`,
    {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: refundCaseParams.observe,
      reportProgress: refundCaseParams.reportProgress
    });
  }

  private initRefundCaseParameters(refundCaseParams: RefundCaseParameters) {
    // Retrieving service operator stored in the session
    const partitionedOperator = this.utilService.getVariable('companyRef', 'session');
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (refundCaseParams.maskedPan) {
      queryParameters = queryParameters.set('maskedPan', <any>refundCaseParams.maskedPan);
    }
    if (refundCaseParams.accessMediaReferences && refundCaseParams.accessMediaReferences.length > 0) {
      queryParameters = queryParameters.set('accessMediaReferences', refundCaseParams.accessMediaReferences.join(','));
    }
    if (refundCaseParams.accessMediaType) {
      queryParameters = queryParameters.set('accessMediaType', <any>refundCaseParams.accessMediaType);
    }
    if (refundCaseParams.accountId) {
      queryParameters = queryParameters.set('accountId', <any>refundCaseParams.accountId);
    }
    if (refundCaseParams.holderEmailAddress) {
      queryParameters = queryParameters.set('holderEmailAddress', <any>refundCaseParams.holderEmailAddress);
    }
    if (refundCaseParams.holderFirstName) {
      queryParameters = queryParameters.set('holderFirstName', <any>refundCaseParams.holderFirstName);
    }
    if (refundCaseParams.holderLastName) {
      queryParameters = queryParameters.set('holderLastName', <any>refundCaseParams.holderLastName);
    }
    if (refundCaseParams.settlementReference) {
      queryParameters = queryParameters.set('settlementReference', <any>refundCaseParams.settlementReference);
    }
    if (refundCaseParams.page) {
      queryParameters = queryParameters.set('page', <any>refundCaseParams.page);
    }
    if (refundCaseParams.serviceOperator) {
      queryParameters = queryParameters.set('serviceOperator', <any>refundCaseParams.serviceOperator);
    } else if (partitionedOperator) {
      /* For partitioned agent this parameter should always be provided to avoid unauthorized error */
      queryParameters = queryParameters.set('serviceOperator', <any>partitionedOperator);
    }
    if (refundCaseParams.since) {
      queryParameters = queryParameters.set('since', refundCaseParams.since.toISOString());
    }
    if (refundCaseParams.size) {
      queryParameters = queryParameters.set('size', <any>refundCaseParams.size);
    }
    if (refundCaseParams.sort) {
      refundCaseParams.sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }
    if (refundCaseParams.status) {
      refundCaseParams.status.forEach((element) => {
        queryParameters = queryParameters.append('status', <any>element);
      });
    }
    if (refundCaseParams.until) {
      queryParameters = queryParameters.set('until', refundCaseParams.until.toISOString());
    }
    if (refundCaseParams.transactionIds) {
      refundCaseParams.transactionIds.forEach(transactionId => {
        queryParameters = queryParameters.append('transactionId', transactionId);
      });
    }
    return queryParameters;
  }

  /**
   * Added this changes to store Refund Cancellation Reason as comment with type REFUND_CANCEL_REASON
   * changes for EPIC-8905 [REFUND] Manage a reason for cancellation by the financial agent
   * @param comment Refund Cancel Comment
   * @param refundCaseId Refund case identifier
   * @param actorReference actorReference
  */
  public cancelRefundCaseUsingPUT(comment: string, refundCaseId: number, actorReference?: string): Observable<any> {

    if (!comment) {
      throw new Error('Required parameter refundCaseComment was null or undefined when calling cancelRefundCaseUsingPUT.');
    }

    if (!refundCaseId) {
      throw new Error('Required parameter refundCaseId was null or undefined when calling cancelRefundCaseUsingPUT.');
    }

    let headers = this.defaultHeaders
      .set('actorType', this.ACTOR_TYPE)
      .set('actorReference', actorReference ? actorReference : this.administratorService.loggedAgent$.getValue().userCredentials.username);

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    const httpHeaderContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(['application/json']);

    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    if (httpHeaderContentTypeSelected) {
      headers = headers.set('Content-Type', httpHeaderContentTypeSelected);
    }
    
    // Create RefundCaseComment object
    const refundCaseComment: RefundCaseComment = {
      agent:this.administratorService.loggedAgent$.getValue().userCredentials.userKeycloakId,
      content: comment,
      date:new Date(),
      commentType: CommentTypeEnum.REFUND_CANCEL_REASON,
      refundCaseId: refundCaseId
    }

    return this.httpClient.put<any>(environment.admin.api.financialEngine + '/api/invoicing-manager/v1/refund-cases/' + refundCaseId + '/_cancel',
    refundCaseComment, // use the new object here
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: 'body',
      reportProgress: false
    }).pipe(
      tap(() => this.refreshRefundInitiatedCounter())
    );
  }

  /**
   * Save result of processing a refund case (PROCESSED or ERROR)
   * In case of error, a message could be provided
   * @param refundCaseId Refund case identifier
   * @param error Eventual error during processing
  */
  public processRefundUsingGET(refundCaseId: number): Observable<any> {

    if (!refundCaseId) {
      throw new Error('Required parameter refundCaseId was null or undefined when calling processRefundUsingGET.');
    }

    let headers = this.defaultHeaders;
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RefundCase>(environment.admin.api.financialEngine + '/v1/refund-cases/' + refundCaseId + '/_process',
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: 'body',
      reportProgress: false
    }).pipe(
      tap(() => this.refreshRefundInitiatedCounter())
    );
  }

  public getRefundCases(params: RefundCaseParameters) {
    return this.readRefundCasesUsingGET(params).pipe(
      map((page: Page<RefundCase>) => {
        const refundCases = page.content;
        const pagination = new Pagination(page.totalElements, page.totalPages, page.size, page.number);
        return new RefundCases(refundCases, pagination);
      })
    );
  }

  public getTransactionIdsFromRefundCases(params: RefundCaseParameters): Observable<string[]> {

    let queryParameters = this.initRefundCaseParameters(params);

    let headers = this.defaultHeaders;
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<string[]>(`${environment.admin.api.financialEngine}/api/invoicing-manager/v1/refund-cases/taps`,
    {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: params.observe,
      reportProgress: params.reportProgress
    });
  }

  /**
   * Save refund cases for some taps. Transform a refund request into multiple refund cases (splitting them by (service operator ; access media) key)
   *
   * @param refundRequest Request to refund some taps
  */
  public saveRefundCasesUsingPOST(refundRequest: RefundRequest): Observable<any> {

    if (!refundRequest) {
      throw new Error('Required parameter refundRequest was null or undefined when calling saveRefundCasesUsingPOST.');
    }

    let headers = this.defaultHeaders
      .set('actorReference', this.administratorService.loggedAgent$.getValue().userCredentials.username)
      .set('actorType', this.ACTOR_TYPE);


    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(['application/json']);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${environment.admin.api.financialEngine}/v1/refund-cases/_prepare`, refundRequest,
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: 'body',
      reportProgress: false
    }).pipe(
      tap(() => this.refreshRefundInitiatedCounter())
    );
  }

  /**
   * Save and process refund cases for some taps. Transform a refund request into multiple refund cases
   * (splitting them by (service operator ; access media) key),then immediately process them
   *
   * @param refundRequest refundRequest
  */
  public saveAndProcessRefundCasesUsingPOST(refundRequest: RefundRequest): Observable<any> {

    if (!refundRequest) {
      throw new Error('Required parameter refundRequest was null or undefined when calling saveAndProcessRefundCasesUsingPOST.');
    }

    let headers = this.defaultHeaders;

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(['application/json']);
    if (httpContentTypeSelected) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${environment.admin.api.financialEngine}/v1/refund-cases/_save_and_process`, refundRequest,
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: 'body',
      reportProgress: false
    });
  }

  /**
   * Read a refund case by its identifier
   *
   * @param refundCaseId Refund case identifier
  */
  public readRefundCaseByIdUsingGET(refundCaseId: number): Observable<RefundCase> {

    if (!refundCaseId) {
      throw new Error('Required parameter refundCaseId was null or undefined when calling readRefundCaseByIdUsingGET.');
    }

    let headers = this.defaultHeaders
      .set('actorReference', this.administratorService.loggedAgent$.getValue().userCredentials.username)
      .set('actorType', this.ACTOR_TYPE);

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(['application/json']);
    if (httpHeaderAcceptSelected) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RefundCase>(environment.admin.api.financialEngine + '/api/invoicing-manager/v1/refund-cases/' + refundCaseId,
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: 'body',
      reportProgress: false
    });
  }

  nextRefundCaseToUpdate(value: RefundCase) {
    this.refundCaseToUpdate$.next(value);
  }

  getRefundCaseToUpdate(): Observable<RefundCase> {
    return this.refundCaseToUpdate$.asObservable();
  }

  refreshRefundInitiatedCounter() {
    const serviceOperator = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    const parameters: RefundCaseParameters = { status: [RefundCaseStatusEnum.INITIATED], serviceOperator: serviceOperator };
    // Avoid calling the counter function for unauthorized profiles
    if (this.isAuthorizedRefundRole) {
      this.readRefundCasesUsingGET(parameters).pipe(takeUntil(this.unsubscribe)).subscribe(
        (nbRefundInitiated) => {
          this.refundInitiatedCounterSource.next(nbRefundInitiated.content.length)
        }, err => this.refundInitiatedCounterSource.next(0)
      );
    }
  }

  /**
   * To delete the receipt (data + file)
   * @param receiptReference
   * @param serviceEmitter
   * @returns
  */
  removeReceipt(receiptReference: string, serviceEmitter: string): Observable<void> {
    const url = `${environment.admin.api.financialEngine}/api/invoicing-manager/v1/services/${serviceEmitter}/receipts/${receiptReference}/_hard`
    return this.httpClient.delete<void>(url);
  }
}
