import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ap-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css'],
  animations: [
    trigger('toggleOverlayAction', [
      transition(':enter', [
        style({ width: '0px', overflow: 'hidden' }),
        animate('200ms', style({ width: '*' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ width: '0px', overflow: 'hidden' }))
      ])
    ])
  ]

})
export class OverlayComponent implements OnInit {

  @Output() close = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('document:keydown.escape', ['$event']) escape(event: KeyboardEvent) {
    this.closeOverlay();
  }

  closeOverlay() {
    this.close.emit(true);
  }
}
