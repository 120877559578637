import { AfterViewInit, Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({ selector: '[ap-page-height]' })
export class PageHeightDirective implements AfterViewInit {

  @Input() overflow = 'auto';
  @Input() offset = 10;

  constructor(private elem: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() =>{
      this.adaptSize();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.adaptSize();
  }

  private adaptSize() {
    this.elem.nativeElement.style.height = (window.innerHeight - this.elem.nativeElement.getBoundingClientRect().top - this.offset) + 'px';
    this.elem.nativeElement.style.overflowY = 'auto';
  }
}
