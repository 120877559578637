import { Pipe, PipeTransform } from '@angular/core';
import { UserAdmin } from '@app/core/services/administrator/models/userAdmin';

@Pipe({ name: 'userString' })
export class userStringPipe implements PipeTransform {
  transform(user: UserAdmin): string {
    const firstName = user?.userDetails?.userFirstName ? user.userDetails?.userFirstName + ' ' : '';
    return firstName + (user?.userDetails?.userLastName ? user.userDetails?.userLastName : '');
  }
}
