import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { AdministratorService } from '../services/administrator/administrator.service';
import { PermissionEnum } from '../services/administrator/models/enum/permission';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard {

  constructor(protected router: Router, protected keycloakService: KeycloakService, protected administratorService: AdministratorService) {
    super(router, keycloakService);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.authenticated) { // If I am authenticated with keycloak
        // Get roles and permissions to check
        const requiredRoles = <string[]>route.data['roles'] || [];
        const requiredPermissions = <string[]>route.data['permissions'] || [];

        // Get role / permissions of the logged agent
        const loggedAgent = this.administratorService.loggedAgent$.getValue();
        const role = loggedAgent.role.name;
        const permissions = loggedAgent.role.permissions;

        // Check if the logged agent has the right role
        const checkRole = (r: string) => r.includes(role);
        const grantedRole = requiredRoles.length === 0 || (role && requiredRoles.some(checkRole));

        // Check if the logged agent has the right permissions
        const checkPermissions = (permission: PermissionEnum) => permissions.includes(permission);
        const grantedPermission = requiredPermissions.length === 0 || (permissions && requiredPermissions.some(checkPermissions));

        if (grantedRole && grantedPermission) {
          resolve(true);
        } else {
          this.router.navigate(['/error', 403]);
          resolve(false);
        }
      } else { // Display login page if not logged
        this.keycloakAngular.login();
        return;
      }
    });
  }
}
