<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <select formControlName="operator" class="form-control" *ngIf="filter.getTextFilterTemplate().criterion.length > 1; else showLabel">
    <option value="" disabled>-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
    <option *ngFor="let criterion of filter.getTextFilterTemplate().criterion" [value]="criterion">{{ 'FILTERS.ADD.' + criterion | translate }}</option>
  </select>
  <ng-template #showLabel>
    <ap-label [status]="'DEFAULT'">{{ 'FILTERS.ADD.' + filterForm.get('operator').value | translate }}</ap-label>
  </ng-template>
  <input *ngIf="shouldShowInputField()" type="text" class="form-control" formControlName="value" placeholder="value" attr.aria-label="value"/>
  <button [disabled]="!filterForm.valid" class="btn btn-success" type="submit" attr.aria-label="{{ 'FILTERS.ADD.VALID' | translate }}">{{ 'FILTERS.ADD.VALID' | translate }}</button>
</form>

