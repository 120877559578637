import { Component, Input, OnInit } from '@angular/core';
import { AccessMediaFilter } from './access-media-filter.model';

@Component({
  selector: 'ap-access-media-filter-item',
  templateUrl: './access-media-filter-item.component.html'
})
export class AccessMediaFilterItemComponent implements OnInit {

  @Input() filter: AccessMediaFilter;

  constructor() {}

  ngOnInit() {
  }
}