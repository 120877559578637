import { AfterViewInit, Component, ContentChildren, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import slugify from 'slugify';
import { OptionComponent, OptOptionComponent } from '../select/option.component';

@Component({
  selector: 'ap-mulitple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectComponent),
      multi: true
    }
  ]
})
export class MultipleSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

  currentValue: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  @Input() existingValues?: string[] = [];

  labels: string[] = [];
  hasExistingValue: boolean = false;
  edition = false;
  term: string;
  subscriptionOptions: Subscription[];

  @ViewChild('list') list:ElementRef;
  @ViewChild('input') input:ElementRef;

  margin = 0;

  @ContentChildren(OptionComponent, { descendants: true }) optionComponents: QueryList<OptionComponent>;
  @ContentChildren(OptOptionComponent, { descendants: true }) optOptionComponents: QueryList<OptOptionComponent>;

  isEmpty = false;

  constructor(private eRef: ElementRef) { }

  /**
   * Disable choice panel (edition mode) if user click outside component
   * @param targetElement Element
   */
  @HostListener('document:click', ['$event.target'])
  clickout(targetElement) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);

    if (targetElement.localName !== 'button' && targetElement.localName !== 'i' && this.edition && !clickedInside) {
      this.edition = false;
    }
  }

  ngOnInit() {
    this.term = '';
  }

  ngAfterViewInit() {
    // TODO: Fix local error : ExpressionChangedAfterItHasBeenCheckedError
    if (!this.hasExistingValue && this.optionComponents && this.labels.length === 0) {
      const values = [];
      this.optionComponents.map(option => {
        if (this.existingValues.includes(option.value)) {
          this.labels.push(this.getCanonicalName(option));
          values.push(option.value);
          option.isDisplayed = false;
        }
      });
      if (!this.optionComponents.some(o => o.isDisplayed)) {
        this.isEmpty = true;
      }
      this.writeValue(values);
      this.hasExistingValue = true;
    }

    this.subscriptionOptions = this.optionComponents.map(option => {
      return option.selectValue.subscribe((value: any) => {
        this.labels.push(this.getCanonicalName(option));
        option.isDisplayed = false;
        if (!this.optionComponents.some(o => o.isDisplayed)) {
          this.isEmpty = true;
        }
        const values = this.currentValue.value || [];
        values.push(value);
        this.writeValue(values);
        this.reset();
      });
    });
  }

  reset() {
    this.term = '';
    this.filter();
  }

  removeChoice(label: string) {
    this.labels = this.labels.filter(l => l !== label);
    const option = this.optionComponents.find(option => option.label === label);
    if (option) {
      option.isDisplayed = true;
      this.currentValue.next(this.currentValue.value.filter(v => v !== option.value));
    }

    if (this.optionComponents.some(o => o.isDisplayed)) {
      this.isEmpty = false;
    }
    this.edition = false;
  }

  /**
   * Build a label with ancestor labels.
   * @param option OptionComponent
   * @returns string
   */
  private getCanonicalName(option: OptionComponent): string {
    let opt = option.optOptionParent;
    let label = '';

    while(opt) {
      label += opt.label + ' > ';
      opt = opt.optOptionParent;
    }

    return label + option.label;
  }

  /**
   * Filter options with a slug
   */
  filter() {
    this.isEmpty = true;
    this.optionComponents.forEach(option => {
      const value = slugify(this.getCanonicalName(option), {
        lower: true,
        locale: 'fr',
      });
      const term = slugify(this.term, {
        lower: true,
        locale: 'fr',
      });

      if (this.currentValue.value) {
        option.isDisplayed = !this.currentValue.value.includes(option.value) && value.includes(term);
      } else {
        option.isDisplayed = value.includes(term);
      }

      if (option.isDisplayed) {
        this.isEmpty = false;
      }
    });

    this.optOptionComponents.forEach(opt => {
      opt.isDisplayed = opt.optionComponents.some(option => option.isDisplayed);
    });
  }

  /**
   * Display panel to choice value
   */
  enableEdition() {
    this.margin = this.list.nativeElement.offsetWidth - this.input.nativeElement.offsetWidth;
    this.edition = true;
  }

  onTouched: () => void;

  registerOnChange(fn: any): void {
    this.currentValue.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any[]): void {
    this.edition = false;
    if (obj != null && obj.length > 0) {
      this.currentValue.next(obj);
    }
  }

  ngOnDestroy(): void {
    this.subscriptionOptions.forEach(s => s.unsubscribe());
  }

}
