import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from './pagination.model';

/**
 * Pagination component. This component provide element to display pagination.
 *
 * @example
 * <pre><code>
 *   <ap-pagination (changeSize)="onChangeSize($event)" (selectedPage)="onChangePage($event)" [pagination]="pagination"></ap-pagination>
 * </code></pre>
 */
@Component({
  selector: 'ap-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  _pagination: Pagination;
  get pagination(): Pagination {
    return this._pagination;
  }
  @Input() set pagination(pagination: Pagination) {
    this._pagination = pagination;
    this.onPage(pagination.page);
  }

  pages = [];

  @Output() selectedPage = new EventEmitter();
  @Output() changeSize = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  onPage(selected: number) {
    const pages = [selected];
    const max = 5;
    let i = 1;

    while (pages.length < max && i < max * 2) {
      if (selected - i >= 0) {
        pages.push(selected - i);
      }
      if (selected + i < this._pagination.totalPages) {
        pages.push(selected + i);
      }
      i++;
    }

    this.pages = pages.sort(function(a, b) {return a - b; });

    if (this._pagination.page != selected) {
      this.selectedPage.emit(selected);
    }

    this._pagination.page = selected;
  }

  onSizePage(size: number) {
    if (size != this._pagination.elements) {
      this._pagination.elements = size;
      this.changeSize.emit(size);
    }
  }

}
