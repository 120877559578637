import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(value: string[], separator: string = ',', count: number = 0): string {
    value = value.map(s => s);
    if (count > 0) {
      let result = value.slice(0, count).join(separator);
      if (value.length > count) {
        result = result.concat("...");
      }
      return result
    } else {
      return value.join(separator);
    }
  }
}
