import { AccessMediaFilter } from "../../filter-item/access-media-filter-item/access-media-filter.model";
import { AbstractFilterTemplate } from "../abstract-filter-template.model";

export class AccessMediaFilterTemplate extends AbstractFilterTemplate<AccessMediaFilter> {
    criterion: 'EQUALS' | 'CONTAINS';
    defaultValue: string = '';
    constructor (name: string, label: string, group: string = 'DEFAULT', criterion: 'EQUALS' | 'CONTAINS' = 'EQUALS', defaultValue: string = '') {
        super(name, label, group);
        this.defaultValue = defaultValue;
        this.criterion = criterion;
    }
    
    public getType(): string {
        return 'AccessMediaFilterTemplate';
    }

    public buildDefaultFilter(): AccessMediaFilter {
        return new AccessMediaFilter(this.defaultValue, this);
    }

    public decode(str: string): AccessMediaFilter {
        const match = AbstractFilterTemplate.decodePattern.exec(str);
        if (match == null) { throw new Error('Impossible to decode with ' + str) ; }

        const key = match[1];
        const enabled = match[2] === '1';
        const value = match[3];

        if (this.name === key) {
            const textFilter = new AccessMediaFilter(value, this);
            textFilter.enabled = enabled;
            return textFilter;
        } else {
            throw new Error('Error');
        }
    }

    public getDefaultValue() {
        return this.defaultValue;
    }
}