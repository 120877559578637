<ng-container *ngIf="!loading && rows && rows.length > 0; else empty">
  <table class="table table-striped table-bordered dataTable no-footer" [ngClass]="{'jambo_table': dark}" attr.aria-label="{{ tableDescription }}">
    <thead>
      <tr *ngIf="bulkHeader && checked && checked.size > 0">
        <th id="subheader" title="checkbox"><ap-checkbox [checked]="(pagination && checked.size === pagination.totalElements) || (!pagination && checked.size === rows.length)" (select)="checkAllRows($event)"></ap-checkbox></th>
        <ng-container *ngTemplateOutlet="bulkHeader.templateRef; context: { $implicit: data }"></ng-container>
      </tr>
      <tr *ngIf="!bulkHeader || !checked || checked.size === 0">
        <th id="subheader" title="checkbox" *ngIf="!!bulkHeader"><ap-checkbox [checked]="(pagination && checked.size === pagination.totalElements) || (!pagination && checked.size === rows.length)" (select)="checkAllRows($event)"></ap-checkbox></th>
        <ng-container *ngFor="let tpl of headerTemplates">
          <ng-container *ngTemplateOutlet="tpl"></ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="subheaderTemplates">
        <td headers="subheader" *ngIf="!!bulkHeader"></td>
        <ng-container *ngFor="let col of headerTemplates; let index = index">
          <ng-container *ngIf="subheaderTemplates[index]" >
            <ng-container *ngTemplateOutlet="subheaderTemplates[index]"></ng-container>
          </ng-container>
          <td headers="subheader" *ngIf="!subheaderTemplates[index]"></td>
        </ng-container>
      </tr>
      <tr *ngFor="let element of rows">
        <td headers="subheader" *ngIf="!!bulkHeader">
          <ap-checkbox [checked]="checked.has(key ? element[key] : element)" (select)="selectRow(key ? element[key] : element, $event)"></ap-checkbox>
        </td>
        <ng-container *ngFor="let tpl of rowTemplates">
          <ng-container *ngTemplateOutlet="tpl; context: { $implicit: element, rows: rows }"></ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <ap-pagination *ngIf="pagination" (changeSize)="onChangeSize($event)" (selectedPage)="onChangePage($event)" [pagination]="pagination"></ap-pagination>
</ng-container>

<!-- Template for loading message -->
<ng-template #loadingData>
  <ap-jumbotron [icon]="'fa-spinner fa-spin'">{{ 'TABLE.LOADING' | translate }}</ap-jumbotron>
</ng-template>

<!-- Template for empty data message -->
<ng-template #noData>
  <ap-jumbotron [icon]="'fa-inbox'">{{ 'TABLE.EMPTY' | translate }}</ap-jumbotron>
</ng-template>

<ng-template #empty>
  <ng-container *ngIf="loading then loadingData; else noData"></ng-container>
</ng-template>
