import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MultipleSelectionFilterTemplate } from '../../filter-template/multiple-selection-filter-template/multiple-selection-filter-template.model';
import { MultipleSelectionFilter } from './multiple-selection-filter.model';

@Component({
  selector: 'ap-multiple-selection-filter-item',
  templateUrl: './multiple-selection-filter-item.component.html',
  styleUrls: ['./multiple-selection-filter-item.component.css']
})
export class MultipleSelectionFilterItemComponent implements OnInit {

  @Input() filter: MultipleSelectionFilter;

  label: string;
  orLabel: string;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    if (this.filter.value) {
      const template = <MultipleSelectionFilterTemplate> this.filter.filterTemplate;
      const options = template.options.filter(o => this.filter.value.includes(o.value));
      if (options) {
        this.orLabel = this.translateService.instant('FILTERS.ADD.OR');

        this.translateService.onLangChange.subscribe(_c => {
          this.getLabel(options);
        });

        this.getLabel(options);
      }
    }
  }

  getLabel(options: any): void {
    options.forEach((o,i) => {
      const translateLabel = this.translateService.instant(o.label);
      if (i === 0) {
        this.label = translateLabel;
      } else {
        this.label = this.label + this.orLabel + translateLabel;
      }
    });
  }
}
