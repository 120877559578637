import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { Page } from "../../commons/page";
import { RouteFilter } from "./model/filter/routeFilter";
import { Route } from "./model/route";

@Injectable({
    providedIn: 'root'
})

export class RouteService {
    private basePath = environment.admin.api.mobilityEngine + '/api/location-manager/v1/topology/routes';

    constructor(
        private httpClient: HttpClient
    ) {}

    public readRouteByFilters(routeFilter: RouteFilter): Observable<Page<Route>> {

        var params = new HttpParams();

        if (routeFilter.topologyReference) {
            params = params.set('topologyReference', routeFilter.topologyReference);
        }

        if (routeFilter.agencyId) {
            params = params.set('agencyId', routeFilter.agencyId);
        }

        if (routeFilter.routeTypes && routeFilter.routeTypes.length > 0) {
            params = params.set('routeTypes', routeFilter.routeTypes.toString());
        }

        if (routeFilter.shortName) {
            params = params.set('shortName', routeFilter.shortName);
        }

        if (routeFilter.longName) {
            params = params.set('longName', routeFilter.longName);
        }

        if (routeFilter.routeIds && routeFilter.routeIds.length > 0) {
            params = params.set('routeIds', routeFilter.routeIds.toString());
        }

        params = params.set('page', '0').set('size', '100000');

        return this.httpClient.get<Page<Route>>(this.basePath, {params});
    }

    public readRoutesByName(name: string, topologyReference: string): Observable<Page<Route>> {

        if (!topologyReference) {
            throw new Error('Parameter topologyReference is required in readRoutesByName method');
        }

        var params = new HttpParams()
        .set('topologyReference', topologyReference)
        .set('longName', name);

        return this.httpClient.get<Page<Route>>(this.basePath, {params});
    }
}
