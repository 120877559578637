import { CurrencyPipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { Page } from '@app/core/services/commons/page';
import { FinancialEngineService } from '@app/core/services/financial-engine/financial-engine.service';
import { SettlementStatusEnum } from '@app/core/services/financial-engine/models/enum/settlementStatus.enum';
import { SettlementFilter, SettlementSummary, SettlementSummaryUI } from '@app/core/services/financial-engine/models/settlementSummary';
import { CompanyPipe } from '@app/shared/pipes/company.pipe';
import { ExecutionModePipe } from '@app/shared/pipes/execution-mode.pipe';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { TruncateMaskedPanPipe } from '@app/shared/pipes/truncateMaskedPan.pipe';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { forkJoin, Observable } from 'rxjs';
import { first, map, pluck } from 'rxjs/operators';
import * as moment from 'moment';
import { CauseEnum } from '@app/core/services/financial-engine/models/enum/cause.enum';

@Component({
  selector: 'ap-export-settlement-data',
  template: `<button type="button" ap-tooltip="<kbd><kbd>alt</kbd> + <kbd>j</kbd></kbd>" [ap-btn-loading]="exporting" (click)="export()" class="btn btn-default" aria-label="Export data"><i class="fa fa-download" alt="Download"></i></button>`,
  providers: [LocalizedDatePipe, TruncateMaskedPanPipe, CompanyPipe, ExecutionModePipe, CurrencyPipe]
})
export class ExportSettlementDataComponent implements OnInit {

  exporting = false;
  MAX_ELEMENTS_PER_PAGE = 2000;
  companies = environment.admin.companies;
  statuses = [SettlementStatusEnum.EXECUTED, SettlementStatusEnum.FAILED, SettlementStatusEnum.SUBROGATED];
  params: ParamMap;

  constructor(private financialEngineService: FinancialEngineService, private adminService: AdministratorService, private route: ActivatedRoute, private translateService: TranslateService, private localizedDate: LocalizedDatePipe, private truncateMaskedPanPipe: TruncateMaskedPanPipe, private companyPipe: CompanyPipe, private executionModePipe: ExecutionModePipe, private currencyPipe: CurrencyPipe) { }

  /**
   * Export data with current filter with the keyboard shortcut alt + j
   *
   * @param e KeyboardEvent
   */
   @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.altKey && e.code === 'KeyJ') {
      this.export();
    }
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(p => {
      this.params = p;
    });
  }

  async export(): Promise<void> {
    this.exporting = true;

    const settlementFilter = this.getSettlementFilter();
    const element = this.params.get('sortElement') || 'paymentExecutionDate';
    const order = <'ASC'|'DESC'>(this.params.get('sortOrder') || 'DESC');
    const numberOfElement = (await this.findSettlementsCall(settlementFilter, order, element, 0, 1).toPromise()).totalElements;
    const headers = this.getHeaders();

    this.getSettlements(settlementFilter, element, order, numberOfElement).subscribe(settlementsPages => {
      let rows = [];

      for (let settlementsPage of settlementsPages) {
        let settlements = settlementsPage.content;
        if (settlements && settlements.length > 0) {
          let rowsTmp = settlements.map(settlement => {
            return [
              "'" + settlement?.reference + "'",
              settlement?.cause ? this.translateService.instant('PAYMENT_CASES.TABLE.DATA.CAUSE.' + settlement.cause) : '',
              settlement?.paymentExecutionDate ? this.localizedDate.transform(settlement.paymentExecutionDate, 'dd MMMM y') : '',
              settlement?.accessMedia?.accessMediaDescription ? this.truncateMaskedPanPipe.transform(settlement.accessMedia.accessMediaDescription, "#") : '',
              settlement?.userAccount,
              settlement?.serviceOperator ? this.companyPipe.transform(settlement.serviceOperator) : '',
              settlement?.status === SettlementStatusEnum.FAILED ? this.currencyPipe.transform(settlement.debtAmount ? settlement.debtAmount : 0, settlement.currency) : this.currencyPipe.transform(settlement.paidAmount ? settlement.paidAmount : 0, settlement.currency),
              settlement?.operations && settlement?.operations.length > 0 && settlement?.operations[0].externalTransactionReference ? "'" + settlement.operations[0].externalTransactionReference + "'" : '',
              settlement?.operations && settlement?.operations.length > 0 ? this.executionModePipe.transform(settlement.operations[0].executionMode) : '',
              this.getStatus(settlement?.status, settlement?.cause)
            ].join(';');
          });
          rows = rows.concat(rowsTmp);
        }
      }

      const BOM = '\uFEFF';
      var blob = new Blob([BOM + headers.join(';') + '\n' + rows.join('\n')], { type: 'text/csv;charset=utf16-le;' });
      const filename = `${moment().format('YYYYMMDD_HHmmss')}_${environment.admin.transactions.specifiedFileName}.csv`;
      saveAs(blob, filename);
      this.exporting = false;
    });
  }

  getSettlements(settlementFilter: SettlementFilter, element: string, order: string, numberOfElement: number): Observable<Page<SettlementSummaryUI>[]> {
    let calls = [];

    if (numberOfElement < this.MAX_ELEMENTS_PER_PAGE) {
      calls.push(this.findSettlementsCall(settlementFilter, order, element, 0, numberOfElement));
    } else {
      const maxPages = Math.floor(numberOfElement / this.MAX_ELEMENTS_PER_PAGE) + 1;

      for (let i=0; i < maxPages; i++) {
        calls.push(this.findSettlementsCall(settlementFilter, order, element, i, this.MAX_ELEMENTS_PER_PAGE));
      }
    }

    return forkJoin(calls);
  }

  private getSettlementFilter() {
    let settlementFilter: SettlementFilter;

    if (this.params.get('filter')) {
      settlementFilter = { ...this.financialEngineService.decodeFilter(this.params.get('filter')) };
    } else {
      settlementFilter = { statuses: this.statuses };
    }

    const companyReference = this.adminService.loggedAgent$.getValue().userDetails?.companyReference;
    // If agent is partitioned, we force the serviceOperator
    if (companyReference) {
      settlementFilter.serviceOperator = companyReference;
    }

    return settlementFilter;
  }

  private findSettlementsCall(settlementFilter: SettlementFilter, order: string, element: string, pageNumber: number, pageSize: number) {
    return this.financialEngineService.findSettlements(settlementFilter, order, element, pageNumber, pageSize)
    .pipe(
      first(),
      // Convert Settlement to SettlementUI
      map((pageSettlement: Page<SettlementSummary>) => {
        const settlementUIs = <SettlementSummaryUI[]> pageSettlement.content;
        return <Page<SettlementSummaryUI>>{
          content: settlementUIs,
          ...pageSettlement
        };
      }),
      map(settlementUIs => {
        settlementUIs.content.forEach(settlementUI => {
          if (settlementUI.accessMedia) {
            settlementUI.accessMedia.accessMediaHolder = {
              accessMediaHolderAccountId: settlementUI.accessMedia.accessMediaHolderAccountId?.toString(),
              accessMediaId: settlementUI.accessMedia.accessMediaId,
              accessMediaHolderLastName: settlementUI.accessMedia.accessMediaHolderLastName,
              accessMediaHolderFirstName: settlementUI.accessMedia.accessMediaHolderFirstName,
              accessMediaHolderEmailAddress: settlementUI.accessMedia.accessMediaHolderEmailAddress,
              accessMediaHolderEntityDescription: settlementUI.accessMedia.accessMediaHolderEntityDescription
            };
            if (settlementUI.accessMedia.accessMediaHolderEmailAddress) {
              const accessMediaHolder = settlementUI.accessMedia.accessMediaHolder;
              const firstName = accessMediaHolder.accessMediaHolderFirstName ? accessMediaHolder.accessMediaHolderFirstName : ''
              const lastName = accessMediaHolder.accessMediaHolderLastName ? accessMediaHolder.accessMediaHolderLastName : ''
              settlementUI.userAccount = accessMediaHolder ? accessMediaHolder.accessMediaHolderEmailAddress : '';
              if (firstName && lastName) {
                settlementUI.userAccount += " (" + firstName + ' ' + lastName + ")"
              } else if (firstName) {
                settlementUI.userAccount += " (" + firstName + ")";
              } else if (lastName) {
                settlementUI.userAccount += " (" + lastName + ")";
              }
            }
          }
        });
        return settlementUIs;
      })
    );
  }

  private getStatus(status: SettlementStatusEnum, cause: CauseEnum) {
    if (status) {
      if (cause === CauseEnum.REFUND) {
        return this.translateService.instant('STATUS.REFUND');
      }
      return this.translateService.instant('STATUS.' + status);
    }
    return '';
  }

  private getHeaders(): string[]  {
    let headers = [
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.CASE_REFERENCE'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.CAUSE'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.CASE_DATE'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.ACCESS_MEDIA'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.CUSTOMER_ACCOUNT'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.OPERATOR'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.TOTAL_AMOUNT'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.TRANSACTION_REFERENCE'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.EXECUTION_MODE'),
      this.translateService.instant('PAYMENT_CASES.TABLE.EXPORT.STATUS')
    ];

    return headers;
  }

}
