<ng-container [ngSwitch]="filter?.filterTemplate?.getType()">
  <ap-text-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'TextFilterTemplate'"></ap-text-filter-template>
  <ap-sips-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'SipsFilterTemplate'"></ap-sips-filter-template>
  <ap-selection-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'SelectionFilterTemplate'"></ap-selection-filter-template>
  <ap-date-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'DateFilterTemplate'"></ap-date-filter-template>
  <ap-autocomplete-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'AutocompleteFilterTemplate'"></ap-autocomplete-filter-template>
  <ap-datetime-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'DateTimeFilterTemplate'"></ap-datetime-filter-template>
  <ap-multiple-selection-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'MultipleSelectionFilterTemplate'"></ap-multiple-selection-filter-template>
  <ap-month-year-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'MonthYearFilterTemplate'"></ap-month-year-filter-template>
  <ap-access-media-filter-template (addFilter)="onAddFilter($event)" [filter]="filter" *ngSwitchCase="'AccessMediaFilterTemplate'"></ap-access-media-filter-template>
</ng-container>
