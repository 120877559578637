import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import cloneDeep from 'lodash.clonedeep';
import { FilterData } from '../../filter/filterData';
import { ColumnDirective } from '../table-ng.component';
import { TFiltersDirective } from './t-filters.directive';

@Component({
  selector: '[ap-t-filter-header]',
  template: `
  <ng-container *ngIf="filter">
    <ng-container [ngSwitch]="filter.filterTemplate.getType()">
      <ap-table-text-filter style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" [filter]="filter" *ngSwitchCase="'TextFilterTemplate'"></ap-table-text-filter>
      <ap-table-sips-filter style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" [filter]="filter" *ngSwitchCase="'SipsFilterTemplate'"></ap-table-sips-filter>
      <ap-table-autocomplete-filter-template style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" [filter]="filter" *ngSwitchCase="'AutocompleteFilterTemplate'"></ap-table-autocomplete-filter-template>
      <ap-table-select-filter style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" [filter]="filter" *ngSwitchCase="'SelectionFilterTemplate'" [noValueOption]="noValueOption"></ap-table-select-filter>
      <ap-table-multiple-select-filter style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" (resetFilters)="resetFilters($event)" [filter]="filter" *ngSwitchCase="'MultipleSelectionFilterTemplate'"></ap-table-multiple-select-filter>
      <ap-table-date-filter style="margin-bottom: 0px;line-height: 0;z-index: 0;" class="input-group input-group-sm" (save)="onEnter()" [filter]="filter" *ngSwitchCase="'DateFilterTemplate'"></ap-table-date-filter>
    </ng-container>
  </ng-container>`,
})
export class TableFilterHeaderComponent implements OnInit {

  @Input('ap-t-filter-header') name: string;

  @Input() noValueOption: boolean = true;

  @Input()
  get filterData(): FilterData { return this._filterData; }
  set filterData(filterData: FilterData) {
    this._filterData = filterData;
    this.createFilter();
  }
  _filterData: FilterData;

  filter;

  constructor(@Optional() public _filters: TFiltersDirective, @Inject('TABLE_COLUMN') @Optional() public _columnDef: ColumnDirective) { }

  private createFilter() {
    const filter = this._filterData.filters
      .find(f => f.filterTemplate.name === this.name && f.enabled);

    if (filter) {
      this.filter = cloneDeep(filter);
    } else {
        this._filterData.templates.filter(t => t.name === this.name)
          .forEach(template => this.filter = template.buildDefaultFilter());
    }
  }

  ngOnInit() {
    if (this._columnDef && !this.name) {
      this.name = this._columnDef.name;
    }

    if (this._filters) {
      this._filterData = this._filters.filterData;
    }

    this.createFilter();

    this._filterData.onChange$.subscribe(e => {
      this.createFilter();
    });
  }

  onEnter() {
    if (this.filter.value) {
      this._filterData.addOrUpdateFilter(this.filter);
    } else {
      const index = this._filterData.filters
        .findIndex(f => f.filterTemplate.name === this.name && f.enabled);
      if (index > -1) {
        this._filterData.removeFilter(index);
      }
    }
  }

  resetFilters(event){

    this._filterData.filters.forEach((value, index) => {
      if (value.filterTemplate.name === event) {
        this._filterData.removeFilter(index);
      }
    });

  }

}
