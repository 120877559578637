import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MultipleSelectionFilter } from '@app/shared/components/filter/filter-item/multiple-selection-filter-item/multiple-selection-filter.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ap-table-multiple-select-filter',
  templateUrl: './table-multiple-select-filter.component.html',
  styleUrls: ['./table-multiple-select-filter.component.css']
})
export class TableMultipleSelectFilterComponent implements OnInit {

  @Input() filter: MultipleSelectionFilter;
  @Output() save = new EventEmitter<MultipleSelectionFilter>();
  @Output() resetFilters = new EventEmitter<string>();

  value: string = '';
  filterForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.filterForm = this.initFormGroup();
  }

  ngOnInit() {
    if (this.filter.value && this.filter.value.length > 0) {
      this.value = this.filter.value[0];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filter.value.length === 1) {
      this.filterForm.get("value").setValue(this.filter.value[0]);
    } else {
       this.filterForm.get("value").setValue('');
    }
  }

  onChange() {
    if (this.filterForm.value.value) {
      this.filter.value = [this.filterForm.value.value];
      this.save.emit(this.filter);
    } else {
      this.resetFilter(this.filter.filterTemplate.name);
    }
  }

  private resetFilter(name){
    this.resetFilters.emit(name);
  }

  initFormGroup(){
    return this.formBuilder.group({
      value: ''
    });
  }
}
