import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ShowMessageModal {
  title?: string;
  message?: string;
  confirmButton?: string;
}

@Component({
  selector: 'show-message-modal',
  templateUrl: './show-message-modal.component.html',
  styleUrls: ['./show-message-modal.component.css']
})
export class ShowMessageModalComponent extends SimpleModalComponent<ShowMessageModal, boolean> {

  title: string;
  message: string;
  confirmButton: string;

  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

}
