<div class="modal-backdrop">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <p class="pre-wrap">{{ message }}</p>
            <form class="form-horizonal" [formGroup]="pinForm" autocomplete="off" (ngSubmit)="confirm()">
                <div class="form-group">
                    <label class="control-label col-sm-3 col-xs-12">Code pin :</label>
                    <div class="col-sm-7 col-xs-12">
                        <input type="text" id="pinCode" name="pinCode" class="form-control" formControlName="pinCode"/>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success" (click)="confirm()" attr.aria-label="{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.OK'| translate }}">{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.OK'| translate }}</button>
        </div>
      </div>
    </div>
</div>