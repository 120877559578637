<ng-container *ngIf="!loading && data && data.length > 0; else empty">
  <table class="table table-striped table-bordered dataTable no-footer" [ngClass]="{'jambo_table': dark}">
    <thead>
      <tr>
        <th *ngIf="grouped">
          <div (click)="checkAllRows()" class="icheckbox_flat-green" [ngClass]="{'checked': checkedAll}">
            <input type="checkbox" class="flat">
            <ins class="iCheck-helper"></ins>
          </div>
        </th>
        <ng-container *ngIf="!shouldShowBulkActionHeader">
          <ng-container *ngTemplateOutlet="lineHeader"></ng-container>
        </ng-container>
        <ng-container *ngIf="shouldShowBulkActionHeader">
          <ng-container *ngTemplateOutlet="lineBulkAction"></ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th *ngIf="grouped"></th>
        <ng-container *ngTemplateOutlet="lineFilter"></ng-container>
      </tr>
      <tr *ngFor="let element of data">
        <td *ngIf="grouped">
          <div (click)="selectRow(element)" class="icheckbox_flat-green" [ngClass]="{'checked': checked.get(element.poi)}">
            <input type="checkbox" class="flat">
            <ins class="iCheck-helper"></ins>
          </div>
        </td>
        <ng-container *ngTemplateOutlet="lineBody, context: { $implicit: element }"></ng-container>
      </tr>
    </tbody>
  </table>
  <ap-pagination *ngIf="pagination" (changeSize)="onChangeSize($event)" (selectedPage)="onChangePage($event)" [pagination]="pagination"></ap-pagination>
</ng-container>



<!-- Template for loading message -->
<ng-template #loadingData>
  <ap-jumbotron [icon]="'fa-spinner fa-spin'">{{ 'TABLE.LOADING' | translate }}</ap-jumbotron>
</ng-template>

<!-- Template for empty data message -->
<ng-template #noData>
  <ap-jumbotron [icon]="'fa-inbox'">{{ 'TABLE.EMPTY' | translate }}</ap-jumbotron>
</ng-template>

<ng-template #empty>
  <ng-container *ngIf="loading then loadingData; else noData"></ng-container>
</ng-template>
