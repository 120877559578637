import { Input, Directive, EventEmitter, Output } from '@angular/core';
import { Sort } from '../table/sort/sort.model';
import { SortHeaderComponent } from './sort/sort-header.component';

@Directive({
    selector: '[ap-sorts]'
})
export class SortsDirective {

    @Input()
    public active: string;

    @Input()
    public direction: 'ASC' | 'DESC' = 'ASC';

    @Output()
    public sortChange = new EventEmitter<Sort>();

    constructor() {

    }

    sort(sortComponent: SortHeaderComponent) {
        this.active = sortComponent.name;
        this.direction = sortComponent.sort.order;
        this.sortChange.emit(sortComponent.sort);
    }

}
