import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ErrorModel {
  title?: string;
  message?: string;
  buttonToTls?: boolean
}

@Component({
  selector: 'ap-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent extends SimpleModalComponent<ErrorModel, boolean> implements ErrorModel {

  title: string;
  message: string;
  buttonToTls?: boolean;

  wsAddress: string = '127.0.0.1:8082';

  constructor() { 
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

  enableTls() {
    window.open(`https://${this.wsAddress}`, '_blank');
  }
}