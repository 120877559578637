<li>
  <div>
    <div (click)="!disabled ? selectItem() : ''" class="icheckbox_flat-green" [ngClass]="{'checked': checked}" style="position: relative; float: left; ">
      <input type="checkbox" class="flat" style="position: absolute; opacity: 0;">
      <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 0px none; opacity: 0;"></ins>
    </div>
    <ng-content select="[ap-checklist-badge]"></ng-content>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</li>
