import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { PaireLinkModalComponent } from '../modals/paire-link-modal/paire-link-modal.component';
import CryptoJS from 'crypto-js';
import { Store } from '@ngrx/store';
import { AccessMediaReaderState } from './access-media-reader-store/access-media-reader.state';
import { setMRandomNumberRequest, setSecretRequest, setSerialNumberRequest } from './access-media-reader-store/access-media-reader.action';
import { selectAccessMediaReaderState } from './access-media-reader-store/access-media-reader.selector';
import { AccessMediaFilter } from '../filter/filter-item/access-media-filter-item/access-media-filter.model';
import { AccessMediaFilterTemplate } from '../filter/filter-template/access-media-filter-template/access-media-filter-template.model';
import cloneDeep from 'lodash.clonedeep';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { WaitingModalComponent } from '../modals/waiting-modal/waiting-modal.component';
import { ErrorModalComponent } from '../modals/error-modal/error-modal.component';
import { AccessMediaREaderStatusEnum } from '@app/core/services/device/models/enum/accessMediaReaderStatus.enum';

@Component({
  selector: 'ap-access-media-reader',
  templateUrl: './access-media-reader.component.html'
})
export class AccessMediaReaderComponent extends AbstractOnDestroyComponent implements OnInit {


  @Output() addFilter = new EventEmitter<AccessMediaFilter>();

  @Input()
  get filter(): AccessMediaFilter { return this._filter; }
  set filter(filter: AccessMediaFilter) {
    this._filter = filter;
  }

  constructor(
    private simpleModalService: SimpleModalService,
    private translate: TranslateService,
    private store: Store<AccessMediaReaderState>,
    private toast: ToastrService
  ) {
    super();
   }

  connexion: WebSocket;
  wsAddress: string = environment.admin.accessMediaReader.connexion.wsAddress;
  isWss: boolean = environment.admin.accessMediaReader.connexion.isWss;
  byodId: string = 'webByod';
  flowId: number = 0;

  hmacTagRequest: number = 2677379073;
  sha256TagRequest: number = 2677379072;
  encPanTagRaquest: number = 2677311835;

  codeErrorAuth: number = -1000101;
  codeErrorDoubleAuth: number = -1000144;
  accessMediaReaderStatus: AccessMediaREaderStatusEnum = AccessMediaREaderStatusEnum.WARNING;


  // Define variables to connect
  secret: string;
  serialNumber: string;
  mRandomNumber: string;
  _filter: AccessMediaFilter;
  lastMessageSend: string;

  ngOnInit() {
  }

  /**
   * Method called on button click
   * It will open theWS connexion if it isn't connected
   */
  readAccessMedia() {
    // Create a new connexion if the connexion is undefined or is in closing state
    if (typeof this.connexion === 'undefined' || this.connexion.readyState !== 1) {
      this.connexion = new WebSocket(`${this.isWss ? 'wss://' : 'ws://'}/${this.wsAddress}`);

      // Proc connexion open event
      this.connexion.onopen = () => {
        this.sendTransactionRequest();
      }

      // Proc close connexion event
      this.connexion.onclose = () => {

      }

      this.connexion.onerror = (error) => {
        const options = {
          title: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.TITLES.ERROR_CONNEXION'),
          message: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.MESSAGES.ERROR_CONNEXION'),
          buttonToTls: true
        }
        this.simpleModalService.addModal(ErrorModalComponent, options).subscribe(isConfirm => {
          if (isConfirm) {
              this.connexion = new WebSocket(`${this.isWss ? 'wss://' : 'ws://'}/${this.wsAddress}`);
              this.simpleModalService.removeAll();
          }
        });
      }

      // Proc message connexion event
      this.connexion.onmessage = (message) => {
        this.messageReceived(message);
      }
    } else {
      this.sendTransactionRequest();
    }
  }


  /**
   * Method called to send a transaction request to the link2500
   */
  sendTransactionRequest() {
    let message = {
      msgName: "transaction.startRequest",
      callerId: "123",
      version: "1.0",
      content: {
        amount: 0,
        currency: 978,
        paymentMeans: 4,
        transactionId: "12345",
        transactionType: 5636098,
        listTxnReqTags: [this.hmacTagRequest,this.sha256TagRequest,this.encPanTagRaquest]
      }
  };

    this.sendMessage(message);
  }


  /**
   * Method to send a message on the websocket connexion
   * @param message
   */
  sendMessage (message) {
    this.flowId ++;
    message.flowId = this.flowId;
    if (this.lastMessageSend !== message.msgName) {
      this.connexion.send(JSON.stringify(message));
      this.lastMessageSend = message.msgName;
    }
  }

  messageReceived(message) {
    if (message.data) {
      let data = JSON.parse(message.data);
      if (data?.content?.error) {
        this.manageError(data);
      } else {
        this.manageMessage(data);
      }
    }
  }

  manageError(message) {
    this.simpleModalService.removeAll();
    if (message?.content?.error === this.codeErrorAuth) {
      if (message.msgName === 'byodAuthentication.getRandomResponse') {
        this.sendPinCode();
      } else {
        this.getRandomRequest()
      }
    } else if (message?.content?.error === this.codeErrorDoubleAuth) {
      this.sendPinCode();
    }
  }

  manageMessage(message) {
    switch (message.msgName) {
      case 'byodAuthentication.sendPinCodeResponse':
        this.store.dispatch(setSecretRequest({secret: message.content.secret}));
        this.store.dispatch(setSerialNumberRequest({serialNumber: message.content.serialNumber}))
        this.getRandomRequest();
      break;
      case 'byodAuthentication.getRandomResponse':
        this.store.dispatch(setMRandomNumberRequest({mRandomNumber: message.content.randomNumber}))
        this.buildJWT();
      break;
      case 'byodAuthentication.validateAuthentResponse':
        this.sendTransactionRequest();
      break;
      case 'transaction.customProcessingRequest':
        this.getAccessMediaTokens(message.content);
      break;
      case 'transaction.startResponse':
        this.transactionStarted();
      break;
      case 'transaction.doneEvent':
        this.doneEvent();
      break;
    }
  }

  transactionStarted() {
    const options = {
      title: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.TITLES.ACCESS_MEDIA'),
      message: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.MESSAGES.READ_ACCESS_MEDIA')
    }

    this.simpleModalService.addModal(WaitingModalComponent, options).subscribe((response) => {
      if (!response) {
        this.accessMediaReaderStatus = AccessMediaREaderStatusEnum.CANCELED;
        this.toast.warning(this.translate.instant('ACCESS_MEDIA.TOAST.WARNING.ACCESS_MEDIA_READ_CANCELLED'));
        this.stopReading();
      }
    });
  }

  sendPinCode() {
    this.simpleModalService.removeAll();

    // Prepare modal options
    const options = {
        title: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.TITLES.PAIRE_PIN'),
        message: this.translate.instant('ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.MESSAGES.PAIRE_PIN'),
        type: "pin"
    }

    this.simpleModalService.addModal(PaireLinkModalComponent, options).subscribe((response) => {
        // Prepare webSocket message
        let message = {
          msgName: "byodAuthentication.sendPinCodeRequest",
          content: {
            pinCode: response,
            byodId: this.byodId
          },
          callerId: "123",
          version: "1.0"

        }
        this.sendMessage(message);
      });
  }

  getRandomRequest() {
    let message = {
        msgName: "byodAuthentication.getRandomRequest",
        callerId: "123",
        flowId: this.flowId,
        version: "1.0"
    }
    this.sendMessage(message);
  }

  buildJWT() {
    let header = this.getBase64Encode(CryptoJS.enc.Utf8.parse(`{"alg":"HS256","typ":"JWT"}`));

    this.store.select(selectAccessMediaReaderState).subscribe(result => {
      if (result && result.secret && result.serialNumber && result.mRandomNumber) {
        let secureKey = CryptoJS.enc.Base64.parse(result.secret);
        let payload = this.getBase64Encode(CryptoJS.enc.Utf8.parse("{\"serial\":\""+ result.serialNumber +"\",\"randomNumber\":" + result.mRandomNumber +",\"byodId\":\""+ this.byodId +"\"}"));
        let signature = this.getBase64Encode(CryptoJS.HmacSHA256(header + "." + payload, secureKey));
        let jwt = header + "." + payload + "." + signature;

        let message = {
          callerId: "123",
          msgName: "byodAuthentication.validateAuthentRequest",
          content: {
            token: jwt
          },
          version: "1.0"
        }
        this.sendMessage(message);
      }
    })
  }

  getBase64Encode(rawData) {
    let res = CryptoJS.enc.Base64.stringify(rawData);
    res = res.replace(/=+$/, '')
    res = res.replace(/\+/g, '-')
    res = res.replace(/\//g, '_')
    return res;
  }

  getAccessMediaTokens(data) {
    let token = "";
    switch(environment.admin.accessMediaReader.token) {
      case "HMAC":
        if (data.txnCustomProc.find(element => element.tag === this.hmacTagRequest)) {
          this.accessMediaReaderStatus = AccessMediaREaderStatusEnum.DONE;
          token = atob(data.txnCustomProc.find(element => element.tag === this.hmacTagRequest).value);
        } else {
          this.accessMediaReaderStatus = AccessMediaREaderStatusEnum.ERROR;
        }
        break;
      case "SHA256":
        if (data.txnCustomProc.find(element => element.tag === this.sha256TagRequest)) {
          this.accessMediaReaderStatus = AccessMediaREaderStatusEnum.DONE;
          token = atob(data.txnCustomProc.find(element => element.tag === this.sha256TagRequest).value)
        } else {
          this.accessMediaReaderStatus = AccessMediaREaderStatusEnum.ERROR;
        }
        break;
      default:
        throw new Error ("Invalid token configuration");
    }
    if (AccessMediaREaderStatusEnum.DONE === this.accessMediaReaderStatus) {
      token = token.split("")
      .map((v) => ("0" + v.charCodeAt(0).toString(16).toUpperCase()).slice(-2))
      .join("");
      this.toast.success(this.translate.instant('ACCESS_MEDIA.TOAST.SUCCESS.ACCESS_MEDIA_READ'));
      this.stopReading();
      this._filter.value = token;
      this._filter = new AccessMediaFilter(token, new AccessMediaFilterTemplate('accessMediaTokens', 'ACCESS_MEDIA.LIST.FILTER.ACCESS_MEDIA.ACCESS_MEDIA','ACCESS_MEDIA.LIST.FILTER.ACCESS_MEDIA.GROUP', 'EQUALS'));
      this.addFilter.emit(cloneDeep(this._filter));
    } else {
      this.toast.error(this.translate.instant('ACCESS_MEDIA.TOAST.ERROR.BAD_TOKEN'));
      this.stopReading();
    }
  }

  ngOnDestroy() {
    if (typeof this.connexion !== 'undefined') {
      this.connexion.close();
    }
  }

  doneEvent() {
    this.simpleModalService.removeAll();
    if (this.accessMediaReaderStatus === AccessMediaREaderStatusEnum.WARNING) {
      this.toast.warning(this.translate.instant('ACCESS_MEDIA.TOAST.WARNING.NO_ACCESS_MEDIA_READ'));
    }
  }

  stopReading() {
    const msg = {
      msgName: "transaction.customProcessingResponse",
      callerId: "123",
      version: "1.0",
      content: {
        result: 0,
        error: 0
      }
    }
    this.sendMessage(msg);
    this.doneEvent();
  }

}
