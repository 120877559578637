<div class="left_col scroll-view">
    <div class="navbar nav_title site_title border-none">
      <img class="img-height" src="assets/images/logo-admin.png" alt="admin logo"/> <span class="title-padding">{{ 'MENU.PORTAIL' | translate }}</span>
    </div>

    <div class="clearfix" role="separator" aria-label="menu separator"></div>

    <!-- menu profile quick info -->
    <div class="clearfix" role="navigation" aria-label="Menu profile quick info">
      <div class="profile_pic" role="link" aria-label="profile logo" [ngClass]="{ 'nav-toggle-ms': !isToggle, 'nav-toggle-md': isToggle }">
        <a routerLink="/home"><img src="assets/images/img.jpg" alt="profile logo" class="img-circle profile_img"></a>
      </div>
      <div class="profile" role="navigation" aria-label="Profile role and agent name container">
        <div class="profile_info" *ngIf="(loggedAgent$ | async) as loggedAgent">
          <a routerLink="/home"><span>{{ loggedAgent.role?.name | concat:'ADMINISTRATOR.ROLES.' | translate }}</span></a>
          <a routerLink="/home"><h2>{{ loggedAgent.userDetails?.userFirstName }} {{ loggedAgent.userDetails?.userLastName }}</h2></a>
        </div>
      </div>
    </div>
    <!-- /menu profile quick info -->

    <br />

    <!-- sidebar menu -->
    <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
        <div class="menu_section" role="navigation" aria-label="Menu">
            <h3>{{ 'MENU.TITLE' | translate }}</h3>
            <ul ap-side-menu>
              <!-- Users -->
              <li *apSecure="'ACCESS_ALL_ACCOUNTS'" ap-side-menu-item excludeRule="/users/.+" linkTo="/users"><i class="fa fa-users"></i>{{ 'MENU.USERS.MANAGE' | translate }}</li>
              <!-- AccessMedias -->
              <li *apSecure="'ACCESS_ALL_ACCESS_MEDIA'" ap-side-menu-item linkTo="/accessmedias"><i class="fa fa-credit-card"></i> {{ 'MENU.ACCESS_MEDIA.TITLE' | translate }}</li>
              <!-- Refund & PaymentCases -->
              <li *ngIf="isPaymentCasesEnabled && hasPermissionForPaymentCases" ap-side-menu-item [exact]="true" linkTo="/users/payment-cases"><i class="fa fa-money"></i>{{ 'MENU.TRANSACTIONS.PAYMENT_CASES.TITLE' | translate }}</li>
              <li *ngIf="isRefundModeDeffered && isAuthorizedRefundRole && hasPermissionForRefund" ap-side-menu-item [exact]="true" linkTo="/users/refunds"><i class="fa fa-fw icon-refund-transaction"><img src="/assets/images/refund_menu.svg" [ngClass]="{ 'small': !isToggle }"/></i>{{ 'MENU.TRANSACTIONS.REFUND.TITLE' | translate }}
                <span class="badge pull-right" title="{{ 'REFUND.STATUS.TOOLTIP' | translate }}">{{ financialEngineService.refundInitiatedCounter$ | async }}</span>
              </li>

              <!-- Customer Requests -->
              <ng-container *ngIf="customerRequestEnabled">
                <li ap-side-submenu *apSecure="'MANAGE_SAV_TICKETS'" >
                  <i class="fa fa-comments-o"></i>{{ 'MENU.SAV.TITLE' | translate }}
                  <span class="badge pull-right">{{ customerRequestService.requestCustomerCounter$ | async }}</span>
                  <ul ap-side-submenu-items>
                    <li ap-side-menu-item [exact]="true" linkTo="/customerrequests">{{ 'MENU.SAV.ALL' | translate }}</li>
                    <li ap-side-menu-item [exact]="true" linkTo="/customerrequests" [queryParams]="{ filter: 'adminReference:1:' + userId }">{{ 'MENU.SAV.MY' | translate }}</li>
                  </ul>
                </li>
              </ng-container>
              <!-- Statitics -->
              <li *apSecure="'ACCESS_STATISTICS,ACCESS_MYCOMPANY_STATISTICS'" ap-side-menu-item linkTo="/statistic"><i class="fa fa-bar-chart"></i> {{ 'MENU.STATISTIC.TITLE' | translate }}</li>
              <!-- Devices -->
              <li *apSecure="'ACCESS_TERMINAL_INFO,CONFIGURE_TERMINAL,CONFIGURE_MYCOMPANY_TERMINAL'" ap-side-submenu>
                <i class="fa fa-cog"></i> {{ 'MENU.DEVICES.TITLE' | translate }}
                <ul ap-side-submenu-items>
                  <li ap-side-menu-item [exact]="true" linkTo="/devices">{{ 'MENU.DEVICES.LIST' | translate }}</li>
                  <li *ngIf="alarmStatsEnabled" ap-side-menu-item linkTo="/devices/supervision">{{ 'MENU.DEVICES.SUPERVISION' | translate }}</li>
                  <li *apSecure="'MANAGE_ALARM'" ap-side-menu-item linkTo="/devices/alarms">{{ 'MENU.DEVICES.ALARMS' | translate }}</li>
                </ul>
              </li>
              <!-- Tarrifs -->
              <li *apSecure="'ACCESS_ALL_TARIFFS'" ap-side-menu-item linkTo="/tariffication"><i class="fa fa-ticket"></i> {{ 'MENU.TARIFFICATION.TITLE' | translate }}</li>
              <!-- Admin management -->
              <li *apSecure="'ACCESS_ALL_USERS,ACCESS_MYCOMPANY_USERS'" ap-side-submenu>
                <i class="fa fa-shield"></i> {{ 'MENU.ADMINISTRATORS.TITLE' | translate }}
                <ul ap-side-submenu-items>
                  <li ap-side-menu-item linkTo="/administrators/registration">{{ 'MENU.ADMINISTRATORS.ENLIST' | translate }}</li>
                  <li ap-side-menu-item [exact]="true" linkTo="/administrators">{{ 'MENU.ADMINISTRATORS.MANAGE' | translate }}</li>
                </ul>
              </li>
              <!-- Administration tools -->
              <li *apSecure="'ACCESS_INTERNAL_TOOL';roles:'ROOT_ADMIN'" ap-side-submenu>
                <i class="fa fa-tasks"></i>{{ 'MENU.ADMIN_TOOLS.TITLE' | translate }}
                <ul ap-side-submenu-items>
                  <li ap-side-menu-item linkTo="/tools/tap">{{ 'MENU.ADMIN_TOOLS.SIMULATE_TAP' | translate }}</li>
                  <li ap-side-menu-item linkTo="/tools/sacc-gateway-simulator">{{ 'MENU.ADMIN_TOOLS.SACC_GATEWAY_SIMULATOR' | translate }}</li>
                  <li ap-side-menu-item linkTo="/tools/simulate-external-enrollment">{{ 'MENU.ADMIN_TOOLS.EXTERNAL_ENROLLMENT' | translate }}</li>
                </ul>
              </li>
            </ul>
        </div>
    </div>
    <!-- /sidebar menu -->

    <!-- /menu footer buttons -->
</div>
