import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FilterData } from '../filterData';
import { Filter } from '@app/core/services/filter/model/filter.model';
import { FilterService } from '@app/core/services/filter/filter.service';
import { environment } from '@env/environment';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyPipe } from '@app/shared/pipes/company.pipe';

@Component({
  selector: 'ap-filter-save',
  templateUrl: './filter-save.component.html',
  styleUrls: ['./filter-save.component.css'],
  providers: [CompanyPipe]
})
export class FilterSaveComponent extends AbstractOnDestroyComponent implements OnInit {

  @Input() filterData: FilterData;

  @Output() save = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  loading = false;

  saveForm: UntypedFormGroup;
  sharedControl = new UntypedFormControl(false);

  companies = Object.keys(environment.admin.companies).map(v => ({ key: v, label: this.companyPipe.transform(v) }));
  companyRoles: string[] = [];

  get company() { return this.saveForm.get('company'); }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private filterService: FilterService,
    private translateService: TranslateService,
    private administratorService: AdministratorService,
    private companyPipe: CompanyPipe
  ) {
    super();
  }

  ngOnInit() {
    this.companyRoles = this.administratorService.companyRoles;
    const myProfil = this.administratorService.loggedAgent$.getValue();

    this.saveForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: new UntypedFormControl(this.companyRoles.includes(myProfil.role.name) ? myProfil.userDetails.companyReference : '')
    }, this.companyRequired());

    if (this.companyRoles.includes(myProfil.role.name)) {
      this.company.disable();
    }

  }

  /**
   * To save a new filter for this logged user into the localstorage.
   */
  onSave() {
    if (this.saveForm.valid) {
      this.loading = true;
      const filter = Filter.parse(this.filterData.name, this.saveForm.value.name, this.filterData.encode(), this.sharedControl.value);

      filter.companyReference = this.company.value ? this.company.value : null;

      this.filterService.saveFilter(filter).pipe().subscribe(() => {
          this.saveForm.reset();
          this.loading = false;
          const notifyMsg = this.translateService.instant('FILTERS.SAVE.TOAST.SUCCESS');
          this.toastr.success(notifyMsg);
          this.save.emit();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 409) {
            if (filter.shared) {
              this.toastr.error(this.translateService.instant('FILTERS.SAVE.TOAST.ERROR.SHARED'));
            } else {
              this.toastr.error(this.translateService.instant('FILTERS.SAVE.TOAST.ERROR.PERSONAL'));
            }
          } else if (error.status === 403) {
            this.toastr.error(this.translateService.instant('ERROR_PAGE.ACCESS_FORBIDDEN'));
          } else {
            this.toastr.error(this.translateService.instant('FILTERS.SAVE.TOAST.ERROR.TECHNICAL'));
          }
          this.loading = false;
        });
    }
  }

  onCancel() {
    this.close.emit();
  }

  private companyRequired(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const isValid = !this.companyRoles.includes(control.value.role) || (this.companyRoles.includes(control.value.role) && control.value.company);
      return !isValid ? { companyRequired: {value: control.value}} : null;
    };
  }

}
