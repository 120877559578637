import { Directive, Input, ViewContainerRef, TemplateRef, SimpleChanges, Renderer2, OnChanges } from '@angular/core';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { PermissionEnum } from '@app/core/services/administrator/models/enum/permission';
import { UserAdmin } from '@app/core/services/administrator/models/userAdmin';
import { BehaviorSubject } from 'rxjs';

/**
 * Allow to display the content if user roles and permissions match with expected roles and permissions in parameters
 * (roles and permissions as string splited by comma or string array).
 * We have to have at least one permission.
 *
 * @example
 * <pre><code>
 *  <div *apSecure="'PERMISSION1, PERMISSION2';roles:'ROLE1, ROLE2';mode:'DISABLED'"></div>
 * </code></pre>
 */
@Directive({ selector: '[apSecure]' })
export class ApSecureDirective implements OnChanges {

  @Input('apSecureMode')
  mode: 'DISABLED' | 'HIDDEN' = 'HIDDEN';

  @Input('apSecure')
  permissions: string | string[] = [];

  @Input('apSecureRoles')
  roles: string | string[] = [];

  loggedAgent$: BehaviorSubject<UserAdmin>;
  hasRights = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private administratorService: AdministratorService) { }

  ngOnChanges(_changes: SimpleChanges): void {
    this.manageApSecure();
  }

  /**
   * Manage roles and permissions and apply mode to the DOM element
   */
  private manageApSecure() {
    this.loggedAgent$ = this.administratorService.loggedAgent$;

    if ( !this.loggedAgent$ ) {
      this.hasRights = false;
    }

    const roles = typeof this.roles === 'string' ? this.roles.split(',') : this.roles;

    let permissions = undefined;
    if (this.permissions) {
      permissions = typeof this.permissions === 'string' ? this.permissions.split(',') : this.permissions;
    }

    // Check permissions

    if (permissions && !permissions.some(p => p && this.loggedAgent$.getValue().role.permissions.includes(<PermissionEnum>p))) {
      this.hasRights = false;
    }
    // Check roles if its required
    if (roles && roles.length > 0 && !roles.includes(this.administratorService.loggedAgent$.getValue().role.name)) {
      this.hasRights = false;
    }

    if (this.hasRights) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.applyMode();
    }

  }

  private applyMode() {
    if (this.mode === 'DISABLED') {
      const view = this.viewContainer.createEmbeddedView(this.templateRef);
      const rootElem = view.rootNodes[0];
      if (rootElem) {
        this.renderer.setProperty(rootElem, 'disabled', true);
      }
    } else {
      this.viewContainer.clear();
    }
  }

}
