import { ActionReducer, createReducer, INIT, on, UPDATE } from "@ngrx/store";
import { AccessMediaReaderState } from "./access-media-reader.state";
import { clearMRandomNumberRequest, clearSecretRequest, clearSerialNumberRequest, setMRandomNumberRequest, setSecretRequest, setSerialNumberRequest } from "./access-media-reader.action";

export const initAccessMediaReaderState: AccessMediaReaderState = null;
export const editAccessMediaReaderStateReducer = createReducer(
    initAccessMediaReaderState,
    /* Secret */
    on(clearSecretRequest, (state, _) => {
        return Object.assign({}, state, { secret: ""});
    }),
    on(setSecretRequest, (state, action) => {
        return Object.assign({}, state, { secret: action.secret});
    }),
    
    /* Serial Number */
    on(clearSerialNumberRequest, (state, _) => {
        return Object.assign({}, state, { serialNumber: ""});
    }),
    on(setSerialNumberRequest, (state, action) => {
        return Object.assign({}, state, { serialNumber: action.serialNumber});
    }),

    /* mRandomNumber */
    on(clearMRandomNumberRequest, (state, _) => {
        return Object.assign({}, state, { mRandomNumber: ""});
    }),
    on(setMRandomNumberRequest, (state, action) => {
        return Object.assign({}, state, { mRandomNumber: action.mRandomNumber});
    }),
);

export const metaReducerAccessMediaReaderStorage = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
          const storageValue = localStorage.getItem("AccessMediaState");
          if (storageValue) {
            try {
              return JSON.parse(storageValue);
            } catch {
              localStorage.removeItem("AccessMediaState");
            }
          }
        }
        const nextState = reducer(state, action);
        localStorage.setItem("AccessMediaState", JSON.stringify(nextState));
        return nextState;
      };
}