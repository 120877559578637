import { Component, Input, OnInit } from '@angular/core';
import { DateFilter } from './date-filter.model';

@Component({
  selector: 'ap-date-filter-item',
  templateUrl: './date-filter-item.component.html'
})
export class DateFilterItemComponent implements OnInit {

  @Input() filter: DateFilter;

  constructor() { }

  ngOnInit() {

  }

}
