import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractOnDestroyComponent } from '@app/core/abstract-on-destroy-component/abstract-on-destroy-component';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ap-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent extends AbstractOnDestroyComponent implements OnChanges {

  searching: string;
  state: 'INIT' | 'LOADING' | 'EMPTY' | 'DONE' = 'INIT';
  options: Entry<any, string>[];

  @Input() value: Entry<string, any>;
  @Input() minSearch = 3;
  @Input() placeholder = '';
  @Input() readonly = true;
  @Input() maxlength = null;
  @Input() description: string = '';

  @Input()
  get autocomplete(): (search: string) => Observable<Map<string, any>> { return this._autocomplete; }
  set autocomplete(autocomplete: (search: string) => Observable<Map<string, any>>) {
    // Reset component when autocomplete function change
    this._autocomplete = autocomplete;
    this.searching = null;
    this.options = [];
    this.state = 'INIT';
    this.computeState();
  }
  _autocomplete: (search: string) => Observable<Map<string, any>>;

  @Output() choice = new EventEmitter<Entry<string, any>>();
  @Output() clear = new EventEmitter<void>();


  private changeEvent = new Subject<string>();
  private loading = false;

  constructor() {
    super();
    this.changeEvent.pipe(
      takeUntil(this.unsubscribe),
      debounceTime(1000),
      switchMap(search => {
        return this._autocomplete(search);
      }),
      map(options => {
        return Array.from(options.entries()).map(option => {
          return new Entry<string, string>(option[0], option[1]);
        });
      }),
    ).subscribe(entries => {
      this.options = entries;
      this.loading = false;
      this.computeState();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Automatically trigger a search if there is no characters threshold
    if (!this.value) {
      this.onSearch('');
    }
  }

  onSearch(str: string): void {
    this.searching = str;
    this.loading = true;
    this.computeState();
    if (str?.length >= this.minSearch) {
      this.changeEvent.next(str);
    }
  }

  onClear() {
    this.value = null;
    this.clear.emit();
  }

  onSelect(entry: Entry<string, string>) {
    this.value = entry;
    this.choice.emit(entry);
  }

  onManualEntry(event) {
    // Handle manual entry, that are not in the topological reference
    if (!this.readonly) {
      this.value = new Entry<string, string>(event.target.value, event.target.value);
      this.choice.emit(this.value);
    }
  }

  private computeState() {
    if (this.searching === null || this.searching?.length < this.minSearch) {
      return this.state = 'INIT';
    }
    if (this.loading) {
      return this.state = 'LOADING';
    }
    if (this.options && this.options.length > 0) {
      return this.state = 'DONE';
    }
    this.state = 'EMPTY';
  }
}

export class Entry<K, V> {
  key: K;
  value: V;

  constructor(key: K, value: V) {
    this.key = key;
    this.value = value;
  }

}
