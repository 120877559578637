import { Component, Input, OnInit } from '@angular/core';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { UserAdmin } from '@app/core/services/administrator/models/userAdmin';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'ap-admin-name',
  templateUrl: './admin-name.component.html'
})
export class AdminNameComponent implements OnInit {

  @Input()
  reference: string;

  @Input()
  pattern = 'FIRSTNAME LASTNAME (ROLE)';

  admin$: Observable<string>;
  error: any;

  constructor (
    private administratorService: AdministratorService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.admin$ = this.administratorService.getCachedUser(this.reference).pipe(
      catchError((error) => {
        console.error('error loading the useradmin', error);
        this.error = error;
        return of();
      }),
      map((user: UserAdmin) => {
        return this.pattern
          .replace('FIRSTNAME', user.userDetails.userFirstName)
          .replace('LASTNAME', user.userDetails.userLastName)
          .replace('EMAIL', user.userDetails.userEmailAddress)
          .replace('ROLE', this.translate.instant('ADMINISTRATOR.ROLES.' + user.role.name));
      })
    );
  }

}
