import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'truncateMaskedPan'})
export class TruncateMaskedPanPipe implements PipeTransform {


  /**
   *return the last four characters of the pan concatenate with #
   */
  transform(maskedPan: string, prefix: string = '#'): string {
    let truncatedMaskedPan = '';
    if (maskedPan) {
      truncatedMaskedPan = prefix + maskedPan.slice(maskedPan.length - 4);
    }
    return truncatedMaskedPan;
  }
}

