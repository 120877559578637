import { Component, Input, OnInit } from '@angular/core';
import { ApplicationPeriod } from '@app/core/services/tariffication/models/application-period.model';

@Component({
  selector: 'ap-period-bar',
  templateUrl: './period-bar.component.html',
  styleUrls: ['./period-bar.component.css']
})
export class PeriodBarComponent implements OnInit {

  @Input() values: ApplicationPeriod[];

  constructor() { }

  ngOnInit(): void {
  }

  getLastValue() {
    if (this.values && this.values.length > 0) {
      return this.values[this.values.length - 1];
    }
  }
}
