<div class="vertical-align-container">
  <div class="form-control">
    <ul #list>
      <ng-container *ngIf="currentValue | async as values">
        <li style="display: flex;" *ngFor="let label of labels">
          <div class="choice">
            <span class="choice-label">{{ label }}</span>
            <span class="choice-remove"><i class="fa fa-times" (click)="removeChoice(label)" aria-hidden="true"></i></span>
          </div>
        </li>
      </ng-container>

      <li style="flex: 1;" #input>
        <input type="text" class="search-field" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="{{ 'SHARED.COMPONENT.SELECT.SEARCH' | translate }}" autocomplete="off" (focus)="enableEdition()"/>
      </li>
    </ul>
  </div>
  <div *ngIf="edition" class="panel panel-default edition shadow" [style.margin-left.px]="margin">
    <div class="panel-body">
      <!--<div class="caret"></div>-->

      <ng-content select="ap-option,ap-optoption,ap-option-info"></ng-content>

      <div class="text-center" *ngIf="isEmpty">{{ 'SHARED.COMPONENT.SELECT.EMPTY' | translate }}</div>
    </div>
  </div>
</div>
