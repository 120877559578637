import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'li[ap-side-menu-item]',
  template: '<a [routerLink]="linkTo" [queryParams]="params"><ng-content></ng-content></a>',
  styles: []
})
export class SideMenuItemComponent implements OnInit {

  @HostBinding('class.current-page') currentPageClass = false;

  @Input()
  linkTo: string;

  @Input()
  params: any;

  @Input()
  exact = false;

  @Input()
  excludeRule: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        if (this.excludeRule && e.url.match(this.excludeRule)) {
          this.currentPageClass = false;
        } else {
          if (this.exact) {
            this.currentPageClass = e.url.split('?')[0] == this.linkTo;
          } else {
            this.currentPageClass = e.url.split('?')[0].startsWith(this.linkTo);
          }
        }
      }
    });

  }

}
