import { AbstractFilter } from '../filter-item/abstract-filter.model';

export abstract class AbstractFilterTemplate<T extends AbstractFilter> {

  protected static readonly decodePattern: RegExp = /^([^\~]+)\~(0|1)\~([^\~]+)\~?(.*)$/;

  name: string;
  label: string;

  group: string;

  constructor(name: string, label: string, group: string = 'DEFAULT') {
    this.name = name;
    this.label = label;
    this.group = group;
  }

  public abstract getType(): string;

  public abstract buildDefaultFilter(): T;

  public abstract decode(str: string): T;

  public abstract getDefaultValue(): any;
}
