import { Pipe, PipeTransform } from '@angular/core';
import slugify from 'slugify';

@Pipe({
  name: 'filterByTerm',
  pure: true
})
export class FilterByTermPipe implements PipeTransform {

  /**
   * Filter a list by term
   *
   * @param items - The list to filter
   * @param term - The value searched in the list
   * @param keys - Fields on which the research will focus
   * @param fromBeginning - If the research will start at the beginning of the string
   * @param slug - enabled slugify (remove special char, space, case...)
   */
  transform(items: any[], term: string, keys: string[] = [], fromBeginning = false, slug = true): any[] {
    if (term && term.trim().length > 0 && items.length > 0) {
      if (slug) {
        term = slugify(term, {
          lower: true,
          locale: 'fr',
        });
      }

      if (keys.length == 0) {
        keys = Object.keys(items[0]);
      }

      return items.filter(item => {
        return keys.some(key => {
          let value = new String(item[key]).toString();
          if (slug) {
            value = slugify(value, {
              lower: true,
              locale: 'fr',
            });
          }
          if (fromBeginning) {
            return value.startsWith(term);
          } else {
            return value.includes(term);
          }
        });
      });
    } else {
      return items;
    }
  }
}
