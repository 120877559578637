<form [formGroup]="filterForm" (ngSubmit)="onSubmit()" aria-label="Form filter multiple selection template">
  <select formControlName="operator" class="form-control" *ngIf="filter.getMultipleSelectionFilterTemplate().criterion.length > 1; else showLabel" aria-label="Select multiple selection filter criterion">
    <option value="" disabled>-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
    <option *ngFor="let criterion of filter.getMultipleSelectionFilterTemplate().criterion" [value]="criterion">{{ 'FILTERS.ADD.' + criterion | translate }}</option>
  </select>
  <ng-template #showLabel>
    <ap-label [status]="'DEFAULT'">{{ 'FILTERS.ADD.' + filterForm.get('operator').value | translate }}</ap-label>
  </ng-template>
  <ap-mulitple-select *ngIf="shouldShowSelectField()" [existingValues]="_filter.value" formControlName="value">
    <ap-option-info *ngFor="let option of filter.getMultipleSelectionFilterTemplate().options" [value]="option.value">{{ option.label | translate }}</ap-option-info>
  </ap-mulitple-select>
  <button [disabled]="!filterForm.valid" class="btn btn-success" type="submit" attr.aria-label="{{ 'FILTERS.ADD.VALID' | translate }}">{{ 'FILTERS.ADD.VALID' | translate }}</button>
</form>
