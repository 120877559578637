import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractFilter } from '../filter-item/abstract-filter.model';

@Component({
  selector: 'ap-filter-template',
  templateUrl: './filter-template.component.html',
  styleUrls: ['./filter-template.component.css']
})
export class FilterTemplateComponent implements OnInit {

  @Input() filter: AbstractFilter;
  @Output() addFilter = new EventEmitter<AbstractFilter>();

  constructor() { }

  ngOnInit() {
  }

  onAddFilter(filter: AbstractFilter) {
    this.addFilter.emit(filter);
  }
}
