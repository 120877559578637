import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Alarm } from '@app/core/services/alarms/models/alarm';
import { Page } from '@app/core/services/commons/page';
import { CompanyPipe } from '@app/shared/pipes/company.pipe';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Sort } from '../../table/sort/sort.model';
import { Stop } from '@app/core/services/device/location/model/stop';
import { AlarmFilter } from '@app/core/services/alarms/models/alarmFilter';
import { Route } from '@app/core/services/device/location/model/route';
import { AlarmsService } from '@app/core/services/alarms/alarms.service';
import { StopService } from '@app/core/services/device/location/stop.service';
import { RouteService } from '@app/core/services/device/location/route.service';
import { FilterOperatorEnum } from '@app/core/services/commons/enum/filter-operator.enum';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { FindPipe } from '@app/shared/pipes/find.pipe';
import { FurtherInformationLabelPipe } from '@app/modules/devices/device-details/device-informations/further-info/pipes/further-information-label.pipe';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ap-export-device-alarms-data',
  template: `<button type="button" ap-tooltip="<kbd><kbd>alt</kbd> + <kbd>j</kbd></kbd>" [ap-btn-loading]="exporting" (click)="export()" class="btn btn-default" aria-label="Export data"><i class="fa fa-download" alt="Download"></i></button>`,
  providers: [CompanyPipe]
})
export class ExportDeviceAlarmsDataComponent implements OnInit {

  DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  MAX_ELEMENTS_PER_PAGE = 2000;
  exporting = false;
  genericParameterNames: string[] = Object.keys(environment?.admin?.devices?.device?.furtherInfo ?? {}).sort();
  params: ParamMap;
  predefinedFilterHasOperator = ['code', 'severity', 'resourceId', 'transportMode', 'installationType',
  'lineReference', 'stationReference', 'placeReference', 'deviceReference', 'companyReference'];

  predefinedColumns = ['code', 'description', 'beginDate', 'endDate', 'severity', 'resourceId', 'deviceReference', 'companyReference', 'installationType',
                      'status', 'closureType', 'transportMode', 'stationReference', 'lineReference', 'placeReference'];
  displayedColumns: string[] = [];

  /**
   * Export data with current filter with the keyboard shortcut alt + j
   *
   * @param e KeyboardEvent
   */
  @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.altKey && e.code === 'KeyJ') {
      this.export();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private companyPipe: CompanyPipe,
    private alarmService: AlarmsService,
    private stopService: StopService,
    private routeService: RouteService,
    private administratorService: AdministratorService,
    private findPipe: FindPipe,
    private readonly furtherInformationLabelPipe: FurtherInformationLabelPipe,
    private readonly decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    this.initializeDisplayedHeaders();
    this.route.queryParamMap.subscribe(p => {
      this.params = p;
    });
  }

  async export(): Promise<void> {
    this.exporting = true;

    const sorts: Sort[] = [];

    if (this.params.has('sortElement')) {
      sorts.push(new Sort(this.params.get('sortElement') || 'beginDate', <'ASC'|'DESC'>(this.params.get('sortOrder') || 'DESC')));
    } else {
      sorts.push(new Sort('beginDate', 'DESC'));
      sorts.push(new Sort('severity', 'DESC'));
    }

    const filter = this.getAlarmsFilters();

    const numberOfElement = (await this.getDeviceAlarmsCall(filter, 0, 1, sorts).toPromise()).totalElements;

    const headers = this.getHeaders();
    this.getAlarms(filter, sorts, numberOfElement).subscribe(alarmsPages => {
      let rows = [];

      for (let alarmsPage of alarmsPages) {
        let alarms = alarmsPage.content;
        if (alarms && alarms.length > 0) {
          let rowsTmp = alarms.map(alarm => {
            let row = this.getAlarmRow(alarm);
            return row.join(';');
          });
          rows = rows.concat(rowsTmp);
        }
      }

      const BOM = '\uFEFF';
      const blob = new Blob([BOM + headers.join(';') + '\n' + rows.join('\n')], { type: 'text/csv;charset=utf16-le;' });
      const filename = `${moment().format('YYYYMMDD_HHmmss')}_${environment.admin.devices.alarms.export.specifiedFileName}.csv`;
      saveAs(blob, filename);
      this.exporting = false;
    });
  }

  private initializeDisplayedHeaders() {
    const columns = [ ...this.predefinedColumns, ...this.genericParameterNames ];

    const hiddenColumns = environment.admin.devices.alarms.hiddenColumns;
    this.displayedColumns = columns.filter(col => !hiddenColumns.includes(col));
  }

  private getHeaders(): string[]  {
    let headers = [];

    this.addDataToArray('code', this.translateService.instant('ALARM.EXPORT.CODE'), headers);
    this.addDataToArray('description', this.translateService.instant('ALARM.EXPORT.LABEL'), headers);
    this.addDataToArray('beginDate', this.translateService.instant('ALARM.EXPORT.DATE'), headers);
    this.addDataToArray('endDate', this.translateService.instant('ALARM.EXPORT.END_DATE'), headers);
    this.addDataToArray('severity', this.translateService.instant('ALARM.EXPORT.SEVERITY'), headers);
    this.addDataToArray('status', this.translateService.instant('ALARM.EXPORT.STATUS'), headers);
    this.addDataToArray('closureType', this.translateService.instant('ALARM.EXPORT.CLOSURE_TYPE'), headers);
    this.addDataToArray('resourceId', this.translateService.instant('ALARM.EXPORT.POI'), headers);
    this.addDataToArray('deviceReference', this.translateService.instant('ALARM.EXPORT.DEVICE_REFERENCE'), headers);
    this.addDataToArray('companyReference', this.translateService.instant('ALARM.EXPORT.COMPANY_REFERENCE'), headers);
    this.addDataToArray('installationType', this.translateService.instant('ALARM.EXPORT.INSTALLATION_TYPE'), headers);
    this.addDataToArray('transportMode', this.translateService.instant('ALARM.EXPORT.TRANSPORT_MODE'), headers);
    this.addDataToArray('stationReference', this.translateService.instant('ALARM.EXPORT.STATION'), headers);
    this.addDataToArray('lineReference', this.translateService.instant('ALARM.EXPORT.LINE'), headers);
    this.addDataToArray('placeReference', this.translateService.instant('ALARM.EXPORT.VEHICULE'), headers);

    const genericParameterHeader = this.genericParameterNames.map(genericParameterName => {
      return this.furtherInformationLabelPipe.transform(genericParameterName);
    });

    headers = headers.concat(genericParameterHeader);

    return headers;
  }

  private getAlarmRow(alarm: Alarm): string[] {
    const alertParam = {
      'alertValue': this.decimalPipe.transform(alarm.alertValue,'1.0-2')|| 'n/a',
      'alertTimeWindow': alarm.alertTimeWindow || 'n/a',
      'alertThreshold': alarm.alertThreshold || 'n/a'
    };

    let row = [];

    this.addDataToArray('code', alarm?.code || '', row);
    this.addDataToArray('description', alarm?.code ? this.translateService.instant(`ALARM.DESCRIPTION.${alarm.code}.LABEL`, alertParam) : '', row);
    this.addDataToArray('beginDate', alarm?.beginDate ? moment(alarm.beginDate).format(this.DATE_TIME_FORMAT) : '', row);
    this.addDataToArray('endDate', alarm?.endDate ? moment(alarm.endDate).format(this.DATE_TIME_FORMAT) : '', row);
    this.addDataToArray('severity', alarm?.severity ? this.translateService.instant(`ALARM.LIST.SEVERITY_TYPES.${alarm.severity}`) : '', row);
    this.addDataToArray('status', alarm?.status ? this.translateService.instant(`ALARM.LIST.STATUS_TYPES.${alarm.status}`): '', row);
    this.addDataToArray('closureType', alarm?.closureType ? this.translateService.instant(`ALARM.LIST.CLOSURE_TYPES.${alarm.closureType}`) : '', row);
    this.addDataToArray('resourceId', alarm?.resourceId ? `'${alarm.resourceId}'` : '', row);
    this.addDataToArray('deviceReference', alarm?.device?.deviceReference ? `'${alarm.device.deviceReference}'` : '', row);
    this.addDataToArray('companyReference', this.companyPipe.transform(alarm?.device?.companyReference) || '', row);
    this.addDataToArray('installationType', alarm?.device?.installationType ?
       this.translateService.instant(`DEVICE.LABELS.CONFIGURATION.PARAMETERS.INSTALLATION_TYPE_VALUE.${alarm.device.installationType}`) : '', row);
    this.addDataToArray('transportMode', alarm?.device?.transportMode ? this.translateService.instant(`ACTIVITY.TRANSPORT.${alarm.device.transportMode}`) : '', row);
    this.addDataToArray('stationReference', alarm?.device?.stationReference || '', row);
    this.addDataToArray('lineReference', alarm?.device?.lineReference || '', row);
    this.addDataToArray('placeReference', alarm?.device?.placeReference || '', row);

    const genericParamsRows = this.genericParameterNames.filter(genericParameterName => this.displayedColumns.includes(genericParameterName)).map(genericParameterName => {
      return this.findPipe.transform(alarm?.device?.parameters, { name: genericParameterName })?.value || '';
    }).filter(element => element !== undefined);
    row = row.concat(genericParamsRows);

    return row;
  }

  private addDataToArray(name: string, data: any, array: any[]) {
    if (this.displayedColumns.includes(name)) {
      array.push(data);
    }
  }

  private getAlarms(filter: any, sorts: Sort[], numberOfElement: number): Observable<Page<Alarm>[]> {
    let calls = [];

    if (numberOfElement < this.MAX_ELEMENTS_PER_PAGE) {
      calls.push(this.getDeviceAlarmsCall(filter, 0, numberOfElement, sorts));
    } else {
      const maxPages = Math.floor(numberOfElement / this.MAX_ELEMENTS_PER_PAGE) + 1;

      for (let i=0; i < maxPages; i++) {
        calls.push(this.getDeviceAlarmsCall(filter, i, this.MAX_ELEMENTS_PER_PAGE, sorts));
      }
    }

    return forkJoin(calls);
  }

  private getAlarmsFilters() {
    const genericParameterNames = this.params.get('genericParameterNames');
    const filterEncode = this.params.get('filter') || '';

    // Decode the encoded filters
    const filter = this.decodeFilter(filterEncode);
    filter.monitored = 'true';

    if (filter.code) {
      filter.code = filter.code.toUpperCase();
    }

    // Day of the acknolewdgement
    if (filter && filter.endDate) {
      filter.fromEndDate = moment(filter.endDate).startOf('day');
      filter.toEndDate = moment(filter.endDate).endOf('day');
    }

    // Acknolewdgement since (range)
    if (filter && filter.closureDateRange) {
      filter.fromEndDate = moment().subtract(moment.duration(filter.closureDateRange));
      filter.closureDateRange = null;
    }

    if (filter.resourceId) {
      filter.resourceId = [filter.resourceId];
    }

    // Generation since (range)
    if (filter.beginDateRange) {
      filter.fromBeginDate = this.setDeviceAlarmRangeFilter(filter.beginDateRange);
      filter.beginDateRange = null;
    }

    // Day of the generation
    if (filter.beginDate) {
      filter.fromBeginDate = moment(filter.beginDate).startOf('day');
      filter.toBeginDate = moment(filter.beginDate).endOf('day');
    }

    if (!filter.fromBeginDate) {
      filter.fromBeginDate = moment().subtract(environment.admin.devices.alarms.history);
    }

    if (!filter.status && !(filter.fromEndDate || filter.toEndDate || filter.closureType)) {
      filter.status = 'OPENED';
    }

    // Filter for genericParameter
    if (genericParameterNames) {
      let genericParameters = '';
      for (const [key, value] of Object.entries(filter)) {
        if (genericParameterNames.includes(key)) {
          genericParameters += genericParameters.length > 0 ? ',' + key + '|' + value : key + '|' + value;
        }
      }
      filter.genericParameter = genericParameters;
    }

    // Add operator to companyReference filter when loggedUser is not ADMIN
    const companyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    if (companyReference) {
      filter.companyReference = `${FilterOperatorEnum.CONTAINS}:${companyReference}`;
    }

    return filter;
  }

  private decodeFilter(filterEncode: string): any {
    const filter = {};
    if (filterEncode) {
      filterEncode.split('|')
      .filter(s => s.match(/.*\~[0-1]\~.*/))
      .filter(s => {
        const enabled = s.split('~')[1] === '1';
        return enabled;
      })
      .forEach(s => {
        const key = s.split('~')[0];
        let operator = s.split('~')[3];
        let value;
        if (key === 'stationReference' || key === 'lineReference') {
          const ids = s.split('~')[3];
          operator = s.split('~')[4];
          if (filter[key]) {
            value = `${filter[key]},${ids}`;
          } else {
            value = `${operator}:${ids}`;
          }
        } else if (operator && this.predefinedFilterHasOperator.includes(key)) {
          value = `${operator}:${s.split('~')[2]}`;
        } else {
          value = s.split('~')[2];
        }
        filter[key] = value;
      });
    }
    return filter;
  }

  private getDeviceAlarmsCall (filter: AlarmFilter, pageNumber, pageSize, sorts: Sort[]): Observable<Page<Alarm>> {
    return this.alarmService.readAlarms(filter, pageNumber, pageSize, sorts).pipe(
      catchError((_error) => {
        this.router.navigate(['/error/500']);
        return of(_error);
      }),
      // enrich each alarm with device (identify by the ressourceId)
      switchMap((_deviceAlarms: Page<Alarm>) => {
        // if the alarm was acknowledged, his status is closed
        if (filter.fromEndDate || filter.toEndDate || filter.closureType) {
          _deviceAlarms.content = _deviceAlarms.content.filter(_a => _a.endDate);
        }
        const stationReferences = [... new Set(_deviceAlarms.content.map(_deviceAlarm => _deviceAlarm?.device?.stationReference?.toString()))];
        // Prepare stopFilter
        return this.stopService.readStopsByFilters({stopIds: stationReferences}).pipe(
          map((_stops: Page<Stop>) => {
            _deviceAlarms.content.forEach(_deviceAlarm => {
              if (_deviceAlarm?.device?.stationReference) {
                const stop = _stops.content.find(s => s.name === _deviceAlarm.device.stationReference);
                if (stop) {
                  _deviceAlarm.device.deviceUI = { stationReference: stop?.name, poi: _deviceAlarm.device.poi, lastBlockingRequestDate: null, lastBlockingRequest: null, lastRebootRequest: null, lineReference: null };
                }
              }
            });
            return _deviceAlarms;
          })
        );
      }),
      // Add line name from references
      switchMap((_deviceAlarms: Page<Alarm>) => {
        const lineReferences = [... new Set(_deviceAlarms.content.map(_deviceAlarm => _deviceAlarm?.device?.lineReference?.toString()))];
        return this.routeService.readRouteByFilters({routeIds: lineReferences}).pipe(
          map((_routes: Page<Route>) => {
            _deviceAlarms.content.forEach(_device => {
              if (_device?.device?.lineReference) {
                const route = _routes.content.find(_r => _r.shortName === _device.device.lineReference);
                if (route) {
                  if (_device.device.deviceUI) {
                    _device.device.deviceUI.lineReference = route?.longName;
                  } else {
                    _device.device.deviceUI = { stationReference: null, poi: _device.device.poi, lastBlockingRequestDate: null, lastBlockingRequest: null, lastRebootRequest: null, lineReference: route?.longName };
                  }
                }
              }
            })
            return _deviceAlarms;
        }));
      })
    )
  }

  private setDeviceAlarmRangeFilter = function (range): moment.Moment {
    const date = moment();
    date.subtract(moment.duration(range));
    return date;
  }

}
