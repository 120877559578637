<section class="modal-backdrop">
  <section class="modal-dialog modal-md">
    <section class="modal-content">
      <section class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
      </section>
      <section class="modal-body">
        <pre *ngIf="message"><code>{{ message | json }}</code></pre>
      </section>
      <section role="contentinfo" class="modal-footer" aria-label="dialog-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()" attr.aria-label="{{ confirmButton }}">{{ confirmButton }}</button>
      </section>
    </section>
  </section>
</section>
