import { Directive, Input, OnInit, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import tippy from 'tippy.js';

@Directive({
  selector: '[ap-tooltip]'
})
export class ToolTipDirective implements OnInit, OnChanges, OnDestroy {

  @Input('ap-tooltip') content: string;
  @Input() show = true;
  @Input() maxWidth: number;

  private tippy: any;

  constructor(private el: ElementRef) {}

  public ngOnInit() {
    if (this.content && this.show) {
      this.tippy = tippy(this.el.nativeElement, {
        content: this.content,
        placement: 'bottom',
        allowHTML: true,
        maxWidth: this.maxWidth,
        arrow: true
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tippy && changes?.content?.currentValue && changes.content.previousValue !== changes.content.currentValue) {
      // Update the tooltip value when its content changes
      this.tippy.setContent(changes.content.currentValue);
    }

    if (this.tippy && changes?.show) {
      if (changes.show.currentValue) {
        this.tippy.enable();
      } else {
        this.tippy.disable();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.tippy) {
      this.tippy.destroy();
      this.tippy = null;
    }
  }
}
