import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Page } from '@app/core/services/commons/page';
import { DeviceService } from '@app/core/services/device/device.service';
import { RouteFilter } from '@app/core/services/device/location/model/filter/routeFilter';
import { StopFilter } from '@app/core/services/device/location/model/filter/stopFilter';
import { RouteService } from '@app/core/services/device/location/route.service';
import { StopService } from '@app/core/services/device/location/stop.service';
import { DeviceFilter } from '@app/core/services/device/models/list/deviceFilter';
import { DeviceUI } from '@app/core/services/device/models/list/deviceUI';
import { HealthIndicator } from '@app/core/services/device/models/list/healthIndicator';
import { FurtherInformationLabelPipe } from '@app/modules/devices/device-details/device-informations/further-info/pipes/further-information-label.pipe';
import { CompanyPipe } from '@app/shared/pipes/company.pipe';
import { DefaultIfEmptyPipe } from '@app/shared/pipes/defaultIfEmpty.pipe';
import { FindPipe } from '@app/shared/pipes/find.pipe';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { saveAs } from 'file-saver';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlarmsService } from '@app/core/services/alarms/alarms.service';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { HealthIndicatorValueEnum } from '@app/core/services/device/models/enum/healthIndicatorValue.enum';
import { BlockingTypeEnum } from '@app/core/services/device/models/enum/blockingType.enum';
import { Device } from '@app/core/services/device/models/list/device';

@Component({
  selector: 'ap-export-devices-data',
  template: `<button type="button" ap-tooltip="<kbd><kbd>alt</kbd> + <kbd>j</kbd></kbd>" [ap-btn-loading]="exporting" (click)="export()" class="btn btn-default" aria-label="Export data"><i class="fa fa-download" alt="Download"></i></button>`,
  providers: [CompanyPipe, DefaultIfEmptyPipe, FindPipe, FurtherInformationLabelPipe, LocalizedDatePipe, DecimalPipe]
})
export class ExportDevicesDataComponent implements OnInit {

  DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  MAX_ELEMENTS_PER_PAGE = 2000;
  transportModes = environment.admin.devices.device.transportMode;
  genericParameterNames: string[] = Object.keys(environment?.admin?.devices?.device?.furtherInfo ?? {}).sort();

  exporting = false;
  params: ParamMap;
  predefinedFilterHasOperator = ['poi', 'deviceModel', 'companyReference', 'serialNumber', 'deviceReference',
  'simReference', 'installationType', 'transportMode', 'placeReference', 'blockingOrigin', 'version',
  'stationReference', 'lineReference'];

  // Configuration
  predefinedColumns = [ 'poi', 'fonction', 'deviceModel', 'companyReference', 'serialNumber', 'deviceReference',
                                'simReference', 'installationType', 'transportMode', 'stationReference','placeReference',
                                'activity', 'system', 'hardware', 'configuration', 'blockingStatus', 'rebootDate', 'alarms', 'version' ];
  displayedColumns: string[] = [];

  maxSizeAlarmsAccepted = environment.admin.devices.alarms.maxRequestHeaderSizeElements; // config to split alarms requests

  constructor(
    private companyPipe: CompanyPipe,
    private defaultIfEmptyPipe: DefaultIfEmptyPipe,
    private deviceService: DeviceService,
    private findPipe: FindPipe,
    private furtherInformationLabelPipe: FurtherInformationLabelPipe,
    private route: ActivatedRoute,
    private router: Router,
    private routeService: RouteService,
    private stopService: StopService,
    private translateService: TranslateService,
    private alarmService: AlarmsService,
    private localizedDatePipe: LocalizedDatePipe,
    private decimalPipe: DecimalPipe
  ) {

  }

  /**
   * Export data with current filter with the keyboard shortcut alt + j
   *
   * @param e KeyboardEvent
   */
   @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.altKey && e.code === 'KeyJ') {
      this.export();
    }
  }

  ngOnInit(): void {
    this.initializeDisplayedHeaders();
    this.route.queryParamMap.subscribe(p => {
      this.params = p;
    });
  }

  async export(): Promise<void> {
    this.exporting = true;

    const deviceFilter = this.getFilters();
    const sortElement = this.params.get('sortElement') || 'state.date';
    const sortOrder = <'ASC'|'DESC'>(this.params.get('sortOrder') || 'DESC');
    const numberOfElement: number = (await this.getDevicesCall(deviceFilter, sortOrder, sortElement, 0, 1).toPromise()).totalElements;
    const headers = this.buildHeaders();

    this.getDevices(deviceFilter, sortElement, sortOrder, numberOfElement).subscribe(async devicesPages => {
      let rows = [];

      for (let devicePage of devicesPages) {
        let devices = devicePage.content;
        if (devices.length > 0) {
          if (this.displayedColumns.includes('alarms') && devices.length) {
            devices = await this.retrieveAlarmsByDevices(devices);
          }

          let rowsTmp = devices.map(device => {
            let row = [];
            this.addDataToArray('poi', device?.poi ? `'${device.poi}'` : '', row);
            this.addDataToArray('fonction', device?.configuration?.parameters?.function ? this.translateService.instant('DEVICE.LABELS.CONFIGURATION.PARAMETERS.FUNCTION_VALUE.' + device.configuration.parameters.function) : '', row);
            this.addDataToArray('deviceModel', device?.configuration?.parameters?.deviceModel ? this.translateService.instant('DEVICE.LABELS.CONFIGURATION.PARAMETERS.DEVICE_MODEL_VALUE.' + device.configuration.parameters.deviceModel) : '', row);
            this.addDataToArray('companyReference', this.companyPipe.transform(device?.configuration?.validator?.companyReference) || '', row);
            this.addDataToArray('serialNumber', device?.state?.serialNumber ? `'${device.state.serialNumber}'` : '', row);
            this.addDataToArray('deviceReference', this.defaultIfEmptyPipe.transform(device?.state?.deviceReference, device?.configuration?.terminal?.deviceReference) ? `'${this.defaultIfEmptyPipe.transform(device?.state?.deviceReference, device?.configuration?.terminal?.deviceReference)}'` : '', row);
            this.addDataToArray('simReference', device?.state?.simReference ? `'${device.state.simReference}'` : '', row);
            this.addDataToArray('installationType', device?.configuration?.parameters?.installationType ? this.translateService.instant('DEVICE.LABELS.CONFIGURATION.PARAMETERS.INSTALLATION_TYPE_VALUE.' + device.configuration.parameters.installationType) : '', row);
            this.addDataToArray('transportMode', device?.configuration?.parameters?.transportMode && this.transportModes.includes(device.configuration.parameters.transportMode) ? this.translateService.instant('ACTIVITY.TRANSPORT.' + device.configuration.parameters.transportMode) : '', row);
            row.push(device?.state?.location?.lineReference || '');
            this.addDataToArray('stationReference', device?.state?.location?.stationReference || '', row);
            this.addDataToArray('placeReference', device?.state?.location?.placeReference ? `'${device?.state?.location?.placeReference}'` : '', row);

            const genericParamsRows = this.genericParameterNames.filter(genericParameterName => this.displayedColumns.includes(genericParameterName)).map(genericParameterName => {
              return this.findPipe.transform(device?.genericParameters, { name: genericParameterName })?.value || '';
            }).filter(element => element !== undefined);
            row = row.concat(genericParamsRows);

            this.addDataToArray('activity', this.getHealthIndicatorClass(device?.healthIndicators, 'Activity'), row);
            this.addDataToArray('activity', this.localizedDatePipe.transform(device?.state?.date) || '', row);
            this.addDataToArray('activity', device?.state?.metrics?.lastTransmittedTap ? this.localizedDatePipe.transform(device.state.metrics.lastTransmittedTap) : '', row);

            this.addDataToArray('system', this.getHealthIndicatorClass(device?.healthIndicators, 'System'), row);
            this.addDataToArray('system', device?.state?.metrics?.upTime ? moment.utc(moment.duration(device.state.metrics.upTime, 'seconds').asMilliseconds()).format('HH:mm:ss') : '', row);
            this.addDataToArray('system', device?.state?.metrics?.memoryUsage ? this.decimalPipe.transform(device.state.metrics.memoryUsage, '1.0-2') : '', row);
            this.addDataToArray('system', device?.state?.metrics?.diskUsage ? this.decimalPipe.transform(device.state.metrics.diskUsage, '1.0-2') : '', row);
            this.addDataToArray('system', device?.state?.metrics?.cpuUsage ? this.decimalPipe.transform(device.state.metrics.cpuUsage, '1.0-2') : '', row);
            this.addDataToArray('system', device?.state?.metrics?.rebootCount ? device.state.metrics.rebootCount : '', row);

            this.addDataToArray('hardware', this.getHealthIndicatorClass(device?.healthIndicators, 'Hardware'), row);
            this.addDataToArray('hardware', device?.state?.metrics && device?.state?.metrics['4g'] ? this.getHardwareIndicatorClass(device.state.metrics['4g']) : '', row);
            this.addDataToArray('hardware', device?.state?.metrics?.gps ? this.getHardwareIndicatorClass(device.state.metrics.gps) : '', row);

            this.addDataToArray('configuration', this.getHealthIndicatorClass(device?.healthIndicators, 'Configuration'), row);
            this.addDataToArray('configuration', device?.state?.configurationState?.version ? device.state.configurationState.version : '', row);
            this.addDataToArray('configuration', device?.state?.configurationState?.versionDate ? this.localizedDatePipe.transform(device.state.configurationState.versionDate) : '', row);
            this.addDataToArray('configuration', device?.configuration?.parameters?.configVersion && device.configuration.parameters.configVersion !== '0' ? device.configuration.parameters.configVersion : '', row);

            this.addDataToArray('blockingStatus', this.manageDeviceWithStateBlock(device).status, row);
            this.addDataToArray('blockingStatus', this.manageDeviceWithStateBlock(device).origins.toString(), row);

            this.addDataToArray('blockingStatus', this.manageDeviceWithLastBlockingRequest(device).request, row);
            this.addDataToArray('blockingStatus', this.manageDeviceWithLastBlockingRequest(device).requestDate, row);
            this.addDataToArray('blockingStatus', this.manageDeviceWithLastBlockingRequest(device).requestOrigin, row);

            this.addDataToArray('rebootDate', device?.lastRebootRequest?.dateRequest ? this.localizedDatePipe.transform(device.lastRebootRequest.dateRequest) : '', row);
            this.addDataToArray('alarms', this.getAlarmSeverityEnum(device?.alarmMaxSeverity), row);
            this.addDataToArray('version', device?.state?.firmwareVersion || '', row);

            return row.join(';');
          });

          rows = rows.concat(rowsTmp);
        }
      }

      const BOM = '\uFEFF';
      const blob = new Blob([BOM + headers.join(';') + '\n' + rows.join('\n')], { type: 'text/csv;charset=utf16-le;' });
      const filename = `${moment().format('YYYYMMDD_HHmmss')}_${environment.admin.devices.list.export.specifiedFileName}.csv`;
      saveAs(blob, filename);
      this.exporting = false;
    });
  }

  /**
   * Get the names of the headers to display
   */
  private initializeDisplayedHeaders() {
    let columns = [ ...this.predefinedColumns, ...this.genericParameterNames ];

    // If blocking is disabled then remove it from displayed column
    if (!environment?.admin?.devices?.device?.blocking?.activate) {
      columns = columns.filter(c => c !== 'blockingStatus');
    }

    // If reboot is disabled then remove it from displayed column
    if (!environment?.admin?.devices?.device?.reboot?.activate) {
      columns = columns.filter(c => c !== 'rebootDate');
    }

    const hiddenColumns = environment.admin.devices.list.hiddenColumns;
    this.displayedColumns = columns.filter(col => !hiddenColumns.includes(col));
  }

  private buildHeaders() {
    let headers = [];

    this.addDataToArray('poi', this.translateService.instant('DEVICE.LABELS.EXPORT.POI'), headers);
    this.addDataToArray('fonction', this.translateService.instant('DEVICE.LABELS.EXPORT.FUNCTION'), headers);
    this.addDataToArray('deviceModel', this.translateService.instant('DEVICE.LABELS.EXPORT.DEVICE_MODEL'), headers);
    this.addDataToArray('companyReference', this.translateService.instant('DEVICE.LABELS.EXPORT.COMPANY_REFERENCE'), headers);
    this.addDataToArray('serialNumber', this.translateService.instant('DEVICE.LABELS.EXPORT.SERIAL_NUMBER'), headers);
    this.addDataToArray('deviceReference', this.translateService.instant('DEVICE.LABELS.EXPORT.DEVICE_REFERENCE'), headers);
    this.addDataToArray('simReference', this.translateService.instant('DEVICE.LABELS.EXPORT.SIM_REFERENCE'), headers);
    this.addDataToArray('installationType', this.translateService.instant('DEVICE.LABELS.EXPORT.INSTALLATION_TYPE'), headers);
    this.addDataToArray('transportMode', this.translateService.instant('DEVICE.LABELS.EXPORT.TRANSPORT_MODE'), headers);
    headers.push(this.translateService.instant('DEVICE.LABELS.EXPORT.LINE'));
    this.addDataToArray('stationReference', this.translateService.instant('DEVICE.LABELS.EXPORT.STATION'), headers);
    this.addDataToArray('placeReference', this.translateService.instant('DEVICE.LABELS.EXPORT.VEHICULE'), headers);

    const genericParameterHeader = this.genericParameterNames.filter(genericParameterName => this.displayedColumns.includes(genericParameterName)).map(genericParameterName => {
      return this.furtherInformationLabelPipe.transform(genericParameterName);
    });
    headers = headers.concat(genericParameterHeader);

    this.addDataToArray('activity', this.translateService.instant('DEVICE.LABELS.EXPORT.ACTIVITY_INDICATOR'), headers);
    this.addDataToArray('activity', this.translateService.instant('DEVICE.LABELS.EXPORT.LAST_CONNECTION'), headers);
    this.addDataToArray('activity', this.translateService.instant('DEVICE.LABELS.EXPORT.LAST_TAP'), headers);

    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.SYSTEM_INDICATOR'), headers);
    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.UPTIME'), headers);
    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.MEMORY'), headers);
    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.DISK_USAGE'), headers);
    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.CPU'), headers);
    this.addDataToArray('system', this.translateService.instant('DEVICE.LABELS.EXPORT.REBOOT_COUNT'), headers);

    this.addDataToArray('hardware', this.translateService.instant('DEVICE.LABELS.EXPORT.HARDWARE_INDICATOR'), headers);
    this.addDataToArray('hardware', this.translateService.instant('DEVICE.LABELS.EXPORT.HARDWARE_4G_STATUS'), headers);
    this.addDataToArray('hardware', this.translateService.instant('DEVICE.LABELS.EXPORT.HARDWARE_GPS_STATUS'), headers);

    this.addDataToArray('configuration', this.translateService.instant('DEVICE.LABELS.EXPORT.CONFIGURATION_INDICATOR'), headers);
    this.addDataToArray('configuration', this.translateService.instant('DEVICE.LABELS.EXPORT.CONFIGURATION_CURRENT_VERSION'), headers);
    this.addDataToArray('configuration', this.translateService.instant('DEVICE.LABELS.EXPORT.CONFIGURATION_UPDATE_DATE'), headers);
    this.addDataToArray('configuration', this.translateService.instant('DEVICE.LABELS.EXPORT.CONFIGURATION_NEXT_VERSION'), headers);

    this.addDataToArray('blockingStatus', this.translateService.instant('DEVICE.LABELS.EXPORT.BLOCKED_STATUS'), headers);
    this.addDataToArray('blockingStatus', this.translateService.instant('DEVICE.LABELS.EXPORT.BLOCKED_ORIGINS'), headers);

    this.addDataToArray('blockingStatus', this.translateService.instant('DEVICE.LABELS.EXPORT.BLOCKING_REQUESTED'), headers);
    this.addDataToArray('blockingStatus', this.translateService.instant('DEVICE.LABELS.EXPORT.BLOCKING_REQUESTED_DATE'), headers);
    this.addDataToArray('blockingStatus', this.translateService.instant('DEVICE.LABELS.EXPORT.BLOCKING_REQUESTED_ORIGIN'), headers);

    this.addDataToArray('rebootDate', this.translateService.instant('DEVICE.LABELS.EXPORT.REBOOT_REQUEST_DATE'), headers);
    this.addDataToArray('alarms', this.translateService.instant('DEVICE.LABELS.EXPORT.ALARMS'), headers);
    this.addDataToArray('version', this.translateService.instant('DEVICE.LABELS.EXPORT.FIRMWARE_VERSION'), headers);

    return headers;
  }

  private addDataToArray(name: string, data: any, array: any[]) {
    if (this.displayedColumns.includes(name)) {
      array.push(data);
    }
  }

  private getHealthIndicatorClass(healthIndicators: HealthIndicator[], name: string): string {
    if (!healthIndicators) { return ''; }
    const healthIndicatorValue = (healthIndicators.find(h => h.name === name) || {}).value;
    switch (healthIndicatorValue) {
      case HealthIndicatorValueEnum.OK:
        return 'OK';
      case HealthIndicatorValueEnum.WARNING:
        return 'WARNING';
      case HealthIndicatorValueEnum.KO:
        return 'KO';
      default:
    }
    return '';
  }

  private getHardwareIndicatorClass(status: string): string {
    if (status === '1') { return 'OK'; }
    if (status === '0') { return 'KO'; }
    return '';
  }

  private manageDeviceWithLastBlockingRequest(device: DeviceUI) {
    let lastBlockInfo = { request: '', requestDate: '', requestOrigin: '' };
    if (device?.lastBlockingRequest && !device.lastBlockingRequest.applied) {
      lastBlockInfo.request = device.lastBlockingRequest.type === BlockingTypeEnum.UNBLOCK ? this.translateService.instant('DEVICE.LABELS.LIST.UNBLOCKING_IN_PROGRESS') : this.translateService.instant('DEVICE.LABELS.LIST.BLOCKING_IN_PROGRESS');
      lastBlockInfo.requestDate = this.localizedDatePipe.transform(device.lastBlockingRequest.date);
      lastBlockInfo.requestOrigin = device?.lastBlockingRequest?.origin ? this.translateService.instant('DEVICE.LABELS.LIST.BLOCKING_ORIGIN.' + device.lastBlockingRequest.origin) : '';
    }
    return lastBlockInfo;
  }

  private manageDeviceWithStateBlock(device: DeviceUI) {
    let stateBlockInfo = {status: this.translateService.instant('DEVICE.LABELS.LIST.BLOCKING_STATUS.UNBLOCKED'), origins: []};
    if (device?.state?.block?.blocked) {
      stateBlockInfo.status = this.translateService.instant('DEVICE.LABELS.LIST.BLOCKING_STATUS.BLOCKED');
      if (device?.state?.block?.origins) {
        device.state.block.origins.map(blockOrigin => {
          if (blockOrigin.origin) {
            stateBlockInfo.origins.push(this.translateService.instant('DEVICE.LABELS.LIST.BLOCKING_ORIGIN.' + blockOrigin.origin));
          }
        });
      }
    }
    return stateBlockInfo;
  }

  private getAlarmSeverityEnum(severity: string): string {
    if (!severity) { return ''; }
    switch (severity) {
      case 'HIGH':
        return this.translateService.instant('ALARM.LIST.SEVERITY_TYPES.HIGH');
      case 'MEDIUM' :
        return this.translateService.instant('ALARM.LIST.SEVERITY_TYPES.MEDIUM');
      case 'LOW':
        return this.translateService.instant('ALARM.LIST.SEVERITY_TYPES.LOW');
      default:
    }
    return '';
  }

  private getDevices(deviceFilter: DeviceFilter, sortElement: string, sortOrder: string, numberOfElement: number): Observable<Page<DeviceUI>[]> {
    let calls = [];

    if (numberOfElement < this.MAX_ELEMENTS_PER_PAGE) {
      calls.push(this.getDevicesCall(deviceFilter, sortOrder, sortElement, 0, numberOfElement));
    } else {
      const maxPages = Math.floor(numberOfElement / this.MAX_ELEMENTS_PER_PAGE) + 1;

      for (let i=0; i < maxPages; i++) {
        calls.push(this.getDevicesCall(deviceFilter, sortOrder, sortElement, i, this.MAX_ELEMENTS_PER_PAGE));
      }
    }

    return forkJoin(calls);
  }

  private getFilters(): DeviceFilter {
    const deviceFilter: DeviceFilter = {};
    const filterEncode = this.params.get('filter');
    const genericParameterNames = this.params.get('genericParameterNames');

    let genericParameters = '';

    if (filterEncode) {
      filterEncode
      .split('|')
      .filter(s => s.match(/.*\~[0-1]\~.*/))
      .filter(s => {
        // filter on enabled value
        return s.split('~')[1] === '1';
      })
      .forEach(s => {
        const key = s.split('~')[0];
        let value = s.split('~')[2];
        let operator = s.split('~')[3];
        if (key && (['sinceConfigurationVersion', 'untilConfigurationVersion', 'sinceLastConnexion', 'untilLastConnexion', 'sinceLastRebootRequest', 'untilLastRebootRequest'].includes(key)) ) {
          value = moment(value).format(this.DATE_TIME_FORMAT);
        }
        if (key && key === 'dateLastConnexion') {
          deviceFilter.sinceLastConnexion = moment(value).startOf('day').format(this.DATE_TIME_FORMAT);
          deviceFilter.untilLastConnexion = moment(value).endOf('day').format(this.DATE_TIME_FORMAT);
        } else if (key === 'dateConfigurationVersion') {
          deviceFilter.sinceConfigurationVersion = moment(value).startOf('day').format(this.DATE_TIME_FORMAT);
          deviceFilter.untilConfigurationVersion = moment(value).endOf('day').format(this.DATE_TIME_FORMAT);
        } else if (key === 'stationReference' || key === 'lineReference') {
          const ids = s.split('~')[3];
          operator = s.split('~')[4];
          if (deviceFilter[key]) {
            let filterNewValue = ids.split(',').map(v=>`${operator}:${v}`).toString();
            deviceFilter[key].push(filterNewValue);
          } else {
            deviceFilter[key] = ids.split(',').map(v=>`${operator}:${v}`);
          }
        } else if (genericParameterNames && genericParameterNames.includes(key) ) {
          genericParameters += genericParameters.length > 0 ? ',' + key + '|' + value : key + '|' + value;
        } else if (operator && this.predefinedFilterHasOperator.includes(key)) {
          if (value.indexOf(',') !== -1) {
            deviceFilter[key] = value.split(',').map(v=>`${operator}:${v}`).toString();
          } else {
            deviceFilter[key] = `${operator}:${value}`;
          }
        } else {
          deviceFilter[key] = value;
        }
      });
    }

    deviceFilter.genericParameter = genericParameters;

    return deviceFilter;
  }

  private getDevicesCall(deviceFilter: DeviceFilter, sortOrder: string, sortElement: string, pageNumber: number, pageSize: number): Observable<Page<Device>> {
    return this.deviceService.getDevices(deviceFilter, sortOrder, sortElement, pageNumber, pageSize, 'FULL').pipe(
      switchMap((devices: Page<DeviceUI>) => {
        let references = devices.content
        .filter(d => d?.state?.location?.stationReference)
        .map(d => d.state.location.stationReference);

        references = [...new Set(references)];

        // Define stop filter
        const stopFilter: StopFilter = {};
        stopFilter.stopIds = references;
        return this.stopService.readStopsByFilters(stopFilter).pipe(
          map(stops => {
            devices.content.forEach(device => {
              if (device?.state?.location?.stationReference) {
                const stop =  stops.content.find(s => s.name === device.state.location.stationReference);
                if (stop) {
                  device.stationReference = stop.name;
                }
              }
            });
            return devices;
          }));
      }),
      switchMap((devices: Page<DeviceUI>) => {
        let references = devices.content
        .filter(d => d?.state?.location?.lineReference)
        .map(d => d.state.location.lineReference);

        references = [...new Set(references)];

        const routeFilter: RouteFilter = {};
        routeFilter.routeIds = references;
        return this.routeService.readRouteByFilters(routeFilter).pipe(
          map(routes => {
            devices.content.forEach(device => {
              if (device?.state?.location?.lineReference) {
                const route = routes.content.find(r => r.shortName === device.state.location.lineReference);
                if (route) {
                  device.lineReference = route.longName;
                }
              }
            });
            return devices;
          }));
      }),
      catchError((error) => {
        console.error(error);
        // Perhaps add different type of errors
        this.router.navigate(['/error/500']);
        return of(error);
      })
    );
  }

  /**
   * This Method is used to generate object with pagination based on devices array
   * @param devices
   * @param page
   * @returns
   */
  private paginatedDevicesAlarms(devices: DeviceUI[], page = 1) {
    const offset = (page - 1) * this.maxSizeAlarmsAccepted;
    const paginatedDevices = devices.slice(offset).slice(0, this.maxSizeAlarmsAccepted);
    const total_pages = Math.ceil(devices.length / this.maxSizeAlarmsAccepted);

    return {
      page: page,
      next_page: (total_pages > page) ? page + 1 : null,
      data: paginatedDevices
    };
  }

  /**
   * This Method is used to set alarm severity of POI based on object with pagination and devices array
   * @param devices
   * @returns
   */
  private async retrieveAlarmsByDevices(devices: DeviceUI[]): Promise<DeviceUI[]> {
    let paginatedDevicesAlarms = this.paginatedDevicesAlarms(devices);
    let paginatedDevicesAlarmsPois = paginatedDevicesAlarms.data.map(device => device.poi);
    let devicesPoisAlarms = await this.alarmService.getDevicesMaximumSeverity(paginatedDevicesAlarmsPois).toPromise();
    let devicesPoisAlarmsMap = new Map(Object.entries(devicesPoisAlarms));
    devices.filter(device => Array.from(devicesPoisAlarmsMap.keys()).includes(device.poi)).map(device => device.alarmMaxSeverity = devicesPoisAlarmsMap.get(device.poi));

    if (paginatedDevicesAlarms.next_page) {
      while(paginatedDevicesAlarms.next_page) {
        paginatedDevicesAlarms = this.paginatedDevicesAlarms(devices, paginatedDevicesAlarms.next_page);
        paginatedDevicesAlarmsPois = paginatedDevicesAlarms.data.map(device => device.poi);
        devicesPoisAlarms = await this.alarmService.getDevicesMaximumSeverity(paginatedDevicesAlarmsPois).toPromise();
        devicesPoisAlarmsMap = new Map(Object.entries(devicesPoisAlarms));
        devices.filter(device => Array.from(devicesPoisAlarmsMap.keys()).includes(device.poi)).map(device => device.alarmMaxSeverity = devicesPoisAlarmsMap.get(device.poi));
      }
    }
    return devices;
  }

}
