import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { Administrator } from './models/administrator';
import { UserAdmin } from './models/userAdmin';
import { map, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';
import { Page } from '../commons/page';
import { KeycloakService } from 'keycloak-angular';
import { Role } from './models/role';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {


  constructor(
    private httpClient: HttpClient,
    private keycloakService: KeycloakService) {
  }

  loggedAgent$ = new BehaviorSubject<UserAdmin>(null);

  profils = [
    'ROOT_ADMIN',
    'SUPER_ADMIN',
    'MYCOMPANY_ADMIN_WITH_TARIFF_MANAGEMENT',
    'MYCOMPANY_ADMIN_WITHOUT_TARIFF_MANAGEMENT',
    'OPERATOR',
    'SERVICE_OPERATOR',
    'SAV',
    'AGENT_WITHOUT_STATS',
    'MYCOMPANY_AGENT_WITH_STATS',
    'MYCOMPANY_FINANCIAL_AGENT',
    'SUPER_STATS',
    'MYCOMPANY_STATS',
    'CONTROL'];

  companyRoles = ['MYCOMPANY_ADMIN_WITH_TARIFF_MANAGEMENT', 'MYCOMPANY_ADMIN_WITHOUT_TARIFF_MANAGEMENT', 'SERVICE_OPERATOR', 'MYCOMPANY_AGENT_WITH_STATS', 'MYCOMPANY_FINANCIAL_AGENT', 'MYCOMPANY_STATS', 'CONTROL'];

  private userCache: { [key: string]: Observable<UserAdmin> } = {};

  public createUserBack(administrator: UserAdmin): Observable<any> {
    return this.httpClient
      .post<Administrator>(environment.admin.api.mobilityEngine + '/v1/realms/' +
        environment.admin.keycloak.realm + '/clients/' + environment.admin.keycloak.clientId + '/users', administrator, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });
  }

  public updateUserBack(administrator: UserAdmin, adminId: number): Observable<any> {
    return this.httpClient
      .put<UserAdmin>(environment.admin.api.mobilityEngine + '/v1/users/' + adminId, administrator, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });
  }

  public getUserByReference(reference: string): Observable<UserAdmin> {
    return this.httpClient.get<UserAdmin>(environment.admin.api.mobilityEngine + '/v1/realms/' + environment.admin.keycloak.realm + '/reference/' + reference + '/user')
      .pipe(
        switchMap(user => {
          return this.httpClient.get<Role>(environment.admin.api.mobilityEngine + '/api/user-manager/v1/roles/' + user.role).pipe(
            map(role => {
              user.role = role;
              return user;
            })
          );
        })
      );
  }

  public getUsersByReferences(references: string[]): Observable<UserAdmin[]> {
    const promises = references.map(ref => this.getUserByReference(ref));
    return forkJoin(promises).pipe(
      map((s: UserAdmin[]) => s.reduce((acc: UserAdmin[], val) => acc.concat(val), [])));
  }

  public getAdministratorByKCId(kcId: string): Observable<UserAdmin> {
      return this.httpClient
      .get<any>(environment.admin.api.mobilityEngine + '/v1/realms/' + environment.admin.keycloak.realm + '/reference/' + kcId + '/user', {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });
  }

  public getAdministrators(filter, sortOrder = 'asc', sortElement = '', pageNumber = 0, pageSize = 10): Observable<Page<UserAdmin>> {

    switch (sortElement.toUpperCase()) {
      case 'EMAIL':
        sortElement = 'userDetails.userEmailAddress';
        break;
      case 'LAST_NAME':
        sortElement = 'userDetails.userLastName';
        break;
      case 'FIRST_NAME':
        sortElement = 'userDetails.userFirstName';
        break;
      case 'COMPANY_REFERENCE':
        sortElement = 'userDetails.companyReference';
        break;
    }
    const sort = sortElement + ',' + sortOrder.toLowerCase();
    let params = new HttpParams()
      .set('sort', sort)
      .set('page', pageNumber.toString())
      .set('size', pageSize.toString());

    if (filter.lastName != null && filter.lastName !== '') {
      params = params.set('lastName', encodeURIComponent(filter.lastName));
    }
    if (filter.firstName != null && filter.firstName !== '') {
      params = params.set('firstName', encodeURIComponent(filter.firstName));
    }
    if (filter.email != null && filter.email !== '') {
      params = params.set('email', encodeURIComponent(filter.email));
    }
    if (filter.companyReference != null && filter.companyReference !== '') {
      params = params.set('companyReference', encodeURIComponent(filter.companyReference));
    }
    if (filter.role != null && filter.role !== '') {
      params = params.set('role', encodeURIComponent(filter.role));
    }
    // filtering: to not show agents without email
    params = params.set('onlyUsersWithEmail', 'true');
    params = this.setParamsWithCompanyRef(params);

    return this.httpClient.get<Page<UserAdmin>>(environment.admin.api.mobilityEngine + '/v1/realms/' + environment.admin.keycloak.realm + '/users', { params })
      .pipe(map(page => {
        if (page.content && page.content.length > 0) {
          page.content.forEach(agent => {
            const role = agent.role;

            // transform into a Role object
            agent.role = {
              name: role.toString(),
              realm: 'tap2useAdmin',
              creationDate: null,
              description: null,
              roleId: null,
              modificationDate: null,
              permissions: null
            };
          });
        }
        return page;
      }));
  }

  public getAdministrator(adminId: number): Observable<UserAdmin> {
    return this.httpClient
      .get<UserAdmin>(environment.admin.api.mobilityEngine + '/v1/users/' + adminId, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      }).pipe(
        switchMap(user => {
          return this.httpClient.get<Role>(environment.admin.api.mobilityEngine + '/api/user-manager/v1/roles/' + user.role).pipe(
            map(role => {
              user.role = role;
              return user;
            })
          );
        })
      );
  }

  deleteAdministrator(adminId: number): Observable<any> {
    return this.httpClient
    .delete(environment.admin.api.mobilityEngine + '/v1/users/' + adminId, {
      headers: new HttpHeaders({
        'x-apikey': environment.admin.api.apikey
      })
    });
  }

  updatePassword(userId: number, credentials) {
    return this.httpClient
      .put(environment.admin.api.mobilityEngine + '/v1/users/' + userId + '/credentials', credentials, {
        headers: new HttpHeaders({
          'x-apikey': environment.admin.api.apikey
        })
      });
  }

  refreshLoggedAgent(): Observable<UserAdmin> {
    const keycloakId = this.keycloakService.getKeycloakInstance().subject;
    return this.httpClient.get<UserAdmin>(environment.admin.api.mobilityEngine + '/v1/realms/' + environment.admin.keycloak.realm + '/reference/' + keycloakId + '/user')
      .pipe(
        switchMap(user => {
          return this.httpClient.get<Role>(environment.admin.api.mobilityEngine + '/api/user-manager/v1/roles/' + user.role).pipe(
            map(role => {
              user.role = role;
              return user;
            })
          );
        }),
        tap(user => {
          this.loggedAgent$.next(user);
        })
      );
  }

  private setParamsWithCompanyRef(params: HttpParams): HttpParams {
    const companyReference = this.loggedAgent$.getValue().userDetails.companyReference;
    if (companyReference) {
      return params.set('companyReference', companyReference);
    }

    return params;
  }

  getMyEqualsOrLowerProfils(): Observable<string[]> {
    if (this.loggedAgent$.getValue()) {
      const role = this.loggedAgent$.getValue().role;
      return this.httpClient.get<string[]>(environment.admin.api.mobilityEngine + '/v1/roles/' + role.name + '/matrix');
    }
  }
  public getCachedUser(reference: string): Observable<UserAdmin> {
    this.userCache[reference] = this.userCache[reference] || this.fetchUser(reference);
    return this.userCache[reference];
  }

  public fetchUser(reference: string): Observable<UserAdmin> {
    return this.getUserByReference(reference).pipe(
      publishReplay(1),
      refCount()
    );
  }

  public isEmailUsed (email: string): Observable<Boolean> {
    const params = new HttpParams().set("email", email);
    return this.httpClient.get<Boolean>(environment.admin.api.mobilityEngine + '/api/user-manager/v1/realms/' + environment.admin.keycloak.realm + '/email/is-used', { params });
  }

}
