import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OgoneResponse } from '@app/core/services/financial-engine/models/ogoneResponse';
import { ScriptsUtilsService } from '@app/core/services/util/scripts-utils.service';
import { ToastrService } from 'ngx-toastr';

declare let Tokenizer: any;
@Component({
  selector: 'ap-ogone',
  templateUrl: './ogone.component.html',
  styleUrls: ['./ogone.component.css']
})
export class OgoneComponent implements OnInit {

  @Input() ogoneResponse: OgoneResponse;
  @Output() receive = new EventEmitter<any>();

  tokenizer: any;
  buttonDisabled: boolean = true;

  constructor(
      private scriptService: ScriptsUtilsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // Load the TokenizerScript
    this.scriptService.load('tokenizer').then(() => {
      // Create a new Ogone tokenizer
      this.tokenizer = new Tokenizer(
        `https://payment.${this.ogoneResponse.partialRedirectUrl}`,
        'div-hosted-tokenization',
        {
          hideCardholderName: false,
          hideTokenFields: true
        });
        this.tokenizer.initialize();
    });
  }

  submitForm() {
    this.tokenizer.submitTokenization().then((data) => {
      if (data.success) {
        this.receive.emit({data});
      } else {
        this.toastr.error(data.error.message);
      }
    })
  }

}
