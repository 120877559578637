import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FilterState } from "./filter-state";

export const selectFilterState = createSelector(
    createFeatureSelector('filterState'),
    (state: FilterState) => {
        return state;
    }
);

export const selectLastBlockingRequestDate = createSelector(
    createFeatureSelector('filterState'),
    (state: FilterState) => {
        return state?.blockingInfo?.lastBlockingRequestDate;
    }
);

export const selectBlockingStateDate = createSelector(
    createFeatureSelector('filterState'),
    (state: FilterState) => {
        return state?.blockingInfo?.blockingStateDate;
    }
)