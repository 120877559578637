import { Component, Input, OnInit } from '@angular/core';
import { AccessMedia } from '@app/core/services/access-media/models/accessMedia';

@Component({
  selector: 'ap-bank-card',
  templateUrl: 'bank-card.component.html',
  styleUrls: ['bank-card.component.css']
})
export class BankCardComponent implements OnInit {

  @Input() expirationDate: boolean = false;
  @Input() rollingColors: boolean = false;

  color: string;
  colors =  ['#e2e2e2', '#c1ecf7', '#e7c1f7', '#f7ccc1', '#d1f7c1', '#eff7c1']

  @Input() accessMedia!: AccessMedia;

  constructor() { }

  ngOnInit() {
    if (this.rollingColors) {
      this.color = this.colors[this.accessMedia.accessMediaId % this.colors.length];
    } else {
      this.color = this.colors[0];
    }
  }
}
