<div class="modal-backdrop">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title | translate }}</h4>
        </div>
        <div class="modal-body">
            <p class="pre-wrap">
              <span>{{message | translate }}</span>
              <span *ngIf="buttonToTls">{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.MESSAGES.ENABLE_TLS' | translate}}</span>
            </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirm()" attr.aria-label="{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.OK'| translate }}">{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.OK'| translate }}</button>
          <button *ngIf="buttonToTls" class="btn btn-secondary" type="button" (click)="enableTls()" attr.aria-label="{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.ENABLE_TLS'| translate }}">{{ 'ACCESS_MEDIA.READ_ACCESS_MEDIA.MODAL.ACTIONS.ENABLE_TLS'| translate }}</button> 
        </div>
      </div>
    </div>
</div>