import { Pipe, PipeTransform } from '@angular/core';
// This pipe receives an integer, which refers to the number of the day of the week. Example: 1 = monday, 7 = sunday
// The function converts the value to a string, to read each char until finding the same id. If this happens it will return a boolean set in true and mark the checkbox as "checked"
@Pipe({
  name: 'weekDays'
})
export class WeekDaysPipe implements PipeTransform {

  transform(days: any, id: number): boolean {
    let dayOfWeek = days,
      stringDaysNumber = dayOfWeek.toString(),
      checkWeekday = false;
    for (let i = 0, len = stringDaysNumber.length; i < len; i += 1) {
      if (stringDaysNumber.charAt(i) == id) {
        checkWeekday = true;
      }

    }

    return checkWeekday;
  }
}
