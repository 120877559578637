import { AbstractFilterTemplate } from "../../filter-template/abstract-filter-template.model";
import { AccessMediaFilterTemplate } from "../../filter-template/access-media-filter-template/access-media-filter-template.model";
import { AbstractFilter } from "../abstract-filter.model";

export class AccessMediaFilter extends AbstractFilter {
    value: string;

    // constructor(value: string, filterTemplate: Access)
    constructor(value: string, filterTemplate: AccessMediaFilterTemplate) {
        super();
        this.value = value;
        this.filterTemplate = <AbstractFilterTemplate<AccessMediaFilter>> filterTemplate;
    }
    
    getAccessMediaFilterTemplate() {
        return <AccessMediaFilterTemplate> this.filterTemplate;
    }
    
    public getType(): string {
      return 'AccessMediaFilter';
    }
    
    public encode(): string {
      const enabledEncode = this.enabled ? '1' : '0';
      return this.filterTemplate.name + '~' + enabledEncode + '~' + this.value;
    }
}