<div class="row">
  <div class="col-md-12">
    <div class="form-inline">
      <div class="form-group">
        <select class="form-control" disabled attr.aria-label="{{ 'FILTERS.EDIT.LABEL' | translate }}">
            <option>{{ _filter?.filterTemplate.label | translate }}</option>
        </select>
      </div>
      <div class="form-group">
        <ap-filter-template (addFilter)="onValidate($event)" [filter]="_filter"></ap-filter-template>
      </div>
    </div>
  </div>
</div>
