import { Injectable, ComponentFactoryResolver, Injector, EmbeddedViewRef, ApplicationRef, Component, Type, ComponentRef } from '@angular/core';
import { OverlayComponent } from '@app/shared/components/overlay/overlay.component';
import { OverlayWrapper } from './overlay-wrapper.component';

@Injectable()
export class OverlayService {

  private overlayComp: ComponentRef<OverlayComponent>;

  constructor(private resolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) {

  }

  display<T>(component: Type<OverlayWrapper<T>>, data: T): OverlayComponent {
    this.close();

    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = factory.create(this.injector);
    componentRef.instance.data = data;
    this.applicationRef.attachView(componentRef.hostView);

    const overlayComp = this.resolver.resolveComponentFactory<OverlayComponent>(OverlayComponent).create(this.injector, [[componentRef.location.nativeElement]]);

    const componentRootNode = (overlayComp.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.applicationRef.attachView(overlayComp.hostView);

    document.getElementsByTagName('body')[0].appendChild(componentRootNode);

    const close$ = overlayComp.instance.close.subscribe(() => {
      overlayComp.destroy();
    });

    overlayComp.onDestroy(() => {
      close$.unsubscribe();
    });

    this.overlayComp = overlayComp;

    return overlayComp.instance;
  }

  isOpen(): boolean {
    return !!this.overlayComp;
  }

  close() {
    if (this.overlayComp) {
      this.overlayComp.destroy();
      this.overlayComp = null;
    }
  }

}
