import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SimpleModalModule } from 'ngx-simple-modal';
import { SharedModule } from '@app/shared/shared.module';
import { AppComponent } from '@app/app.component';
import { MenuComponent } from '@app/core/menu/menu.component';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { StatisticComponent } from './modules/statistic/statistic.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AppInternalSupportGuard } from './core/guards/app-internal-support-guard';
import { ConfigurationService } from './core/services/configuration/configuration.service';
import { AdministratorService } from './core/services/administrator/administrator.service';
import { ExtraRouterService } from './core/services/util/extra-router.service';
import { environment } from '@env/environment';
import { TenantService } from './core/services/tenant/tenant.service';
import { TenantInterceptor } from './core/services/tenant/tenant.interceptor';
import { AppInternalDeviceStatGuard } from './core/guards/app-internal-device-stat-guard';
import { StoreModule } from '@ngrx/store';
import { editAccessMediaReaderStateReducer, metaReducerAccessMediaReaderStorage } from './shared/components/access-media-reader/access-media-reader-store/access-media-reader.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { editFilterStateReducer, metaReducerLocalStorageFilter } from './shared/components/filter/filter-state-store/filter-state.reducer';
import { NgProgressModule } from 'ngx-progressbar';
import { MaintenanceInterceptor } from './core/services/maintenance/maintenance.interceptor';
import { MaintenanceService } from './core/services/maintenance/maintenance.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, MenuComponent, StatisticComponent],
  imports: [
    AppRoutingModule,
    StoreModule.forRoot(
      {
        filterState: editFilterStateReducer,
        accessMediaReaderState: editAccessMediaReaderStateReducer,
      }, {
        metaReducers: [
          metaReducerLocalStorageFilter,
          metaReducerAccessMediaReaderStorage,
        ]
    }),
    StoreDevtoolsModule.instrument(),
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgProgressModule.withConfig({
      spinner: false,
      color: '#1abb9c',
      thick: true
    }),
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SimpleModalModule.forRoot({ container: 'modal-container' }),
    KeycloakAngularModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [KeycloakService, ConfigurationService, AdministratorService, TenantService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (extra: ExtraRouterService) => () => extra.init(),
      multi: true,
      deps: [ExtraRouterService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    AppInternalSupportGuard,
    AppInternalDeviceStatGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster=' + environment.version);
}

/**
 * Init function
 * The promises will be played before the app (preloading).
 *
 * @param keycloak
 * @param configurationService
 * @param administratorService
 * @param maintenanceService
 * @returns
 */
export function init(keycloak: KeycloakService, configurationService: ConfigurationService, administratorService: AdministratorService, maintenanceService: MaintenanceService): () => Promise<any> {
  return async (): Promise<any> => {
    // Fetch local configuration
    let environment = await configurationService.refreshConfiguration().toPromise();
    // Fetch remote configuration
    if (environment.admin.multitenant.enabled && !environment.admin.multitenant.useLocaly) {
      environment = await configurationService.fetchRemoteConfiguration().toPromise();
    }
    // Init keycloak
    await keycloak.init({
      config: environment.admin.keycloak,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false
      }, bearerExcludedUrls: [
        '/maintenance'
      ]
    });

    // Preload languages settings for translate service
    await configurationService.initLanguage();
    await configurationService.addCustomTranslation();

    // Save logged user (usefull to get permissions and role)
    await administratorService.refreshLoggedAgent().toPromise();
    return true;
  };

}
