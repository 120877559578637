<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <select formControlName="operator" class="form-control" *ngIf="filter.getSelectionFilterTemplate().criterion.length > 1; else showLabel">
    <option value="" disabled>-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
    <option *ngFor="let criterion of filter.getSelectionFilterTemplate().criterion" [value]="criterion">{{ 'FILTERS.ADD.' + criterion | translate }}</option>
  </select>
  <ng-template #showLabel>
    <ap-label [status]="'DEFAULT'">{{ 'FILTERS.ADD.' + filterForm.get('operator').value | translate }}</ap-label>
  </ng-template>
  <select *ngIf="shouldShowSelectField()" formControlName="value" class="form-control">
    <option value="" disabled>-- {{ 'FILTERS.ADD.OPTION_EMPTY' | translate }} --</option>
    <option *ngFor="let option of filter.getSelectionFilterTemplate().options" [ngValue]="option.value">{{ option.label | translate }}</option>
  </select>
  <button [disabled]="!filterForm.valid" class="btn btn-success" type="submit" attr.aria-label="{{ 'FILTERS.ADD.VALID' | translate }}">{{ 'FILTERS.ADD.VALID' | translate }}</button>
</form>
