import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MonthYearFilter } from '../../filter-item/month-year-filter-item/month-year-filter.model';
import cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';

@Component({
  selector: 'ap-month-year-filter-template',
  templateUrl: './month-year-filter-template.component.html',
  styleUrls: ['./month-year-filter-template.component.css']
})
export class MonthYearFilterTemplateComponent implements OnInit {
  
  @Input()
  get filter(): MonthYearFilter { return this._filter; }
  set filter(filter: MonthYearFilter) {
    this._filter = filter;
    this.filterForm.setValue({value: moment(filter.value).format('MM-YYYY')});
  } 
  
  _filter: MonthYearFilter;
  @Output() addFilter = new EventEmitter<MonthYearFilter>();
  filterForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { 
     this.filterForm = this.formBuilder.group({
       value: ['', Validators.required]
     });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.filterForm.valid) {
      this._filter.value = new Date(this.filterForm.value.value);
      this.addFilter.emit(cloneDeep(this._filter));
    }
  }

}
