<div class="x_content" *ngIf="loaded && errGettingData">
  <div class="empty-table">
    <div>
      <i class="fa fa-warning"></i><br />
      <div>{{ 'ERROR_PAGE.INTERNAL_ERROR' | translate }}</div>
      <div>{{ 'ERROR_PAGE.INTERNAL_ERROR_MSG' | translate }}</div>
    </div>
  </div>
</div>
<div class="x_content" *ngIf="!loaded && !errGettingData">
  <div class="text-center">
    <div class="spinner-fa fa fa-spinner fa-spin fa-3x"></div>
  </div>
</div>
<div *ngIf="empty && loaded && !errGettingData">
  <div class="empty-table">
    <div>
      <i class="fa fa-inbox"></i><br />
      <span>{{ 'COMMON.ERROR.EMPTY_DATA' | translate }}</span>
      <br />
    </div>
  </div>
</div>