<div class="row">

  <div class="col-md-8">
    <!-- Form to fill the name of the new filter -->
    <form class="form-inline" [formGroup]="saveForm" (ngSubmit)="onSave()">
      <div class="form-group">
        <input type="text" formControlName="name" class="form-control" placeholder="{{ 'FILTERS.SAVE.LBL_PLACEHOLDER' | translate }}" attr.aria-label="{{ 'FILTERS.SAVE.LBL_PLACEHOLDER' | translate }}">
      </div>
      <div class="form-group save-filter-margins" *apSecure="'MANAGE_SHARED_FILTERS,MANAGE_MYCOMPANY_SHARED_FILTERS'">
        <ap-checkbox-ng [formControl]="sharedControl"></ap-checkbox-ng>
        <label class="shared_filter">{{ 'FILTERS.SAVE.LBL_SHARED_FILTER' | translate }}</label>
      </div>
      <!-- Company -->
      <div class="form-group save-filter-margins" *ngIf="sharedControl.value" [ngClass]="{'has-error': saveForm.errors?.companyRequired && (saveForm.dirty || saveForm.touched)}">
        <label class="control-label">{{ 'SHARED.COMPONENT.OVERLAY.USABLE_BY' | translate }} &nbsp;</label>
        <select class="form-control" formControlName="company" attr.aria-label="{{ 'SHARED.COMPONENT.OVERLAY.USABLE_BY' | translate }}">
          <option [value]=''></option>
          <option *ngFor="let company of (companies | orderBy:'asc':'label')" [value]="company.key">{{ company.label }}</option>
        </select>
      </div>
      <div class="form-group save-filter-margins">
        <span class="input-group-btn">
          <button type="submit" class="btn btn-success" [ap-btn-loading]="loading" [disabled]="!saveForm.valid" attr.aria-label="{{ 'FILTERS.SAVE.ACTIONS.SAVE' | translate }}">{{ 'FILTERS.SAVE.ACTIONS.SAVE' | translate }}</button>
        </span>
        <span *ngIf="saveForm.errors?.companyRequired && (saveForm.dirty || saveForm.touched)" class="help-block">{{ 'ERROR.PATTERN.REQUIRED' | translate }}</span>
      </div>
    </form>
  </div>

  <!-- Cancel button -->
  <div class="col-md-4 text-right">
    <button class="btn btn-default" (click)="onCancel()">{{ 'FILTERS.SAVE.ACTIONS.CANCEL' | translate }}</button>
  </div>

</div>
