import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ap-checkbox',
  template:   `
    <div class="icheckbox_flat-green" [ngClass]="{'checked': checked }" (click)="selectItem()">
      <input aria-label="none" type="checkbox" class="flat" style="position: absolute; opacity: 0;">
      <ins class="iCheck-helper" style="display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 0px none; opacity: 0;"></ins>
    </div>`
})

export class CheckboxComponent implements OnInit {

  @Input() checked: boolean;
  @Output() select = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  selectItem(): void {
    this.select.emit(!this.checked);
  }

}
