import { FilterData } from '@app/shared/components/filter/filterData';
import { Pagination } from '@app/shared/components/pagination/pagination.model';
import { RefundCase } from './refund-case.model';
import { environment } from '@env/environment';
import { RefundCaseStatusEnum } from './enum/refund-case-status.enum';

export class RefundCases {
  refundCases: RefundCase[];
  pagination: Pagination;

  constructor(refundCases: RefundCase[], pagination: Pagination) {
    this.refundCases = refundCases;
    this.pagination = pagination;
  }
}

export class RefundCaseParameters {
  accessMediaReferences?: Array<string>;
  maskedPan?: string;
  accessMediaType?: string;
  accountId?: number;
  holderEmailAddress?: string;
  holderFirstName?: string;
  holderLastName?: string;
  page?: number;
  serviceOperator?: string;
  settlementReference?: string;
  since?: Date;
  size?: number;
  sort?: Array<string>;
  status?: Array<'INITIATED' | 'PROCESSED' | 'ERROR' | 'CANCELLED'>;
  until?: Date;
  observe?: 'body';
  reportProgress?: boolean;
  transactionIds?: string[];

  constructor() {
    this.accessMediaReferences = null;
    this.maskedPan = null;
    this.accessMediaType = null;
    this.accountId = null;
    this.holderEmailAddress = null;
    this.holderFirstName = null;
    this.holderLastName = null;
    this.page = 0;
    this.serviceOperator = null;
    this.settlementReference = null;
    this.since = null;
    this.until = null;
    this.observe = 'body';
    this.reportProgress = false;
    this.transactionIds = null;
    this.size = environment.admin.application.pageSize || 10;
    this.sort = ['modificationDate,DESC'];
  }

  static fromFilter(filterData: FilterData, params: RefundCaseParameters, companyRef?: string): RefundCaseParameters {
    const filters = new Map();

    filterData.encode().split('|').map(str => {
      const key = str.split('~')[0];
      const enabled = str.split('~')[1] === '1';
      const value = str.split('~')[2];
      return { key, enabled, value };
    }).filter(filter => {
      return filter.enabled;
    }).forEach(filter => {
      filters.set(filter.key, filter);
    });

    // Resetting all filters
    params.serviceOperator = companyRef ? companyRef : null;
    params.maskedPan = null;
    params.holderEmailAddress = null;
    params.holderLastName = null;
    params.holderFirstName = null;
    params.settlementReference = null;
    params.status = params.status ? params.status : [RefundCaseStatusEnum.INITIATED, RefundCaseStatusEnum.ERROR];
    params.since = null;
    params.until = null;

    if (filters.has('operator')) {
      params.serviceOperator = filters.get('operator').value;
    }
    if (filters.has('maskedPan')) {
      params.maskedPan = filters.get('maskedPan').value;
    }
    if (filters.has('email')) {
      params.holderEmailAddress = filters.get('email').value;
    }
    if (filters.has('lastName')) {
      params.holderLastName = filters.get('lastName').value;
    }
    if (filters.has('firstName')) {
      params.holderFirstName = filters.get('firstName').value;
    }
    if (filters.has('settlementReference')) {
      params.settlementReference = filters.get('settlementReference').value;
    }
    if (filters.has('status')) {
      params.status = [filters.get('status').value];
    }
    if (filters.has('creationDateAfter')) {
      params.since = new Date(filters.get('creationDateAfter').value);
    }
    if (filters.has('creationDateBefor')) {
      params.until = new Date(filters.get('creationDateBefor').value);
    }

    return params;
  }
}
