<section>
  <ul class="list-unstyled timeline">
    <li *ngFor="let timeline of timelines">
      <section class="block">
        <section class="tags">
          <a href class="tag">
            <span>{{ timeline.tag }}</span>
          </a>
        </section>
        <section>
          <h2 class="title">{{ timeline.title }}</h2>
          <section class="actions">
            <button *ngIf="timeline.action && timeline.action !== ''" class="btn" (click)="onClick(timeline.action.event, timeline)" type="button" attr.aria-label="{{ timeline.action.label }}">
              {{ timeline.action.label }}
            </button>
          </section>
          <section class="byline">
            <span>{{ timeline.byline }}</span>
          </section>
          <p>{{ timeline.excerpt }}</p>
        </section>
      </section>
    </li>
  </ul>
</section>
