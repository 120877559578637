import { Component, Input, OnInit } from '@angular/core';
import { SipsFilter } from './sips-filter.model';

@Component({
  selector: 'ap-sips-filter-item',
  templateUrl: './sips-filter-item.component.html',
  styleUrls: ['./sips-filter-item.component.css']
})
export class SipsFilterItemComponent implements OnInit {

  @Input() filter: SipsFilter;

  constructor() { }

  ngOnInit() {
  }

}
