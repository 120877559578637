<div *ngIf="filterData" class="row">
  <div class="col-lg-8 col-md-8">
    <ap-filter-list (disable)="onDisable($event)" (edit)="onEdit($event)" (remove)="onRemove($event)" [filters]="filterData.filters"></ap-filter-list>
    <button *ngIf="!creationFilterDisplay && editingFilterId == -1" class="btn btn-success" (click)="creationFilterDisplay = true" attr.aria-label="{{ 'FILTERS.ACTIONS.ADD_FILTER' | translate }}">{{ 'FILTERS.ACTIONS.ADD_FILTER' | translate }}</button>
    <button *ngIf="creationFilterDisplay || editingFilterId > -1" class="btn btn-info" (click)="creationFilterDisplay = false; editingFilterId = -1" attr.aria-label="{{ 'FILTERS.ACTIONS.CANCEL' | translate }}">{{ 'FILTERS.ACTIONS.CANCEL' | translate }}</button>
  </div>
  <div class="col-lg-4 col-md-4 text-right">
    <ap-access-media-reader *ngIf="readAccessMedia" (addFilter)="onAddFilter($event)" [filter]="filterData.filters"></ap-access-media-reader>
    <button (click)="saving = !saving" [disabled]="filterData.filters.length === 0" [ngClass]="{'active': saving}" class="btn btn-default" title="{{ 'FILTERS.FILTER.ACTIONS.SAVE' | translate}}" type="button"><i class="fa fa-save" alt="Save"></i></button>
    <ap-filter-load [filterData]="filterData"></ap-filter-load>
    <ap-export-data [exportType]='exportType'></ap-export-data>
    <button ap-tooltip="<kbd><kbd>alt</kbd> + <kbd>q</kbd></kbd>" title="{{ 'FILTERS.FILTER.ACTIONS.REMOVE' | translate}}" (click)="onRemoveAll()" [disabled]="filterData.filters.length === 0" class="btn btn-danger"><i class="fa fa-trash" alt="Trash"></i></button>
  </div>
</div>
<div class="clearfix"></div>
<!-- To display a panel to add a filter -->
<div *ngIf="creationFilterDisplay" @toggleFilterAction>
  <hr/>
  <ap-filter-add (addFilter)="onAddFilter($event)" [templates]="filterData.templates"></ap-filter-add>
</div>
<!-- To display a panel to edit a filter -->
<div *ngIf="editingFilterId > -1" @toggleFilterAction>
  <hr/>
  <ap-filter-edit (validate)="onEditValidate($event)" [filter]="filterData.filters[editingFilterId]"></ap-filter-edit>
</div>
<!-- To display a panel to save filter into localstorage -->
<div *ngIf="saving" @toggleFilterAction>
  <hr/>
  <ap-filter-save (close)="saving = false" (save)="onSave()" [filterData]="filterData"></ap-filter-save>
</div>
<br/>
<div class="clearfix"></div>
