import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, Component, ContentChildren, HostBinding, QueryList } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SideMenuItemComponent } from '../side-menu-item/side-menu-item.component';

@Component({
  selector: 'li[ap-side-submenu]',
  templateUrl: './side-submenu.component.html',
  styleUrls: ['./side-submenu.component.css'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px', overflow: 'hidden' })),
      transition('false <=> true', animate(200))
    ])
  ]
})
export class SideSubmenuComponent implements AfterContentInit {

  @HostBinding('class.active') activeClass = false;
  @HostBinding('class.expanded') expanded = false;

  @ContentChildren(SideMenuItemComponent, {descendants: true}) items!: QueryList<SideMenuItemComponent>;

  constructor(private router: Router) { }

  ngAfterContentInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
          const startWithUrl = this.items.map(item => item.linkTo).some(link => {
            return e.url.startsWith(link);
          });
          this.activeClass = startWithUrl;
        }
      });
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  public setActive(active: boolean) {
    this.activeClass = active;
  }

}
