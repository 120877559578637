import { Component, Input, OnInit } from '@angular/core';
import { TextFilter } from './text-filter.model';

@Component({
  selector: 'ap-text-filter-item',
  templateUrl: './text-filter-item.component.html',
  styleUrls: ['./text-filter-item.component.css']
})
export class TextFilterItemComponent implements OnInit {

  @Input() filter: TextFilter;

  constructor() { }

  ngOnInit() {
  }

}
