<ng-container *ngIf="currentValue | async as value;else inputNoFocus">
  <ng-container *ngIf="!edition;else input">
    <div class="input-group" style="margin-bottom: 0;">
      <input type="text" class="form-control" disabled [value]="label || value" title="{{ ariaLabel }}">
      <div class="input-group-btn">
        <button style="margin-right: 0px !important;" class="btn btn-default" type="button" (click)="reset()" title="Erased"><i class="fa fa-times" alt="Reset"></i></button>
        <button style="margin-right: 0px;" class="btn btn-default" type="button" (click)="enableEdition()" title="Edit"><i class="fa fa-caret-down" alt="Select"></i></button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #input>
  <input type="text" ap-focus [(ngModel)]="term" (ngModelChange)="filter()" class="form-control" placeholder="{{ 'SHARED.COMPONENT.SELECT.SEARCH' | translate }}" autocomplete="off" (focus)="enableEdition()" title="{{ ariaLabel }}">
</ng-template>
<ng-template #inputNoFocus>
  <input type="text" [(ngModel)]="term" (ngModelChange)="filter()" class="form-control" placeholder="{{ 'SHARED.COMPONENT.SELECT.SEARCH' | translate }}" autocomplete="off" (focus)="enableEdition()" title="{{ ariaLabel }}">
</ng-template>

<div *ngIf="edition" class="panel panel-default edition shadow">
  <div class="panel-body">
    <div class="caret"></div>

    <ng-content select="ap-option,ap-optoption,ap-option-info"></ng-content>

    <div class="text-center" *ngIf="isEmpty">{{ 'SHARED.COMPONENT.SELECT.EMPTY' | translate }}</div>
  </div>
</div>
