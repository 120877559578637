import { Component } from '@angular/core';

@Component({
  selector: '[ap-side-menu]',
  template: '<ng-content></ng-content>',
  styles: [],
  host: { 'class' : 'nav side-menu' }
})
export class SideMenuComponent {
}
