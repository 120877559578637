import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface PaireLinkModel {
  title: string;
  message: string;
}

@Component({
  selector: 'ap-paire-link-modal',
  templateUrl: './paire-link-modal.component.html',
  styleUrls: ['./paire-link-modal.component.css']
})
export class PaireLinkModalComponent extends SimpleModalComponent<PaireLinkModel, string> implements PaireLinkModel, OnInit{

  pinForm: UntypedFormGroup;
  title: string;
  message: string;
  constructor() { 
    super();
  }

  confirm() {
    this.result = this.pinForm.value.pinCode;
    this.close();
  }

  ngOnInit() {
    this.pinForm = new UntypedFormGroup({
      pinCode: new UntypedFormControl('', Validators.required)
    })
  }
}