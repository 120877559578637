import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '@app/core/services/maintenance/maintenance.service';

@Component({
  selector: 'ap-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.css']
})
export class MaintenanceBannerComponent implements OnInit {
  from: Date = null;
  to: Date = null;

  constructor(private maintenanceService: MaintenanceService) {
  }

  ngOnInit(): void {
    const config = this.maintenanceService.getMaintenanceConfigFromLocal();
    if (config?.maintenance?.banner?.from && config?.maintenance?.banner?.to) {
      this.from = new Date(config.maintenance.banner.from);
      this.to = new Date(config.maintenance.banner.to);
    }
  }

}
