import { Input, OnInit, ElementRef, Renderer2, Component } from '@angular/core';

@Component({
  template: `
    <ng-content *ngIf="!loading"></ng-content>
    <ng-container *ngIf="loading">
      <i class="fa fa-cog fa-spin" alt="Waiting"></i> {{ 'SHARED.COMPONENT.BTN_LOADING.IN_PROGRESS' | translate }}
    </ng-container>
  `,
  selector: 'button[ap-btn-loading]'
})
export class BtnLoadingComponent implements OnInit {

  @Input('ap-btn-loading')
  get loading(): boolean { return this._loading; }
  set loading(loading: boolean) {
    this._loading = loading;
    this.change();

  }
  private _loading = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  public ngOnInit() {
    this.change();
  }

  private change() {
    if (this.loading) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }

}
