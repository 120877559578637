import { createAction, props } from "@ngrx/store";

/* Secret */
export const setSecretRequest = createAction("[AccessMediaReader] Set secret", props<{ secret: string}>());
export const clearSecretRequest = createAction("[AccessMediaReader] Clear secret");

/* Serial Number */
export const setSerialNumberRequest = createAction("[AccessMediaReader] Set SerialNumber", props<{ serialNumber: string}>());
export const clearSerialNumberRequest = createAction("[AccessMediaReader] Clear serialNumber");

/* mRandomNumber */
export const setMRandomNumberRequest = createAction("[AccessMediaReader] Set mRandomNumber", props<{ mRandomNumber: string}>());
export const clearMRandomNumberRequest = createAction("[AccessMediaReader] Clear mRandomNumber");