import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface WaitingModel {
  title: string;
  message: string;
}

@Component({
  selector: 'ap-waiting-modal',
  templateUrl: './waiting-modal.component.html',
  styleUrls: ['./waiting-modal.component.css']
})
export class WaitingModalComponent extends SimpleModalComponent<WaitingModel, boolean> implements WaitingModel {

  title: string;
  message: string;

  constructor() { 
    super();
  }

  cancel () {
    this.result = false;
    this.close();
  }
}