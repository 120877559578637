<span *ngIf="status" class="label" [ngClass]="{
  'label-succes-light': status === 'VERIFIED_PENDING_MODIFICATION',
  'label-in-progress': status === 'IN_PROGRESS',
  'label-primary': status === 'CREATED' || status === 'INITIALIZED' || status === 'REFUND',
  'label-success' : status === 'CLOSED' || status === 'ACTIVE' ||  status === 'TREATED' || status === 'VERIFIED' || status === 'FULL_PRICE' || status === 'UNITARY' || status === 'PROCESSED' || status === 'OK' || status === 'DONE' || status === 'PAYMENT_DONE' || status === 'EXECUTED' || status === 'SUBROGATED',
  'label-warning':  status === 'ASSIGNED' || status === 'EXPIRED ' || status === 'AWAITING_VALIDATION',
  'label-danger': status === 'FAILED' || status === 'OPEN' || status === 'NON_TREATED' || status === 'SUSPENDED' || status === 'CANCELLED' || status === 'DELETED' || status === 'INACTIVE' || status === 'TERMINATED' || status === 'UNASSIGNED' || status === 'BLOCKED' || status === 'REVOKED'|| status === 'UNPROCESSED' || status === 'KO' || status === 'REFUSED' || status === 'DEBT'
}">
  {{ 'STATUS.' + status | translate }}
</span>
